import {observer} from "mobx-react";
import {CMSConfiguration, JPage,} from "@jnext/commons";
import React, {Fragment, useEffect, useMemo} from "react";
import {useCMSStore} from "store/cmsStore";
import HowToSection from "../../../components/Vas/Components/HowToSection";
import {JVasCardSection} from "../../../components";


const VasPage = () => {
    // Ottiene le pagine dal CMS store
    const { pages, setCurrentPage } = useCMSStore();
    // Funzione di navigazione personalizzata

    // Usa useMemo per memorizzare la configurazione CMS della pagina VAS
    const cmsConfig: CMSConfiguration | undefined = useMemo(() => pages?.vas, [pages?.vas]);

    useEffect(() => {
        setCurrentPage(pages?.vas ?? undefined);
    }, [cmsConfig?.page]);

    return (
        <Fragment>
            <JPage enabled {...cmsConfig?.page} options={{headerAlign: 'center'}}>
                {/* Se ci sono sezioni abilitate, renderizza JVasSection per ciascuna */}
                {!!cmsConfig?.section?.sections?.length && <>
                    {cmsConfig?.section?.sections.map((section, key) => {
                        if(section?.enabled){
                            switch (section?.type) {
                                case 'HOW_TO':
                                    return <HowToSection key={key} section={section} />;
                                case 'VAS_CARD_CAROUSEL':
                                    return <JVasCardSection key={key} section={section} />;
                                default:
                                    return null;
                            }
                        }
                    })}
                </>
                }
            </JPage>
        </Fragment>
    );
}

export default observer(VasPage);