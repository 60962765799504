import {CMSConfiguration, getNavPathFromType, NavigationTypes} from "@jnext/commons";
import { observer } from "mobx-react";
import React, {useEffect, useMemo} from "react";
import { useCMSStore } from "store/cmsStore";
import HomePageStandard from "./HomePageStandard";
import { Custom } from "../../../pages/Custom";
import { HttpService } from "../../../service/HttpService";
import {AuthService} from "../../../service/AuthService";
import {getCorrectSection, mzUseNavigate} from "../../../utils";
import {VasService} from "../../../service/VasService";
import {useLocation} from "react-router-dom";

const HomePagePrivate = () => {
    const { pages, setCurrentPage } = useCMSStore();
    const cmsConfig: CMSConfiguration | undefined = useMemo(() => pages?.homepage, [pages]);
    const { options } = cmsConfig?.page as any;
    const customProject = HttpService?.customProject;
    const mzNavigate = mzUseNavigate();
    const location = useLocation();

    useEffect(() => {
        setCurrentPage(pages?.homepage ?? undefined);
    }, [cmsConfig?.page]);

    if (!cmsConfig || !cmsConfig.page) return <div />;

    useEffect(() => {
        (async()=> {
            if (AuthService.authorized && cmsConfig) {
                const vasConfig = getCorrectSection(cmsConfig?.section?.sections, NavigationTypes.VAS);
                if(location.pathname !== '/vas/thankyoupage'){
                    if(vasConfig){
                        const data = await VasService.getSubscriptionDetails(vasConfig.options?.logicId as string);
                        sessionStorage.setItem('vasLogicId', vasConfig.options?.logicId as string);
                        if(!data.hasSubscription) mzNavigate(getNavPathFromType(NavigationTypes.VAS))
                    }
                }
            }
        })()
    }, [location.pathname, cmsConfig]);

    return (
        <>
            {options?.custom ?
                <>
                    {customProject?.customFrontend &&
                        <Custom customFrontendUrl={customProject?.customFrontendUrl} type={options?.customComponent} />}
                </>
                : <HomePageStandard />
            }
        </>
    );
};

export default observer(HomePagePrivate);
