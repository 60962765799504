import React, {useEffect, useState} from "react";
import {mzUseNavigate} from "../../utils";
import MicroApp from "../../commons/MicroApp";
import {useParams} from "react-router-dom";
import {getNavPathFromType, NavigationType} from "@jnext/commons";
import {HttpService} from "../../service/HttpService";
import {useConsumerStore} from "../../store/consumerStore";
import axios from "axios";

export const RedemptionFlow: React.FC = () => {
    const { id } = useParams();
    const navigate = mzUseNavigate();
    const params = useParams();
    const customProject = HttpService?.customProject;
    const [avatar, setAvatar]= useState<string>();
    const { consumerInfo, avatarURL } = useConsumerStore();

    useEffect(() => {
        if(avatarURL){
            setAvatar(avatarURL)
        }
    }, [avatarURL]);

    return <>
        {HttpService.env?.formatReward &&
            <MicroApp
                entry={HttpService.env?.formatReward}
                loaderRows={8}
                props={{

                    /*********** GENERIC PROPS FOR MICROAPP ***********/
                    type: "redemption-page",
                    logicId: id,
                    customProject: customProject,
                    JNextNavigate: navigate,
                    useParams: params,
                    useLocation: location,
                    keyCloakClientId: HttpService.env?.keycloakClientID as string,
                    userProfile: consumerInfo,
                    avatarURL: avatar,
                    customHttpService: HttpService,
                    customAxios: axios,
                    onRedirect: (scope: NavigationType, params?: Record<string, any>) => {
                        return navigate( getNavPathFromType(scope, params) );
                    },
                }}
            />
        }
    </>;
}
