import React from "react";
import {JCMSCTA, JModal} from "@jnext/commons";
import { FORMAT_DATE, useTranslationWord, generatePathUrl } from "../../../../utils";
import { Order, OrderTrackingStatusEnum } from "@jnext/ts-axios-mz";
import moment from "moment";

interface TrackInfoProps {
    order: Order;
    closeModal: () => void;
}

export const TrackInfo: React.FC<TrackInfoProps> = ({ order, closeModal }) => {
    const translateWord = useTranslationWord();

    return (
        <JModal
            width={800}
            isModalVisible={true}
            footer={null}
            handleCancel={() => closeModal()}
        >
            <div className={'historyRewards-track-modal'}>
                <div className={'track-modal-title'}>
                    Ordine n°{order?.friendlyId}
                </div>
                <div className={'track-modal-timeline'}>
                    <ul>
                        {order?.ordersTracking?.map((track, index) => {
                                const status = track?.status;
                                const isSent = status === OrderTrackingStatusEnum.Sent;
                                return (
                                    <li key={index} className={`order-status-tracking-status--${status}`}
                                        data-status={status}>
                                        <div className={'status-icon-container'}>
                                            <figure>
                                                <img src={generatePathUrl(translateWord(`ORDER_STATUS_ICON_${status}`))}
                                                     alt={""} aria-hidden={true}/>
                                            </figure>
                                        </div>
                                        <div className={`track-content track-content-status--${status}`}>
                                            <div className={'track-info'}>
                                                <div className={'track-info-title'}>
                                                    {
                                                        track?.creationDate &&
                                                        <div
                                                            className={'track-data'}>{moment(track?.creationDate)?.format(FORMAT_DATE)}</div>
                                                    }
                                                    {translateWord(`ORDER_STATUS_${status}`)}
                                                </div>
                                                <div className={'track-info-description'}>
                                                    {translateWord(`ORDER_STATUS_DESCRIPTION_${status}`)}
                                                </div>

                                                {isSent && order?.deliveries?.[0]?.trackingLink && (
                                                    <div className={'track-info-cta'}>
                                                        <JCMSCTA
                                                            size={'large'}
                                                            color={'default'}
                                                            title={translateWord("trackingButton") !== "trackingButton" ? translateWord("trackingButton") : "Traccia"}
                                                            type={"BUTTON"}
                                                            href={{
                                                                type: "EXTERNAL",
                                                                value: order?.deliveries?.[0]?.trackingLink,
                                                                target: '_blank'
                                                            }}
                                                        />
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </li>
                                )
                            }
                        )}
                    </ul>
                </div>
            </div>
        </JModal>
    )
}