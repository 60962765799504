import { Row } from "antd";
import { ProductPreviewTagDto } from "@jnext/ts-axios-formatdigitalcollection";
import { generatePathUrl } from "../../utils";

interface CardTagProps {
    tag: ProductPreviewTagDto;
    positionCount: Record<string, number>;
}

export const CardTag: React.FC<CardTagProps> = ({ tag, positionCount }) => {
    const { title, colorCode, position, imgUrl } = tag;

    const positionStyles: Record<string, React.CSSProperties> = {
        "topLeft": { position: "absolute", top: "1.25rem", left: "1.25rem" },
        "topRight": { position: "absolute", top: "1.25rem", right: "1.25rem" },
        "bottomCenter": { position: "absolute", bottom: "0", left: "50%", transform: "translateX(-50%)" },
        "bottomRight": { position: "absolute", bottom: "0", right: "1.25rem" },
    };

    const positionValue = position && positionStyles[position] ? position : "topLeft";
    const positionKey = positionValue as keyof typeof positionStyles;
    const count = positionCount[positionKey] || 0;
    const isTopPosition = positionKey.includes("top");

    const style = {
        ...positionStyles[positionKey],
        [isTopPosition ? 'marginTop' : 'marginBottom']: `${count * 25}px`,
    };

    positionCount[positionKey] = count + 1;

    return (
        <div className="card-tag" style={{ backgroundColor: colorCode || 'transparent', ...style }}>
            <Row justify={"center"} align={"middle"} className={"tagImgTitleContainer"}>
                {imgUrl &&
                    <img src={generatePathUrl(imgUrl)} className={"card-tag-img"} alt="tagImg" aria-hidden={true} />}
                <span dangerouslySetInnerHTML={{ __html: title || '' }} />
            </Row>
        </div>
    );
};
