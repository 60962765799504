// Import necessary modules and components
import React, {useEffect, useMemo, useState} from "react";
import {
    breakpointsMin,
    CMSCTA,
    CMSSection,
    getNavPathFromType,
    JCardViewAll,
    JCMSCTA,
    JHTML, JShortcodeParser,
    JSlider,
    NavigationTypes
} from "@jnext/commons";
import "./cardSubscriptionPurchased.scss";
import {VasService} from "../../../../service/VasService";
import {
    FORMAT_DATE,
    getBackgroundCard,
    myStyleBackground,
    myStyleStructure,
    mzUseNavigate,
    useTranslationWord
} from "../../../../utils";
import {ContestReward} from "@jnext/ts-axios-mz";
import {forEach} from "lodash";
import {DigitalWalletCardItem} from "../../../CatalogList/DigitalWalletCardItem";
import {CatalogType} from "../../../../type/catalogTypes";
import {AuthService} from "../../../../service/AuthService";
import {JModalVas} from "../JModalVas";
import {useWindowSize} from "@react-hook/window-size";
import {useCMSStore} from "../../../../store/cmsStore";
import moment from "moment";
import {ClusteredInitiative, ProductPreviewDto} from "@jnext/ts-axios-formatdigitalcollection";

// Component to display eligible products in a slider
const EligibleProductsDesktop = ({rewards, onClickProduct, onClickViewAll, isDesktopLayout = true}: {
    rewards: ContestReward[],
    onClickProduct: (productId: string) => void,
    onClickViewAll: () => void,
    isDesktopLayout: boolean,
}) => {
    const translateWord = useTranslationWord();
    return (
        <JSlider
            slidesToShow={isDesktopLayout ? 3 : 1}
            dots={false}
            arrows={!isDesktopLayout}
        >
            {rewards?.map((el, i) => (
                <DigitalWalletCardItem
                    positionCard={!isDesktopLayout ? 'horizontal' : 'vertical'}
                    noOverlay={true}
                    key={el?.logicId}
                    isDigital
                    data={el}
                    pricePosition={'TOPRIGHT'}
                    isProductVas={true}
                    showTag={true}
                    onClick={() => onClickProduct(el?.logicId as string)}/>
            ))}
            {isDesktopLayout && <JCardViewAll
                title={translateWord('viewAll')}
                onClick={onClickViewAll}
            />}
        </JSlider>
    )
}

// Main component to display purchased subscription card
export const JCardSubscriptionPurchased = ({product, section}: {
    product: ProductPreviewDto;
    section?: CMSSection;
}) => {
    const navigate = mzUseNavigate();
    const translateWord = useTranslationWord();

    // Get CMS structure from the store
    const {structure} = useCMSStore();

    // Get background template for the card
    const template = useMemo(() => getBackgroundCard(structure, product?.logicId as string), [structure, product?.logicId])

    const myBackground = useMemo(() => myStyleBackground(template), [template])
    const myStyle = useMemo(() => myStyleStructure(template), [template])

    // State to manage modal visibility
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    // Get window width
    const [width] = useWindowSize();

    // Determine if the layout is desktop
    const isDesktopLayout = useMemo(() => (
        width > breakpointsMin.md
    ), [width]);

    const options = section?.options;

    // Get expiration or activation date of the product
    const infoDate = useMemo(() => product?.serviceDetails?.expireDate || product?.serviceDetails?.activationDate ?
        moment(product?.serviceDetails?.expireDate || product?.serviceDetails?.activationDate)?.format(FORMAT_DATE) : null, [product?.serviceDetails]);

    const catalogBtn = section?.cta?.find((cta: CMSCTA) => (
        (cta?.scope === NavigationTypes.CATALOG_LOYALTY_COLLECTION
            || cta?.scope === NavigationTypes.CATALOG_DIGITAL_WALLET) && cta?.options?.isPurchased));

    const moreInfoBtn = section?.cta?.find((cta: CMSCTA) => (options?.showPurchasedMoreInfo != false) && cta?.scope === 'MORE_INFO');

    // State to store rewards
    const [rewards, setRewards] = React.useState<ContestReward[]>([]);

    const clusterInitiative: ClusteredInitiative | undefined = useMemo(() => product?.serviceDetails?.clusteredInitiatives
        ?.find((cluster: ClusteredInitiative) => cluster.refInitiativeRegistryType === 'CATALOG'), [product?.serviceDetails?.clusteredInitiatives])

    // Fetch eligible products for the cluster initiative
    useEffect(() => {
        (async () => {
            if (clusterInitiative?.refInitiativeRegistryLogicId && clusterInitiative?.refClusterLogicId) {

                const data = await VasService.getProductsEligible(clusterInitiative?.refInitiativeRegistryLogicId, clusterInitiative?.refClusterLogicId);

                forEach(data?.results, (item) => {
                    // @ts-ignore
                    data.rewardItems = item;
                })

                if (data?.results) {
                    if (data?.results.length > 2) {
                        return setRewards(data?.results.slice(0, 2));
                    }
                    setRewards(data?.results)
                }
            }
        })();
    }, [clusterInitiative]);

    // Function to open product details
    const openProduct = (refInitiativeRegistryLogicId: string, productId: string, refClusterLogicId?: string) => {
        const paths = {
            [CatalogType.digitalWallet]: AuthService.authorized ? NavigationTypes.CATALOG_DIGITAL_WALLET_DETAILS : NavigationTypes.PUBLIC_CATALOG_DIGITAL_WALLET_DETAILS
        }
        if(refClusterLogicId) sessionStorage.setItem('refClusterLogicId', refClusterLogicId);
        return navigate(getNavPathFromType(paths[CatalogType.digitalWallet], {
            initiativeLogicId: refInitiativeRegistryLogicId,
            productId
        }));
    }

    return (
        <div className={'card-subscription-purchased'} style={myBackground}>

            {infoDate && <div className={'activation-date'}
                              style={{background: `${template?.backgroundCta}`, color: `${template.colorCta}`}}>
                <JShortcodeParser
                    html={translateWord(`subscription_date_${product?.serviceDetails?.status}`, {date: infoDate})}/>
            </div>}

            <div className={'card-subscription-purchased-container'}>
                <div className="card-leftSide">
                    <div className={'title-container'}>
                        <JHTML.Text styleSpan={myStyle} html={translateWord(`vas-card-title_upper`)}/>

                        {/* Display product title */}
                        {product?.title && <JHTML.Title styleSpan={myStyle} html={product?.title}/>}

                    </div>

                    {/* Display product description */}
                    {product?.description && <JHTML.SubTitle styleSpan={myStyle} html={product?.description}/>}


                    <div className="btn-container">

                        {moreInfoBtn && <div className={'btn-more-info'}>
                            {/* Button to show more information */}
                            <JCMSCTA
                                {...moreInfoBtn}
                                action={() => setIsModalVisible(true)}/>
                        </div>}

                        {catalogBtn && <div className={'btn-catalog'}>
                            {/* Button to navigate to catalog */}
                            <JCMSCTA
                                {...catalogBtn}
                                style={{background: `${template?.backgroundCta}`}}
                                title={`<span style="color: ${template?.colorCta}"}>${catalogBtn.title}</span>`}
                                action={() => {
                                    sessionStorage.setItem('refClusterLogicId', product?.serviceDetails?.clusteredInitiatives?.[0]?.refClusterLogicId as string);
                                    navigate(getNavPathFromType(catalogBtn?.scope as NavigationTypes, {initiativeLogicId: catalogBtn?.scopeParameters?.initiativeLogicId}));
                                }}
                            />
                        </div>}
                    </div>
                </div>

                {/* Display rewards if available */}
                {!!rewards && rewards?.length > 0 && <div className="card-rightSide">
                    {product?.title && <JHTML.Title styleSpan={{color: `${template?.colorSecondary}`}} level={1} html={translateWord('title_eligible_products')}/>}

                    <EligibleProductsDesktop
                        isDesktopLayout={isDesktopLayout}
                        rewards={rewards}
                        onClickProduct={(productId: string) => {
                            if (clusterInitiative?.refInitiativeRegistryLogicId && productId) {
                                return openProduct(clusterInitiative?.refInitiativeRegistryLogicId, productId, clusterInitiative?.refClusterLogicId)
                            }
                        }}
                        onClickViewAll={() => {
                            sessionStorage.setItem('vasLogicId', catalogBtn?.scopeParameters?.initiativeLogicId as string);
                            catalogBtn?.scope &&
                            navigate(getNavPathFromType(catalogBtn?.scope, {initiativeLogicId: catalogBtn?.scopeParameters?.initiativeLogicId}))
                        }
                        }
                    />


                </div>}
            </div>

            {/* Show modal if isModalVisible is true */}
            {isModalVisible &&
                <JModalVas
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    infoProducts={product?.cardMetadata}/>}

        </div>
    )
}
