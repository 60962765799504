import React from "react";
import {StarOutlined} from "@ant-design/icons";
import {
    getProductImage,
    CMSSection
} from "@jnext/commons";
import {RedemptionResponse} from "@jnext/ts-axios-mz";
import {Order} from "@jnext/ts-axios-mz/models/order";
import {RewardItemValue} from "../../../../commons/ItemValue";
import {HistoryRewardType} from "../../../../type/historyReward";
import {TranslateComponent, generatePathUrl, useTranslationWord, FORMAT_DATE} from "../../../../utils";
import {Tracking} from "./TrackingComponent";
import {Row} from "antd";
import {DeliveryVoucherContentDto} from "@jnext/ts-axios-reward";
import VoucherDetail from "./VoucherDetail";


interface ProductGroupV2Props {
    redemption: RedemptionResponse;
    options: any;
    headCms?: CMSSection;
    bodyCms?: CMSSection;
    type: Exclude<HistoryRewardType, HistoryRewardType.ALL>;
    setOrderTracking: (order: Order) => void;
    disabledStatusBtn?: boolean;
}

export const ProductGroupV1: React.FC<ProductGroupV2Props> = ({
                                                                  redemption,
                                                                  options,
                                                                  headCms,
                                                                  bodyCms,
                                                                  type,
                                                                  setOrderTracking,
                                                                  disabledStatusBtn = false
                                                              }) => {

    const translateWord = useTranslationWord();

    return (
        <ul className={'orders-list'}>
            {
                redemption?.orders?.map((order, i) => (
                    <li key={i}>
                        <div
                            className={'order-number'}>
                            {bodyCms?.table?.orderN?.label} {order.friendlyId}
                        </div>

                        <Tracking order={order} key={i} headCms={headCms}/>

                        <div className={'products-groups'}>
                            <div className={'product-group'}>
                                <div className={'left-side'}>
                                    {/*PRODUCTS IN ORDER*/}
                                    {
                                        order.products?.map(product => {
                                            const thumbnailImage = getProductImage(product?.product);
                                            const vouchers = product?.vouchers as DeliveryVoucherContentDto[];
                                            return (
                                                <div className={'product-row'}
                                                     key={product?.product?.logicId}>
                                                    <div className={'product-main-data'}>
                                                        {
                                                            thumbnailImage &&
                                                            <img className={'product-thumbnail'}
                                                                 src={generatePathUrl(thumbnailImage)}
                                                                 aria-hidden={true}/>
                                                        }
                                                        {
                                                            !thumbnailImage &&
                                                            <figure/>
                                                        }
                                                        <div className={'product-info'}>
                                                            <div
                                                                className={'product-brand'}>{product?.product?.brands?.[0]?.title}</div>
                                                            <div className={'product-name'}>
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: product?.product?.title ?? ""}}
                                                                />
                                                                {product?.vouchers &&
                                                                    <VoucherDetail
                                                                        vouchers={vouchers}
                                                                        bodyCms={bodyCms}
                                                                    />
                                                                }
                                                            </div>

                                                            {
                                                                (product?.value?.wallets || product?.value?.additionalCost) && (
                                                                    <div className={'stars-container mobile'}>
                                                                        <StarOutlined/>
                                                                        <label>-</label>
                                                                    </div>
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className={'product-other-data'}>
                                                        <div className={'quantity-container'}>
                                                            <label>{bodyCms?.table?.quantity?.label}</label>
                                                            <div
                                                                className={'quantity'}>{product?.quantity}</div>
                                                        </div>
                                                        {

                                                            type !== HistoryRewardType.CONTEST &&
                                                            (!!product?.amount || !!product?.cashAmount) &&
                                                            (
                                                                <div className={'stars-container'}>
                                                                    <RewardItemValue
                                                                        amount={product?.amount}
                                                                        cashAmount={product?.cashAmount}
                                                                        walletId={product?.referredWalletConfigurationLogicId}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <div
                                    className={`right-side`}
                                    style={{
                                        cursor: disabledStatusBtn ? 'default' : 'pointer',
                                        backgroundColor: translateWord(`ORDER_STATUS_COLOR_${order?.orderStatus}`)
                                    }}
                                    onClick={!disabledStatusBtn ? () => setOrderTracking(order) : () => {
                                    }}
                                >
                                    <div className={`order-status-container`}>
                                        <figure>
                                            <img className={''}
                                                 src={generatePathUrl(translateWord(`ORDER_STATUS_ICON_${order?.orderStatus}`))}
                                                 aria-hidden={true}/>
                                        </figure>
                                        <div className={'order-status'}>
                                            <TranslateComponent
                                                text={`ORDER_STATUS_${order?.orderStatus}`}/>
                                        </div>
                                        {/* <div className={'order-status-subtitle'}>ID: 203454Y4371</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}