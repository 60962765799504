import "./App.scss"
import React, {useEffect, useState} from "react"
import {LoadingOutlined} from "@ant-design/icons"
import {breakpointsMax, JCommonProvider} from "@jnext/commons"
import {Spin} from "antd"
import {ActivateUser} from "components/ActivateUser"
import {observer} from "mobx-react"
import {Cart} from "pages/Cart"
import Contests from "pages/Contests"
import EventCompleted from "pages/Events/eventCompleted"
import EventPage from "pages/Events/eventPage"
import GenerateSSO from "pages/GenerateSSO"
import HowTo from "pages/HowTo"
import {Login} from "pages/Login"
import {CollectionProduct, DigitalWalletProduct} from "pages/ProductDetail"
import {Route, Routes, useLocation} from "react-router-dom"
import {useAppStore} from "store/appStore"
import {useCMSStore} from "store/cmsStore"
import {useConsumerStore} from "store/consumerStore"
import {useLoadingStore} from "store/loadingStore"
import {useWalletStore} from "store/walletStore"
import {RequestStatus} from "type/request-status"
import {ErrorServer} from "./commons"
import {GenericError} from "./commons/ErrorsPage/GenericError"
import {Error404} from "./commons/ErrorsPage/templates/Error404"
import {ErrorNoAuth} from "./commons/ErrorsPage/templates/ErrorNoAuth"
import {modalMessageDialog} from "./commons/ModalMessage/ModalMessage"
import {
    AuthCheckProvider,
    ForgotPassword,
    HistoryRewardsCatalog,
    HistoryRewardsCatalogDW,
    HistoryRewardsCatalogLC,
    HistoryRewardsContest,
    ResetPassword
} from "./components"
import HistoryContest from "./components/Profile/Sections/HistoryContest"
import {HistoryRewardsDetail} from "./components/Profile/Sections/HistoryRewardsDetail"
import ProfileInfo from "./components/Profile/Sections/ProfileInfo"
import {Checkout} from "./pages/Checkout"
import Contacts from "./pages/Contacts"
import Contest from "./pages/Contest"
import ContestResult from "./pages/ContestResult"
import Event from "./pages/Event"
import EventExtraCheck from "./pages/EventExtraCheck"
import EventExtraForm from "./pages/EventExtraForm"
import EventDetail from "./pages/Events/eventDetail"
import Faq from "./pages/Faq"
import PrivateHome from "./pages/HomePage/PrivateHome"
import PublicHome from "./pages/HomePage/PublicHome"
import InstantWinPage from "./pages/InstantWinPage"
import Profile from "./pages/Profile"
import {RedemptionFlow} from "./pages/RedemptionFlow"
import {RegistrationFinalize} from "./pages/Registration/Finalize"
import RegistrationPage from "./pages/Registration/Registration"
import SSOCheck from "./pages/SSO/Check"
import {SSOFinalize} from "./pages/SSO/Finalize/SSOFinalize"
import {AuthService} from "./service/AuthService"
import {CustomProjectDTO, HttpService} from "./service/HttpService"
import {TranslationService} from "./service/TranslationService"
import {DEFAULT_LANGUAGE} from "./type/languages"
import {
    generateProviderPathUrl,
    googleTrackingView,
    matomoTrackingView,
    mzUseNavigate,
    setGlobalVars,
    useTranslationWord
} from "./utils"
import {HistoryRewardType} from "./type/historyReward"
import {CatalogType} from "./type/catalogTypes"
import {CatalogPageContainer} from "./pages/Catalog"
import HistoryPoints from "./components/Profile/Sections/HistoryPoints"
import HomePagePublic from "./components/HomePage/Public/HomePagePublic"
import HomePagePrivate from "./components/HomePage/Private/HomePagePrivate"
import {CartInitiatives} from "./pages/CartInitiatives"
import {Custom} from "./pages/Custom"
import {ProjectFrontendComponents} from "@jnext/ts-axios-coreconfiguration"
import {useWindowSize} from "@react-hook/window-size"
import MGMPage from "pages/MGM"
import {isDevEnvironment} from "./components/MetaTags/MetaTagsUtils"
import {Helmet} from "react-helmet"
import ShareCode from "./pages/ShareCode/ShareCode"
import {AccessCode} from "./pages/Access"
import SubscriptionDetails from "./pages/Subscription/SubscriptionDetails"
import axios from "axios";
import VasPage from "./pages/Vas/VasPage/VasPage"
import VasOutlet from "./pages/Vas/VasOutlet/VasOutlet"
import HistoryPDA from "./components/Profile/Sections/HistoryPDA"
import HistoryPDADetail from "./components/Profile/Sections/HistoryPDADetail"
import {CheckoutAndDataEntry} from "components/CheckoutAndDataEntry/CheckoutDataEntry"
import LoaderOverlay from "./components/LoaderOverlay/LoaderOverlay";
import {initializeCookieConsent} from './utils/cookieConsent';
import {useAnalytics} from "./store/analytics";

const globalLoader = document.querySelector(".loader-overlay")
const hideGlobalLoader = () => globalLoader?.classList.add("global-loader--hide")

const App = () => {

    const mzNavigate = mzUseNavigate()
    const translateWord = useTranslationWord()
    const {updateProfile} = useConsumerStore()
    const [width] = useWindowSize()
    const antIcon = <LoadingOutlined style={{fontSize: 48}} spin/>
    const [webAppLoaded, setWebAppLoaded] = useState<boolean>(false)
    const location = useLocation()
    const {loading, setLoading} = useLoadingStore()
    const {setCurrentLanguage, setTranslateFile, setMobileLayout} = useAppStore()
    const {modalMessage, clearModalMessage} = useAppStore()
    const {updateCMS, appSettings, status: CMSRequestStatus} = useCMSStore()
    const {updateWallet, wallet} = useWalletStore()
    const [confError, setConfError] = useState<boolean>(false)
    const [customProject, setCustomProject] = useState<CustomProjectDTO>()
    const [hasValidationScript, setHasValidationScript] = useState<boolean>(false);
    const [scriptLink, setScriptLink] = useState<string>("");
    const [scriptLinkLoaded, setScriptLinkLoaded] = useState<boolean>(false);
    const [pathLoaded, setPathLoaded] = useState<boolean>(false);
    const {analytics, cookieBanner} = useAnalytics();

    const createSkipLink = () => {

        const element = document.getElementById("skip-link")

        if (element) {
            element.innerHTML = translateWord("SKIP_LINK")
        }
    }

    useEffect(() => {

        if (
                scriptLinkLoaded ||
                !HttpService?.env?.pathImg ||
                !AuthService?.loginInfo ||
                (localStorage?.getItem('project') == '0' || !localStorage?.getItem('project')) ||
                (localStorage?.getItem('tenant')?.length == 0 || !localStorage?.getItem('tenant'))
        ) {
            return
        }

        (async () => {
            try {
                let scriptValidation = `${HttpService?.env?.pathImg}/${localStorage?.getItem('tenant')}/${localStorage?.getItem('project')}/assets/mz/validations/scriptValidation.js`;
                await axios.get(scriptValidation);
                setScriptLink(scriptValidation)
                setHasValidationScript(true);
                setPathLoaded(true);
            } catch (e) {
                //file does not exist or forbidden to access
                console.log("scriptValidation url error: ", e);
                setHasValidationScript(false);
            } finally {
                setScriptLinkLoaded(true)
            }
        })();

    }, [pathLoaded, HttpService?.env?.pathImg, localStorage?.getItem('project'), localStorage?.getItem('tenant')]);

    useEffect(() => {
        if (!hasValidationScript) {
            return;
        }
        getValidationFeScriptGenerator();

    }, [hasValidationScript]);

    const getValidationFeScriptGenerator = () => {
        const script = document.createElement("script");
        script.src = `${scriptLink}?${new Date().getTime()}`; // Set the script source
        script.type = "text/javascript";
        script.async = true; // Optional
        const head = document.head;
        head.insertBefore(script, head.firstChild);
    }

    useEffect(
            () => {

                /**
                 * On page change scroll  top
                 */
                window.scrollTo(0, 0)

                /**
                 * Update wallets
                 */
                if (location) {
                    const commonAnalyticsPayload = {
                        event: "page_view",
                        page_location: location.pathname
                    }

                    matomoTrackingView({type: "trackPageView"}, location.pathname)

                    googleTrackingView(commonAnalyticsPayload)
                }
            },
            [location.pathname]
    )

    useEffect(() => {
        /**
         * Creating accessibility skip link
         */
        createSkipLink()
    })

    useEffect(() => {

        (async () => {
            try {
                setGlobalVars(appSettings)

                const [env] = await Promise.all([
                    await HttpService.downloadConfigJSON(),
                ]);

                //const env = await HttpService.downloadConfigJSON()

                // if no baseUrl exit
                if (!env?.baseUrl) {
                    return
                }

                const [, , , localDomains] = await Promise.all([
                    // set bas url
                    HttpService.setBaseUrl(env?.baseUrl),
                    // Configure axios client
                    HttpService.configureAxios(setLoading),
                    // Read URL params
                    AuthService.verifyUrlTokens(setCurrentLanguage),

                    await HttpService.getLocalDomain()
                ]);

                // ottiene il project e il tenant per il dominio
                if (!(localDomains.loaded)) {
                    setConfError(true)
                    return
                }

                const [, , files] = await Promise.all([
                    // carica webapp ora al caricamento dell'app
                    updateCMS(),
                    AuthService.updateLoginInfo(),
                    await TranslationService.loadAllTranslationFiles(localDomains.language)
                ]);

                if (!files) {
                    setConfError(true)
                    return
                }
                if (files.length > 0) {
                    setTranslateFile(files[0])
                }

            } catch (e) {

                console.log("APP.TSX Error", e)
                // Error on configuration
                setConfError(true)
            } finally {
                setWebAppLoaded(true)
            }
        })()
    }, [])

    useEffect(() => {
        if (!webAppLoaded) return

        if (AuthService.authorized && (localStorage.getItem("token"))) {
            if (location.pathname === "/" || location.pathname.includes("catalog")) {
                updateWallet()
            }
            updateProfile()
        }

    }, [webAppLoaded, location.pathname])

    //set Custom Project
    useEffect(() => {
        if (HttpService?.customProject) {
            setCustomProject(HttpService?.customProject)
        }
    }, [HttpService?.customProject])

    useEffect(() => {
        if (modalMessage) {
            modalMessageDialog(modalMessage, clearModalMessage, appSettings, translateWord)
        }
    }, [modalMessage])

    useEffect(() => {
        if (width) {
            setMobileLayout(width < breakpointsMax?.md)
        }
    }, [width])

    const routesCustomComponent = (isPrivate: boolean) => {
        if (customProject?.customFrontend) {
            let project: ProjectFrontendComponents[] | undefined = []
            project = customProject?.frontendComponents?.filter(proj => proj?.pathUrl?.includes("public"))

            if (isPrivate) {
                project = customProject?.frontendComponents?.filter(proj => proj?.pathUrl?.substring(0, 1) === "/")
            }

            return project?.map(
                    (feComponent: ProjectFrontendComponents, key: number) => {
                        return (<Route
                                key={key}
                                path={`${feComponent?.pathUrl}`}
                                element={<Custom customFrontendUrl={customProject?.customFrontendUrl}
                                                 type={feComponent?.component}/>}
                        />)
                    })
        }
    }

    useEffect(() => {
        // Set default language
        setCurrentLanguage(localStorage.getItem("ln") || DEFAULT_LANGUAGE)
        document.documentElement.lang = localStorage.getItem("ln") || DEFAULT_LANGUAGE
        // Set loader for http service
        HttpService.setLoading = setLoading
    }, [])


    useEffect(() => {
        if (!appSettings?.scripts) {
            return
        }

        const {head, body} = appSettings.scripts

        if (head) {
            const headScripts = Array.isArray(head) ? head : [head]

            headScripts.forEach((url, index) => {
                const script = document.createElement("script")
                script.src = url
                script.dataset.customScript = "head"
                script.dataset.customScriptPosition = `${index + 1}`
                document.head.append(script)
            })
        }

        if (body?.top) {
            const bodyTopScripts = Array.isArray(body.top) ? body.top : [body.top]

            bodyTopScripts.forEach((url, index) => {
                const script = document.createElement("script")
                script.src = url
                script.defer = true
                script.dataset.customScript = "body-top"
                script.dataset.customScriptPosition = `${index + 1}`
                document.body.prepend(script)
            })
        }

        if (body?.bottom) {
            const bodyBottomScripts = Array.isArray(body.bottom) ? body.bottom : [body.bottom]

            bodyBottomScripts.forEach((url, index) => {
                const script = document.createElement("script")
                script.src = url
                script.dataset.customScript = "body-bottom"
                script.dataset.customScriptPosition = `${index + 1}`
                document.body.append(script)
            })
        }

    }, [appSettings?.scripts])

    useEffect(() => {
        if (webAppLoaded && scriptLinkLoaded) {
            hideGlobalLoader()
        }
    }, [webAppLoaded, scriptLinkLoaded]);

    // Add the initialization here, before other useEffects
    useEffect(() => {

        if(!cookieBanner)
        {
            return
        }

        (async () => {

            try {
                await initializeCookieConsent(analytics, cookieBanner);

            } catch (err) {
                console.error("Error initializing cookie consent", err);
            } finally {
            }
        })();

    }, [cookieBanner]);

    return (
            <>
                <LoaderOverlay isLoading={!webAppLoaded && !scriptLinkLoaded}/>
                {confError &&
                        <GenericError
                                title={"Sito in manutenzione"}
                                text={"Prova a ricaricare la pagina, oppure contatta l'amministratore."}
                                image={[{src: "assets/error/illustrazioneManutenzione.png"}]}/>
                }

                {webAppLoaded && scriptLinkLoaded &&
                        <>
                            <JCommonProvider
                                    walletData={wallet || []}
                                    mzNavigate={mzNavigate}
                                    imagePathURL={generateProviderPathUrl()}
                                    setLoading={setLoading}
                            >
                                {CMSRequestStatus === RequestStatus.SUCCESS && (
                                        <div className="router-container">
                                            {loading && (
                                                    <div className={"app-spinner-container"}>
                                                        <Spin indicator={antIcon} spinning={loading}
                                                              className={"app-loader-spinning"}/>
                                                    </div>
                                            )}
                                            <Routes>
                                                <Route
                                                        path="/"
                                                        element={<PublicHome/>}
                                                >
                                                    <Route path="public" element={<HomePagePublic/>}/>
                                                    <Route
                                                            path="shareCode/:shareCode"
                                                            element={<ShareCode/>}
                                                    />
                                                    <Route
                                                            path="activateUser"
                                                            element={<ActivateUser/>
                                                            }
                                                    />
                                                    <Route
                                                            path="login"
                                                            element={
                                                                <Login layout="page"/>
                                                            }
                                                    />
                                                    <Route
                                                            path="registration"
                                                            element={
                                                                <RegistrationPage/>
                                                            }
                                                    />

                                                    <Route
                                                            path="registration/finalize"
                                                            element={
                                                                <RegistrationFinalize/>
                                                            }
                                                    />

                                                    <Route
                                                            path="forgotPassword"
                                                            element={
                                                                <ForgotPassword/>
                                                            }
                                                    />

                                                    <Route
                                                            path="resetPassword"
                                                            element={
                                                                <ResetPassword/>
                                                            }
                                                    />
                                                    <Route
                                                            path="sso/finalize"
                                                            element={<SSOFinalize/>}
                                                    />
                                                    <Route path="public/howTo" element={<HowTo/>}/>
                                                    <Route path="public/faq" element={<Faq/>}/>


                                                    <Route path="public/catalog/digital-wallet/:initiativeLogicId?"
                                                           element={<CatalogPageContainer
                                                                   type={CatalogType.digitalWallet}
                                                                   isPrivate={false}/>}/>
                                                    <Route path="public/catalog/loyalty-collection/:initiativeLogicId?"
                                                           element={<CatalogPageContainer
                                                                   type={CatalogType.loyaltyCollection}
                                                                   isPrivate={false}/>}/>

                                                    <Route path="public/catalog/digital-wallet/:initiativeLogicId/:id/:relatedId?"
                                                           element={<DigitalWalletProduct private={false}/>}/>
                                                    <Route path="public/catalog/loyalty-collection/:initiativeLogicId/:id/:relatedId?"
                                                           element={<CollectionProduct private={false}/>}/>

                                                    <Route path="public/vas" element={<VasPage/>}/>

                                                    {routesCustomComponent(false)}

                                                </Route>

                                                <Route
                                                        path="/"
                                                        element={<PublicHome checkAuth={false}/>}
                                                >
                                                    <Route path="generateSso" element={<GenerateSSO/>}/>
                                                </Route>
                                                <Route
                                                        path="/"
                                                        element={
                                                            <AuthCheckProvider>
                                                                <PrivateHome/>
                                                            </AuthCheckProvider>
                                                        }
                                                >

                                                    <Route index element={<HomePagePrivate/>}/>

                                                    <Route path="profile" element={<Profile/>}>
                                                        <Route index element={<ProfileInfo/>}/>
                                                        <Route path="personalInfo" element={<ProfileInfo/>}/>
                                                        <Route path="subscriptionDetails"
                                                               element={<SubscriptionDetails/>}/>

                                                        <Route path="rewardsHistory"
                                                               element={<HistoryRewardsContest/>}/>
                                                        <Route path="rewardsHistory/contest"
                                                               element={<HistoryRewardsContest/>}/>
                                                        <Route path="rewardsHistory/catalog"
                                                               element={<HistoryRewardsCatalog/>}/>
                                                        <Route path="rewardsHistory/catalog/loyaltyCollection"
                                                               element={<HistoryRewardsCatalogLC/>}/>
                                                        <Route path="rewardsHistory/catalog/digitalWallet"
                                                               element={<HistoryRewardsCatalogDW/>}/>


                                                        <Route path="rewardsHistory/contest/:redemptionId"
                                                               element={<HistoryRewardsDetail
                                                                       type={HistoryRewardType.CONTEST}/>}/>
                                                        {/* <Route path="rewardsHistory/catalog/:redemptionId"
                                           element={<HistoryRewardsDetail type={HistoryRewardType.CATALOG}/>}/>*/}
                                                        <Route path="rewardsHistory/catalog/loyaltyCollection/:redemptionId"
                                                               element={<HistoryRewardsDetail
                                                                       type={HistoryRewardType.CATALOG_LOYALTY_COLLECTION}/>}/>
                                                        <Route path="rewardsHistory/catalog/digitalWallet/:redemptionId"
                                                               element={<HistoryRewardsDetail
                                                                       type={HistoryRewardType.CATALOG_DIGITAL_WALLET}/>}/>

                                                        <Route path="contestHistory" element={<HistoryContest/>}/>
                                                        <Route path="contestHistory/:contestId"
                                                               element={<HistoryContest/>}/>
                                                        <Route path="pointsHistory" element={<HistoryPoints/>}/>
                                                        <Route path="pda/history" element={<HistoryPDA/>}/>
                                                        <Route path="pda/history/:logicId"
                                                               element={<HistoryPDADetail/>}/>
                                                    </Route>

                                                    <Route path="contests" element={<Contests/>}/>
                                                    <Route path="contest" element={<Contest/>}>
                                                        <Route path=":id" element={<InstantWinPage/>}/>
                                                        <Route path=":id/extra/form" element={<EventExtraForm/>}/>
                                                        <Route path=":id/result" element={<ContestResult/>}/>
                                                    </Route>

                                                    <Route path="events">
                                                        <Route index element={<EventPage/>}/>
                                                        <Route path="completed" element={<EventCompleted/>}/>
                                                    </Route>
                                                    <Route path="event" element={<Event/>}>
                                                        <Route path=":id" element={<EventDetail/>}/>
                                                        <Route path=":id/extra/form" element={<EventExtraCheck/>}/>
                                                    </Route>

                                                    <Route path="friends" element={<MGMPage/>}/>
                                                    <Route path="faq" element={<Faq/>}/>
                                                    <Route path="howTo" element={<HowTo/>}/>
                                                    <Route path="contacts" element={<Contacts/>}/>

                                                    <Route path="redeem/:context/:id" element={<RedemptionFlow/>}/>
                                                    <Route path="redeem/:id" element={<RedemptionFlow/>}/>

                                                    <Route path="catalog/digital-wallet/:initiativeLogicId?"
                                                           element={<CatalogPageContainer
                                                                   type={CatalogType.digitalWallet}
                                                                   isPrivate/>}/>
                                                    <Route path="catalog/loyalty-collection/:initiativeLogicId?"
                                                           element={<CatalogPageContainer
                                                                   type={CatalogType.loyaltyCollection}
                                                                   isPrivate/>}/>

                                                    <Route path="catalog/digital-wallet/:initiativeLogicId/:id/:relatedId?"
                                                           element={<DigitalWalletProduct private/>}/>
                                                    <Route path="catalog/loyalty-collection/:initiativeLogicId/:id/:relatedId?"
                                                           element={<CollectionProduct private/>}/>

                                                    <Route path="checkout/:initiativeLogicId/:basketType"
                                                           element={<Checkout/>}/>
                                                    <Route path="cart" element={<CartInitiatives/>}/>
                                                    <Route path="cart/:initiativeLogicId" element={<Cart/>}/>

                                                    <Route path="vas" element={<VasOutlet/>}>
                                                        <Route index element={<VasPage/>}/>
                                                    </Route>

                                                    <Route
                                                            path="data-entry-checkout/:initiativeLogicId/:catalogGroupProductLogicId"
                                                            element={<CheckoutAndDataEntry/>}
                                                    />
                                                    <Route
                                                            path="thankyoupage"
                                                            element={<CheckoutAndDataEntry isThankyouPage/>}
                                                    />

                                                    {routesCustomComponent(true)}
                                                </Route>

                                                <Route path="sso" element={<SSOCheck/>}/>
                                                <Route path="access" element={<AccessCode/>}/>
                                                <Route path="serverError" element={<ErrorServer/>}/>
                                                <Route path="*" element={<Error404/>}/>
                                                <Route path="noAuth" element={<ErrorNoAuth/>}/>

                                            </Routes>

                                        </div>
                                )}

                                {/* Dev & stage robots tag to prevent indexing */}
                                {isDevEnvironment &&
                                        <Helmet>
                                            <meta name="robots" content="noindex, nofollow"/>
                                        </Helmet>
                                }

                            </JCommonProvider>

                        </>

                }

            </>
    )
}

export default observer(App)
