import {Stepper} from "../../commons/Stepper";
import React, {useEffect, useMemo, useState} from "react";
import {CheckoutCMSConfig, CheckoutDataInsertOptions, CheckoutMainSectionTypes, CheckoutSectionTypes} from "./models";
import {CheckoutService} from "../../service/CheckoutService";
import {getNavPathFromType, JBreadcrumb, JCMSCTA, JModal, JPage, JSection, NavigationTypes} from "@jnext/commons";
import {StepperSectionTypes} from "./stepperModel";
import {CartService} from "../../service/CartService";
import {CheckoutComplete} from "./Steps/CheckoutComplete";
import {DataFormContainer} from "./Steps/DataForm";
import {DataSummary} from "./Steps/DataSummary";
import {useLocation, useParams} from "react-router-dom";
import {
    BasketResponse,
    BasketTypeEnum,
    CheckoutRequest,
    FormatCourierDto,
    GetBasketResponseItem,
    ProductTypeEnum
} from "@jnext/ts-axios-formatdigitalcollection";
import {browserRedirect, mzUseNavigate, useTranslationWord} from "../../utils";
import {useCMSStore} from "../../store/cmsStore";
import {useConsumerStore} from "../../store/consumerStore";
import MetaTags from "../../components/MetaTags/MetaTags";
import {useWalletStore} from "../../store/walletStore";
import {useVasStore} from "../../store/VasStore";
import {modalMessageDialog} from "../../commons/ModalMessage/ModalMessage";

export const Checkout = () => {
    const location = useLocation();

    // get isTypeService
    let isTypeService = location?.state?.isTypeService || false;

    const {consumerInfo} = useConsumerStore();
    const translateWord = useTranslationWord();


    const {basketType: basketTypeParam} = useParams();

    const basketType = useMemo(() => {

        if (
                [BasketTypeEnum.DirectPurchase, BasketTypeEnum.BasketPurchase].indexOf(basketTypeParam as BasketTypeEnum) == -1
        ) {
            return BasketTypeEnum.DirectPurchase;
        }

        return basketTypeParam;
    }, [basketTypeParam]) as BasketTypeEnum;

    const servicePurchased = useMemo(()=> sessionStorage.getItem("servicePurchased"), []);

    /**
     * Flag -> if is direct purchase
     */
    const isDirectPurchase = useMemo(() => basketType == BasketTypeEnum.DirectPurchase, [basketType]);

    const {pages, setCurrentPage} = useCMSStore();
    const cmsConfig: CheckoutCMSConfig = useMemo(() => pages?.checkout as CheckoutCMSConfig, [pages]);
    const cmsConfigVas: CheckoutCMSConfig | undefined = useMemo(() => ((isTypeService && pages) ? pages?.vasCheckout as CheckoutCMSConfig : undefined), [isTypeService]);
    const checkoutWithCourier = useMemo(() => cmsConfig?.page?.options?.checkoutWithCourier, [cmsConfig?.page]);
    const showDeliveryDaysInfo = useMemo(() => cmsConfig?.page?.options?.showDeliveryDaysInfo, [cmsConfig?.page]);
    const genericDeliveryDays = useMemo(() => cmsConfig?.page?.options?.genericDeliveryDays, [cmsConfig?.page]);


    useEffect(() => {
        setCurrentPage(pages?.checkout ?? undefined);
    }, [cmsConfig?.page]);
    /**
     * If not enabled
     */
    if (!cmsConfig?.page?.enabled) {
        return <></>;
    }

    function getNeededSection(){
        if(isTypeService){
            return cmsConfigVas?.section?.sections?.find(el => el.type == (isDirectPurchase ? CheckoutMainSectionTypes.DIRECT_CHECKOUT : CheckoutMainSectionTypes.BASKET_CHECKOUT));
        }
        return cmsConfig?.section?.sections?.find(el => el.type == (isDirectPurchase ? CheckoutMainSectionTypes.DIRECT_CHECKOUT : CheckoutMainSectionTypes.BASKET_CHECKOUT));
    }

    /**
     * Sections
     */
    const neededSection = useMemo(() => getNeededSection(), [cmsConfig]);

    const dataSection = useMemo(() => neededSection?.sections?.find(el => el.type == CheckoutSectionTypes.DATA_INSERT), [neededSection])
    const confirmSection = useMemo(() => neededSection?.sections?.find(el => el.type == CheckoutSectionTypes.CONFIRM), [neededSection]);
    const summarySection = useMemo(() => neededSection?.sections?.find(el => el.type == CheckoutSectionTypes.SUMMARY), [neededSection])
    const stepperSection = useMemo(() => neededSection?.sections?.find(el => el.type == StepperSectionTypes.STEPS), [neededSection])
    const dataSectionOptions = (dataSection?.options ?? null) as CheckoutDataInsertOptions | null;

    const showModalConfirmPayment = useMemo(() => dataSectionOptions?.showModalConfirmPayment, [dataSectionOptions]);

    /**
     *  Navigation & stepper utils
     */
    const navigate = mzUseNavigate();
    const { updateWallet } = useWalletStore();
    const [activeStep, setActiveStep] = useState<number>(0);

    const [basket, setBasket] = useState<BasketResponse>();
    const isPhysical = useMemo(() => basket?.items?.find(el => el.productType == ProductTypeEnum.Item), [basket?.items]);
    const {initiativeLogicId} = useParams();

    /** Data form State **/
    const [dataFormState, setDataFormState] = useState<CheckoutRequest>();
    const [redemptionId, setRedemptionId] = useState<string>('');

    const [prefilled, setPrefilled] = useState(false);

    /** Couriers **/
    const [couriersList, setCouriersList] = useState<FormatCourierDto[] | undefined>([]);

    const config = useMemo(() => pages?.checkout, [pages]);

    const [showModalMessage, setShowModalMessage] = useState(false);

    /**
     * Action service doCheckout
     * @param formData
     */
    const doCheckout = async(formData: CheckoutRequest) => {
        try {
            const checkoutResponse = await CheckoutService.doCheckout(initiativeLogicId!, basketType, formData);

            if(!!checkoutResponse && (checkoutResponse as any)?.checkoutMode === 'CHECKOUT_PAYMENT'){
                if(checkoutResponse.redemptionLogicId){
                    await CheckoutService.getCheckoutUrl(checkoutResponse.redemptionLogicId).then((res) => {
                        if(res?.url){
                            return browserRedirect(res.url);
                        }
                    });
                }
            } else if(!!checkoutResponse && (checkoutResponse as any)?.checkoutMode === 'SUBSCRIPTION_RENEWAL'){
                return navigate(getNavPathFromType(NavigationTypes.THANKYOU_PAGE));
            }

            if (checkoutResponse?.redemptionLogicId) {
                await updateWallet();
                setActiveStep(2);
                setRedemptionId(checkoutResponse?.redemptionLogicId);
            }

        } catch (e) {
        }

    }


    /**
     * Action after filling form data
     * @param formData
     */
    const afterFormFill = async (formData: CheckoutRequest) => {
        // If the purchase is direct
        if (isDirectPurchase) {
            // If the modal confirmation for payment is enabled from cms and the service is purchased
            if(showModalConfirmPayment && servicePurchased?.includes("true")){
                // Show the modal message
                return setShowModalMessage(true);
            }
            // Proceed with the checkout process
            await doCheckout(formData);

        } else {
            // If not a direct purchase, move to the next step
            setActiveStep(1);
        }
    }

    const afterDataSummary = async () => {
        // Do checkout request

        try {
            const checkoutResponse = await CheckoutService.doCheckout(initiativeLogicId!, basketType, dataFormState!);

            if (checkoutResponse?.redemptionLogicId) {
                await updateWallet();
                setActiveStep(2);
                setRedemptionId(checkoutResponse?.redemptionLogicId);
            }

        } catch (e) {

        }
    }

    /**
     * Remove item from cart
     * @param item
     */
    const removeBasketItem = async (item: GetBasketResponseItem) => {
        await CartService.remove(item, initiativeLogicId!, basketType);

        // Reload all basket data
        await reloadBasket();
    }

    /**
     * Reload basket data
     */
    const reloadBasket = async () => {
        const cartResponse = await CartService.getCartPreview(initiativeLogicId!, basketType);
        setBasket(cartResponse);
        return cartResponse;
    }


    /**
     * Extract cart
     */
    useEffect(() => {
        (async () => {

            const cartResponse = await reloadBasket();
            const isItems = cartResponse?.shipments?.find(item => item.productType === 'ITEM');
            const couriers = cartResponse?.couriers;

            if(cartResponse && checkoutWithCourier && !!isItems){
                if(couriers && couriers?.length > 0){
                    setCouriersList(cartResponse?.couriers);
                    setDataFormState(prevState =>{
                                return{
                                    ...prevState,
                                    shippingCourier : {
                                        courierExternalId: couriers[0].externalId,
                                        flDeliveryScheduled: couriers[0].partners?.[0].flScheduledDelivery
                                    }
                                }
                            }
                    );}
            }

            /**
             * If not data in basket -> return to homepage
             */
            if (cartResponse?.items?.length == 0) {
                await navigate(getNavPathFromType('HOME'));
                return;
            }
        })();
    }, []);

    /**
     * prefill user email
     */
    useEffect(() => {
        if (!consumerInfo || !consumerInfo.email || !dataSectionOptions || !dataSectionOptions.prefillUserData) {
            return;
        }

        setDataFormState(prev => {
            return {
                ...prev,
                address: {
                    ...prev?.address,
                    email: consumerInfo.email
                }
            }
        });

        setPrefilled(true);

    }, [consumerInfo?.email, dataSectionOptions?.prefillUserData]);

    /**
     * Auto-submit digital form
     */
    useEffect(() => {

        if (!dataSectionOptions || !dataSectionOptions.fastDigitalCheckout || !prefilled || !isDirectPurchase || isPhysical || !dataFormState) {
            return;
        }

        afterFormFill(dataFormState).then()

    }, [prefilled, dataSectionOptions?.fastDigitalCheckout, isDirectPurchase, isPhysical, dataFormState])

    return <>
        {
                cmsConfig &&
                <JPage {...cmsConfig} title=''>
                    <MetaTags pageConfiguration={config?.page}/>
                    <JSection>
                        <div className={'checkout-page'}>

                            <div className={'checkout-page-content'}>
                                <aside>
                                    {
                                            cmsConfig?.page?.title &&
                                            <div>
                                                <JBreadcrumb items={[
                                                    {
                                                        label: 'Home',
                                                        navigateType: 'HOME'
                                                    },
                                                    {
                                                        label: cmsConfig?.page?.title
                                                    }
                                                ]}/>
                                            </div>
                                    }
                                    <h1 className={'aside-title'}>{cmsConfig?.page?.title}</h1>
                                    {
                                            stepperSection?.sections &&
                                            <Stepper steps={
                                                stepperSection.sections?.map(el => ({
                                                    title: el.title || '',
                                                }))
                                            }
                                                     activeStep={activeStep}
                                                     disabled={activeStep >= 2}
                                                     onChange={(newStep) => setActiveStep(newStep)}
                                            />
                                    }
                                </aside>
                                <div className={'content-side'}>

                                    {/* Step 1: form */}
                                    {
                                            activeStep == 0 &&
                                            dataSection && basket &&
                                            <DataFormContainer
                                                    cmsConfig={dataSection}
                                                    onComplete={async (formData) => {
                                                        setDataFormState(prev => {
                                                            return {
                                                                ...prev, address: formData.address
                                                            }
                                                        });
                                                        await afterFormFill(formData);
                                                    }}
                                                    basketType={basketType}
                                                    initiativeLogicId={initiativeLogicId!}
                                                    basket={basket}
                                                    initialValues={dataFormState}
                                                    showDeliveryDaysInfo={showDeliveryDaysInfo}
                                                    genericDeliveryDays={genericDeliveryDays}
                                                    options={dataSectionOptions as unknown as Record<string, string>}
                                            />
                                    }

                                    {/* Step 2: cart review */}
                                    {
                                            activeStep == 1 && basket &&
                                            dataFormState &&
                                            dataSection && confirmSection &&
                                            <DataSummary
                                                    cmsConfig={confirmSection}
                                                    checkoutWithCourier={checkoutWithCourier}
                                                    showDeliveryDaysInfo={showDeliveryDaysInfo}
                                                    genericDeliveryDays={genericDeliveryDays}
                                                    dataFormCmsConfig={dataSection}
                                                    basket={basket}
                                                    initiativeLogicId={initiativeLogicId!}
                                                    removeItem={(item) => removeBasketItem(item)}
                                                    dataForm={dataFormState!}
                                                    completeCheckout={() => afterDataSummary()}
                                                    onCheckoutDataUpdate={(formState => {
                                                        setDataFormState(prevState => {
                                                            return {
                                                                ...prevState,
                                                                address: formState.address || prevState?.address,
                                                                shippingCourier: formState.shippingCourier || prevState?.shippingCourier
                                                            }
                                                        });
                                                    })}
                                                    couriersList={couriersList}
                                            />
                                    }

                                    {/* Step 3: order complete and summary */}
                                    {
                                            activeStep == 2 &&
                                            summarySection && redemptionId && basket &&
                                            <CheckoutComplete
                                                    cmsConfig={summarySection}
                                                    redemptionId={redemptionId}
                                                    basket={basket}
                                                    showDeliveryDaysInfo={showDeliveryDaysInfo}
                                                    genericDeliveryDays={genericDeliveryDays}
                                            />
                                    }

                                </div>
                            </div>
                        </div>
                    </JSection>
                    <JModal
                            width={520}
                            isModalVisible={showModalMessage}
                            title={translateWord('MODAL_ACTION_CONFIRM_PAYMENT')}
                            handleCancel={() => setShowModalMessage(false)}
                            footer={<>
                                <JCMSCTA
                                        type={'BUTTON'}
                                        color={'default'}
                                        title={translateWord('cancel')}
                                        action={() => setShowModalMessage(false)} />
                                <JCMSCTA
                                        type={'BUTTON'}
                                        color={'primary'}
                                        title={translateWord('CONFIRM_PAYMENT_BTN')}
                                        action={() => dataFormState && doCheckout(dataFormState)} />
                            </>}
                    />
                </JPage>
        }
    </>;
}