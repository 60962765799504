import { generatePathUrl } from "../../../../utils"
import { JHTML } from "@jnext/commons"
import React from "react"
import { useTranslationWord } from "../../../../utils"
import { BoxEnum } from "../FriendBoard"

interface CardBoxMgmProps {
  title: string,
  point: number | undefined,
  type: BoxEnum,
  src?: string,
  enableActiveFriendsCounter?: boolean
  activeFriends?: number
  index?: number
}

export const CardBoxMgm = ({
                             title,
                             point,
                             src,
                             enableActiveFriendsCounter = false,
                             activeFriends = 0,
                             type
                           }: CardBoxMgmProps) => {

  const translateWord = useTranslationWord()
  const ActiveFriendsLabel = translateWord("ACTIVE_FRIENDS_COUNTER_TITLE") !== "ACTIVE_FRIENDS_COUNTER_TITLE"
    ? translateWord("ACTIVE_FRIENDS_COUNTER_TITLE")
    : "Amici attivi"

  return (
    <div className={"card"}>
      <div className={"upCard"}>
        {src && <img aria-hidden={true} src={generatePathUrl(src)} />}
      </div>
      <div className={"downCard"}>
        {title && <JHTML.Text html={title} />}
        <label className={"point"}>{point}</label>
      </div>
      {
        type === BoxEnum.MAIN_DASHBOARD && enableActiveFriendsCounter &&
        <div className={"activeFriends"}>
          <span className="active-friends-label">{ActiveFriendsLabel}</span>
          <span className={"active-friends-value"}>{activeFriends}</span>
        </div>
      }
    </div>
  )
}
