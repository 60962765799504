import React, { useMemo } from 'react';
import { CMSCTA, CMSSection, JCMSImage, JHTML } from "@jnext/commons";
import LinksGenerate, {CustomLink} from 'commons/LinksGenerate';
import { useCMSStore } from 'store/cmsStore';
import {Language} from "../Language";

const Footer = () => {
    const { structure } = useCMSStore();
    const configFooter: CMSSection | undefined = useMemo(() => structure?.footer, [structure]);
    const languageSection: CMSSection | undefined = useMemo(() => configFooter?.sections?.find(e => e?.type === 'LANGUAGE_PICKER'), [configFooter]);
    const configFooterNavigation: CMSCTA[] | undefined = useMemo(() => structure?.footerNavigation, [structure]);

    //Se presente l'icona del footer diventa un link navigabile
    const configFooterLogo = useMemo(() => configFooter?.cta?.find(e => e?.title?.toUpperCase() === 'LOGO'), [configFooterNavigation]);

    return (
        <>
            {!!configFooter && configFooter?.enabled &&
                (<div className="footerContainer">
                    <div className="lblLogo">
                        {configFooterLogo ? <CustomLink
                                link={configFooterLogo}>
                                <JCMSImage image={configFooter?.image}/>
                            </CustomLink>:
                            <JCMSImage image={configFooter?.image}/>}
                    </div>
                    <div className="lblTextContainer">
                        {configFooter?.text && <div className="lbl">
                            <div dangerouslySetInnerHTML={{ __html: configFooter?.text }} />
                        </div>}
                    </div>

                    {!!configFooterNavigation?.length && (
                        <nav className="footerLinks">
                            <LinksGenerate
                                divider={true}
                                links={configFooterNavigation}
                            />
                        </nav>
                    )}

                    {languageSection?.enabled &&
                        <div className='footerLanguage'>
                            {languageSection?.title && <JHTML.Text html={languageSection?.title}/>}
                            <Language />
                        </div>}
                </div>)
            }
        </>
    )
}

export default Footer;