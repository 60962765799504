import React from 'react';
interface LoaderOverlayProps {
    isLoading: boolean;
}

const LoaderOverlay: React.FC<LoaderOverlayProps> = ({ isLoading }) => {
    return (
            <>
                {isLoading && (
                        <div className="loader-overlay">
                            <div className="loader">
                                <div className="spinner"></div>
                            </div>
                        </div>
                )}
            </>
    );
};

export default LoaderOverlay;