import React, {FC, useMemo, useState} from "react";
import {GetProductsPreviewPagedResponse, MediaTypeEnum} from "@jnext/ts-axios-formatdigitalcollection";
import {Col, Row} from "antd";
import {CatalogPagination} from "./CatalogPagination";
import {Pagination} from "./index";
import {CatalogType} from "../../type/catalogTypes";
import {DigitalWalletCardItem} from "./DigitalWalletCardItem";
import {LoyaltyCollectionCardItem} from "./LoyaltyCollectionCardItem";
import {CatalogPaginationType} from "../../pages/Catalog/CatalogPage";
import {Options} from "../CatalogSlider/LoyaltyCollectionSlider";
import {CMSSection} from "@jnext/commons";

interface CatalogBodyProps {
    refInitiativeRegistryLogicId?: string;
    type: CatalogType;
    rewardSpan: number;
    page: number;
    setPage: (page: number) => void;
    onProductClick: (logicId: string) => void;
    paginationType: CatalogPaginationType;
    section: CMSSection
    isProductVas?: boolean;
    catalog?: GetProductsPreviewPagedResponse;
    pagination?: Pagination;
    totalPage?: number;
}

export function CatalogBody({
                                type,
                                rewardSpan,
                                page,
                                setPage,
                                onProductClick,
                                paginationType,
                                section,
                                isProductVas = false,
                                catalog,
                                pagination,
                                totalPage}: CatalogBodyProps) {

    const CardComponent = type == CatalogType.digitalWallet ? DigitalWalletCardItem : LoyaltyCollectionCardItem;
    const sectionOptions = section?.options as unknown as Options;
    const obtainableOptions = useMemo(()=> (sectionOptions as any)?.obtainable ?? undefined,[sectionOptions])

    return(
        <Row gutter={[32, 32]} align={'stretch'}>
            {
                catalog?.results?.map((el, idx: number) => {
                    // Removing the medias with mediaType CUSTOM from the catalog results
                    const ProductsWithFilteredMedia = ({ ...el, mediaContents: el.mediaContents?.filter(media => media.mediaType !== MediaTypeEnum.Custom) })

                    return (
                        <Col xs={24} sm={12} md={rewardSpan} key={ProductsWithFilteredMedia.logicId || idx} className={'catalog-col'}>
                            <CardComponent
                                options={sectionOptions}
                                data={ProductsWithFilteredMedia}
                                obtainableOptions={obtainableOptions}
                                pricePosition={'TOPRIGHT'}
                                showTag={true}
                                isProductVas={isProductVas}
                                onClick={() => onProductClick(ProductsWithFilteredMedia.logicId as string)}
                            />
                        </Col>)
                })
            }
            {
                pagination?.hasPagination && (!!totalPage && totalPage > 1) &&
                <Col className='catalogPagination' span={24}>
                    <CatalogPagination
                        page={page + 1}
                        setPage={(page: number) => setPage(page - 1)}
                        totalPage={totalPage}
                        paginationType={paginationType} />
                </Col>
            }
        </Row>
    )
}

