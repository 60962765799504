import React, { useMemo } from "react"
import "./JComparisonTable.scss"
import { breakpointsMax, CMSCTA, getNavPathFromType, JCMSCTA, NavigationTypes } from "@jnext/commons"
import { CheckOutlined } from "@ant-design/icons"
import { mzUseNavigate, toFixedValue, useTranslationWord } from "../../../../utils"
import { AuthService } from "../../../../service/AuthService"
import {
  BasketResponse,
  BasketTypeEnum,
  PlanStatusEnum,
  ProductPreviewDto
} from "@jnext/ts-axios-formatdigitalcollection"
import { useWindowSize } from "@react-hook/window-size"
import { CtaVasCmsDTO, PlanComparisonVasCmsDTO } from "@jnext/ts-axios-mz"
import { CartService } from "../../../../service/CartService"

interface JComparisonTableProps {
  cards: ProductPreviewDto[];
  vasCms: PlanComparisonVasCmsDTO[];
}

interface BenefitCellProps {
  benefits: {
    [key: string]: boolean | string | number;
  };
  benefitKey: string;
  isMobileLayout: boolean;
}

const BenefitCell: React.FC<BenefitCellProps> = ({ benefits, benefitKey, isMobileLayout }) => {

  const benefitValue = benefits[benefitKey]

  if (typeof benefitValue === "boolean" && !isMobileLayout) {
    return benefitValue ? (
      <div className={"item-width item-alignment-center"}>
        <CheckOutlined className={"icon-check"} />
      </div>
    ) : (
      <></>
    )
  }

  if (isMobileLayout && benefitValue) {
    return <p className={"t-center item-width"}>{benefitKey}</p>
  }

  if (typeof benefitValue !== "boolean" && !isMobileLayout) {
    return <p className={"t-center item-width"}>{benefitValue.toString()}</p>
  }
  return null
}

export const JComparisonTable: React.FC<JComparisonTableProps> = ({ cards, vasCms }) => {
  const navigate = mzUseNavigate();
  const translateWord = useTranslationWord()
  const [width] = useWindowSize()
  const isMobileLayout = useMemo(() => width < breakpointsMax.sm, [width])
  const isAuth = useMemo(() => AuthService.authorized, [])

  async function addToBasket(index: number) {
    try {

      const card = cards[index]
      if (!card?.serviceDetails?.initiativeLogicId) {
        console.error("Missing required initiativeLogicId")
        return
      }

      const res: BasketResponse = await CartService.add(
        card.serviceDetails.initiativeLogicId,
        BasketTypeEnum.DirectPurchase,
        {
          productLogicId: card?.serviceDetails?.productLogicId,
          redemptionModalityLogicId: card?.redemptionModalities?.[0]?.logicId,
          quantity: 1
        }
      )

      if (!res?.items?.length) {
        console.error("Invalid response: missing items")
        return
      }

      const firstItem = res.items[0]
      const isPurchaseParameters = firstItem.purchaseParameters

      if (Array.isArray(isPurchaseParameters) && isPurchaseParameters.length > 0) {
        return navigate(getNavPathFromType(NavigationTypes.DATA_ENTRY_CHECKOUT, {
          initiativeLogicId: card?.serviceDetails?.initiativeLogicId,
          catalogGroupProductLogicId: firstItem.catalogGroupProductLogicId
        }), {
          state: {
            isTypeService: true
          }
        })
      }

      return navigate(getNavPathFromType(NavigationTypes.CHECKOUT as string, {
        initiativeLogicId: card?.serviceDetails?.initiativeLogicId,
        basketType: BasketTypeEnum.DirectPurchase
      }), {
        state: {
          isTypeService: true
        }
      })
    } catch (error) {
      console.error("Error adding to basket:", error)
    }
  }

  const isPlanPurchased = (plan: PlanComparisonVasCmsDTO) =>
    cards?.some(card =>
      card?.serviceDetails?.status === PlanStatusEnum.Purchased &&
      plan?.logicId === card?.logicId
    )

  const isPlanScheduled = (plan: PlanComparisonVasCmsDTO) =>
    cards?.some(card =>
      card?.serviceDetails?.status === PlanStatusEnum.Scheduled &&
      plan?.logicId === card?.logicId
    )

  // Estrae tutti i benefici unici da tutti i piani
  const benefits = useMemo(() =>
      Array.from(new Set(vasCms?.flatMap(plan => Object.keys(plan?.benefits ?? {})))),
    [vasCms]
  )

  const getCtaConfig = (cta: CtaVasCmsDTO[] | undefined, plan: PlanComparisonVasCmsDTO): CMSCTA | undefined => {
    if (!cards || cards.length === 0) return undefined
    if (!cta) return undefined

    const mappedCta = cta.map(ct => ({
      ...ct,
      type: ct?.type ?? ""
    })) as CMSCTA[]

    if (isPlanScheduled(plan)) {
      return mappedCta.find(ct => ct?.scope === PlanStatusEnum.Scheduled)
    } else if (isPlanPurchased(plan)) {

      return mappedCta.find(ct => ct?.scope === PlanStatusEnum.Purchased)
    }

    return mappedCta.find(ct => ct?.scope === PlanStatusEnum.AvailableForPurchase)

  }

  return (
    <div className={"comparison-tab-container"}>
      <h1 className={"comparison-tab-title"}>{translateWord("COMPARISON_TAB_TITLE")}</h1>
      <div className={isAuth ? "tab-container-with-cta" : "table-container"}
           style={{ "--num-cols": vasCms.length } as React.CSSProperties}>
        <div className="table-header">
          <div className="table-cell t-right border-none"></div>
          {vasCms?.map((plan, index) => (
            <div key={index} className={"table-cell t-right border-none table-cell-end"}>
              <div className="table-cell-flex item-width">
                <p className={"table-small-text"}>PIANO</p>
                <p className="table-header-text pt-10 ">{plan?.name}</p>
              </div>
            </div>
          ))}
        </div>

        {benefits?.map((benefit, index) => (
          <div key={index} className="table-row">
            {
              isMobileLayout ? <div></div> :
                <div
                  className={`table-cell t-left ${index === 0 || isMobileLayout ? "border-none" : ""}`}>
                  <p>{benefit} </p>
                </div>
            }
            {vasCms?.map((plan) => (
              <div
                className={`table-cell t-right item-alignment-value-cell ${index === 0 || isMobileLayout ? "border-none" : ""}`}>
                <BenefitCell
                  benefits={plan?.benefits ?? {}}
                  benefitKey={benefit}
                  isMobileLayout={isMobileLayout}
                />

              </div>
            ))}
          </div>
        ))}

        <div className="table-footer">
          <div className="table-cell t-right border-none"></div>
          {vasCms?.map((plan, index) => {

              return (
                <div key={index} className="table-cell t-right border-none table-cell-end">
                  <div className={"table-cell-flex item-width"}>
                    <p className="table-header-text">{"€" + toFixedValue(plan?.price ?? "0")}</p>
                    <p className={"table-small-text pt-10"}>al mese</p>
                  </div>
                  {isAuth && plan?.cta && plan?.cta?.length > 0 &&
                    <div className={"item-width pt-10 custom-position"}>
                      <JCMSCTA
                        {...getCtaConfig(plan?.cta, plan) as CMSCTA}
                        action={() => addToBasket(index)}
                      />
                    </div>}
                </div>
              )
            }
          )}
        </div>
      </div>
    </div>
  )
}
