import React, {Dispatch, SetStateAction} from "react";
import {CMSSection} from "@jnext/commons";
import {CatalogType} from "../../type/catalogTypes";
import {
    GetProductsPreviewPagedResponse
} from "@jnext/ts-axios-formatdigitalcollection";
import {CatalogPaginationType} from "../../pages/Catalog/CatalogPage";
import {CatalogBody} from "./CatalogBody";

var _ = require('lodash');

export interface Pagination {
    hasPagination?: boolean,
    type?: CatalogPaginationType,
    size?: number,
}

export interface CatalogProps {
    page: number;
    setPage: (Dispatch<SetStateAction<number>>);
    paginationType: CatalogPaginationType;
    colNumbers: number;
    onProductClick: (productId: string) => void,
    type: CatalogType;
    section: CMSSection;
    totalPage: number;
    isProductVas?: boolean;
    refInitiativeRegistryLogicId?: string;
    catalog?:GetProductsPreviewPagedResponse;
    pagination?: Pagination,
}

// Define the CatalogList component that accepts various props to configure the catalog display
export const CatalogList: React.FC<CatalogProps> = ({
                                                        page, // Current page number
                                                        setPage, // Function to set the current page number
                                                        paginationType, // Type of pagination to use
                                                        colNumbers, // Number of columns to display
                                                        onProductClick, // Function called when a product is clicked
                                                        type, // Type of catalog (e.g., digital wallet, loyalty collection)
                                                        section, // CMS section data
                                                        totalPage, // Total number of pages
                                                        isProductVas = false, //product Vas
                                                        refInitiativeRegistryLogicId, // Reference initiative registry logic ID
                                                        catalog, // Catalog data
                                                        pagination, // Pagination configuration
                                                    }) => {

    // Calculate the width of the columns
    const rewardSpan = 24 / colNumbers;

    return (
        <div className={'catalog-list'}>
            <CatalogBody
                page={page}
                setPage={setPage}
                onProductClick={onProductClick}
                totalPage={totalPage}
                rewardSpan={rewardSpan}
                type={type}
                catalog={catalog}
                pagination={pagination}
                paginationType={paginationType}
                section={section}
                refInitiativeRegistryLogicId={refInitiativeRegistryLogicId}
                isProductVas={isProductVas}
            />
        </div>
    )
}