import {CMSSection, getNavPathFromType, NavigationTypes, scrollTo} from "@jnext/commons";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Col, Row} from "antd";
import {Categories} from "./Categories";
import {CatalogList} from "../../components/CatalogList";
import {CatalogPaginationType, CMSCatalogOptions} from "./CatalogPage";
import {Filters} from "./Filters";
import {CatalogService, GetProductsFilters} from "../../service/CatalogService";
import {mzUseNavigate} from "../../utils";
import {observer} from "mobx-react";
import {CatalogType} from "../../type/catalogTypes";
import MetaTags from "../../components/MetaTags/MetaTags";
import MessageBox from "../../components/Messages/MessageBox";
import {useMessages} from "../../components/Messages/messageHooks";
import {CardSubscription} from "../../components";
import {
    ClusteredInitiative,
    GetProductsPreviewPagedResponse,
    PlanStatusEnum,
    ProductPreviewDto
} from "@jnext/ts-axios-formatdigitalcollection";
import _ from "lodash";
import {TabBtn} from "../../components/CatalogList/TabBtn";
import {VasService} from "../../service/VasService";


interface CatalogProps {
    section: CMSSection;
    type: CatalogType;
    initiativeLogicId: string;
    isPrivate: boolean;
}


const typeToShow = ["COMPANY_CLOSURE"]

// Definisce il componente Catalog che accetta le proprietà type, section, initiativeLogicId e isPrivate
export const Catalog: React.FC<CatalogProps> = observer(({ type, section, initiativeLogicId, isPrivate }) => {

    // Ottiene le opzioni del catalogo dalla sezione
    const options = section?.options as any as CMSCatalogOptions;

    // Stato per memorizzare i filtri applicati ai prodotti
    const [filters, setFilters] = useState<GetProductsFilters|undefined>();

    // Ottiene la funzione di navigazione
    const navigate = mzUseNavigate();
    // Riferimento al contenitore del catalogo
    const wrapperRef = useRef(null);

    // Stato per memorizzare il prodotto acquistato
    const [purchased, setPurchased] = useState<ProductPreviewDto>();

    const [initLogicId, setInitLogicId] = useState<string>(initiativeLogicId);

    // Funzione per aprire il dettaglio di un prodotto
    const openProduct = (productId: string) => {
        const paths = {
            [CatalogType.digitalWallet]: isPrivate ? NavigationTypes.CATALOG_DIGITAL_WALLET_DETAILS : NavigationTypes.PUBLIC_CATALOG_DIGITAL_WALLET_DETAILS,
            [CatalogType.loyaltyCollection]: isPrivate ? NavigationTypes.CATALOG_LOYALTY_COLLECTION_DETAILS : NavigationTypes.PUBLIC_CATALOG_LOYALTY_COLLECTION_DETAILS,
        }

        if(refInitiativeRegistryLogicId){
            initiativeLogicId = refInitiativeRegistryLogicId;
        }
        navigate(getNavPathFromType(paths[type], {initiativeLogicId, productId})).then();
    }


    const {messages} = useMessages();

    // Usa useMemo per memorizzare la sezione di acquisto
    const purchaseBox = useMemo(() => section?.sections?.find(section => section.type === "PURCHASE_BOX_VAS"), [section?.sections])



    const [page, setPage] = useState<number>(0); // Stato per la pagina corrente
    const [lastPage, setLastPage] = useState<number>(); // Stato per l'ultima pagina caricata
    const [totalPage, setTotalPage] = useState<number>(0); // Stato per il numero totale di pagine

    const [lastFilters, setLastFilters] = useState<GetProductsFilters>(); // Stato per gli ultimi filtri applicati
    const [loadTime, setLoadTime] = useState<number>(0); // Stato per il numero di caricamenti


    const [catalog, setCatalog] = useState<GetProductsPreviewPagedResponse|undefined>(); // Stato per i dati del catalogo
    const [products, setProducts] = useState<ProductPreviewDto[]|undefined>(); // Stato per i dati dei prodotti

    const [isProductVas, setIsProductVas] = useState<boolean>(false); // Stato  per indicare la struttura vas
    const [indexActiveTab, setIndexActiveTab] = useState<number>(0); // Stato per tab selezionato

    const [refInitiativeRegistryLogicId, setRefInitiativeRegistryLogicId] = useState<string>(''); // Stato per il logiId del catalogo


    const pagination = useMemo(() => {
        return {
            hasPagination: options?.pagination,
            type: options?.paginationType,
            size: options?.paginationSize
        }
    }, [options]);

    const paginationType: CatalogPaginationType = useMemo(()=> pagination?.type ? pagination?.type : 'LAZY', [pagination]);


    useEffect(() => {
        const filterEquals = _.isEqual(lastFilters, filters); // Confronta i filtri attuali con gli ultimi filtri

        // Se i filtri sono gli stessi e la pagina è la stessa, non fare nulla
        if (filterEquals && lastPage === page) {
            return;
        }

        // Se i filtri sono cambiati, resetta la pagina
        let newPage = page;
        if (!filterEquals) {
            newPage = 0;
        }

        // Salva lo stato degli ultimi filtri e dell'ultima pagina
        setLastFilters(filters);
        setPage(newPage);
        setLastPage(newPage);

        (async () => {

            // Ottiene i prodotti dal servizio CatalogService
            const data = await CatalogService.getProducts(initiativeLogicId, !isProductVas ? filters || {}: {}, {
                page: newPage,
                pageSize: pagination?.size || 9
            }, isPrivate);

            // Se la paginazione è di tipo lazy load, aggiungi i nuovi dati ai vecchi
            if (data && data?.results && catalog && catalog?.results && paginationType == 'LAZY') {
                data.results?.splice.apply(data, [0, 0, ...catalog.results]);
            }

            // Controlla se ci sono abbonamenti
            const productsTmp = data?.results?.filter(el => el.productType === 'SERVICE');

            // Imposta i dati della paginazione
            setTotalPage(data?.pagination?.pageCount);

            // Re-centra l'elemento nella schermata
            if (wrapperRef.current) {
                if (loadTime != 0) {
                    scrollTo(wrapperRef.current);
                }

                setLoadTime(loadTime + 1);
            }


            if(productsTmp && productsTmp?.length>0){
                setProducts(productsTmp);

                setIsProductVas(!!productsTmp?.find(pr => !!pr?.serviceDetails))

                // Controlla se c'è un abbonamento acquistato e aggiorna il catalogo
                const purchased = productsTmp?.find(el => el.serviceDetails?.status === PlanStatusEnum.Purchased);

                if(purchased && setPurchased){
                    setPurchased(purchased);
                }

                if(productsTmp && productsTmp?.length > 0){
                    let serviceSelected: ProductPreviewDto | undefined;
                    if(sessionStorage.getItem('refClusterLogicId')){
                        serviceSelected = productsTmp?.find(el =>
                            el?.serviceDetails?.clusteredInitiatives?.find(cluster => cluster.refClusterLogicId === sessionStorage.getItem('refClusterLogicId')));
                        setIndexActiveTab(productsTmp?.findIndex(el => el?.logicId === serviceSelected?.logicId));
                    }
                    return  await getCatalogTabs(productsTmp, serviceSelected?.logicId);
                }
            }

            return setCatalog(data as GetProductsPreviewPagedResponse);

        })();

    }, [, filters]);

    // Funzione per ottenere le schede del catalogo
    async function getCatalogTabs(productsTmp: ProductPreviewDto[], logicId?: string){
        const logicIdTmp = logicId || productsTmp[0]?.logicId;
        const clusterInitiativeByLogicId : ClusteredInitiative[] | undefined = productsTmp
            ?.find(product => product.logicId === logicIdTmp)?.serviceDetails?.clusteredInitiatives;

        const clusterInitiative : ClusteredInitiative | undefined = clusterInitiativeByLogicId
            ?.find(cluster => cluster.refInitiativeRegistryType === 'CATALOG');

        if (clusterInitiative?.refInitiativeRegistryLogicId && clusterInitiative?.refClusterLogicId) {
            setInitLogicId && setInitLogicId(clusterInitiative.refInitiativeRegistryLogicId);
            setRefInitiativeRegistryLogicId(clusterInitiative.refInitiativeRegistryLogicId);
            const data = await VasService.getProductsEligible(clusterInitiative.refInitiativeRegistryLogicId, clusterInitiative.refClusterLogicId, 0, 10, filters);
            if(data){
                setTotalPage((data as any)?.customData?.pagination?.pageCount);
                setCatalog(data || []);
            }
        }
    }

    return (
        <div>
            {/* MetaTags per la configurazione della sezione */}
            <MetaTags sectionConfiguration={section}/>
            {/* Mostra la CardSubscription se c'è un prodotto acquistato e una sezione di acquisto */}
            {!!purchased && purchaseBox?.enabled &&  <CardSubscription product={purchased} section={purchaseBox} />}
            {/* Mostra il titolo del catalogo se presente */}
            {
                section?.title &&
                <h1 className={'catalog-title'}>{section?.title}</h1>
            }
            {/* Mostra la descrizione del catalogo se presente */}
            {
                section?.text &&
                <div className={'catalog-description'} dangerouslySetInnerHTML={{__html: section.text}}/>
            }
            {/* Mostra i filtri standard se configurati */}
            {
                messages?.filter((message) => typeToShow?.includes(message?.messageType ?? ''))
                    ?.map((message) => {
                        return (
                            <div className={"message-box-container"} >
                                <MessageBox
                                    msgTitle={message?.msgTitle ?? ''}
                                    msgSubtitle={message?.msgSubtitle ?? ''}
                                    msgBody={message?.msgBody ?? ''}
                                    msgTitleColor={message?.msgTitleColor ?? ''}
                                    msgSubtitleColor={message?.msgSubtitleColor ?? ''}
                                    msgBodyColor={message?.msgBodyColor ?? ''}
                                    fromDate={message?.fromDate ?? ''}
                                    toDate={message?.toDate ?? ''}
                                    msgLogoUrl={message?.msgLogoUrl ?? ''}
                                    msgDisclaimerColor={message?.msgDisclaimerColor ?? ''}
                                />
                            </div>
                        )
                    })}
            {
                options?.hasStandardFilters && initLogicId &&
                <Filters
                    section={section}
                    initiativeLogicId={initLogicId}
                    isPrivate={isPrivate}
                    onChange={(newFilters) => {
                        const clonedFilters = _.clone(filters);

                        // Se il prezzo non è presente, rimuovilo dai filtri
                        if (typeof newFilters?.minAmount == 'undefined' || typeof newFilters?.maxAmount == 'undefined') {
                            delete clonedFilters?.minAmount;
                            delete clonedFilters?.maxAmount;
                        }

                        setFilters({
                            ...clonedFilters,
                            ...newFilters,
                        });
                    }}
                />
            }
            <div className={`${isProductVas ? 'catalog-component-vas' : 'catalog-component'}`} ref={wrapperRef}>
                <div className={'catalog-component-content'}>
                    <Row>
                        {/* Componente per i pulsanti delle schede */}
                        {(!!products && products?.length > 1) && isProductVas &&
                            <TabBtn
                                containerElement={wrapperRef.current as unknown as HTMLElement}
                                products={products}
                                indexActiveTab={indexActiveTab}
                                onClickTab={async (logicId: string) => {
                                    setCatalog({results: []});
                                    await getCatalogTabs(products, logicId)
                                }}
                            />}
                    </Row>
                    <Row>
                        {/* Mostra le categorie se configurate */}
                        {
                            options?.hasCategoryFilters && initLogicId &&
                            <Col span={6} className={'categories-container'}>
                                <Categories
                                    initiativeLogicId={initLogicId}
                                    isPrivate={isPrivate}
                                    onChoose={sectorId => {
                                        const newFilters = _.clone(filters);

                                        // Rimuove i vecchi settori dai filtri
                                        if (!sectorId && newFilters?.sectorLogicIds) {
                                            delete newFilters.sectorLogicIds;
                                        }
                                        // Imposta i nuovi settori nei filtri
                                        else if (sectorId && newFilters) {
                                            newFilters.sectorLogicIds = [sectorId];
                                        }

                                        setFilters({
                                            ...newFilters,
                                        });
                                    }}/>
                            </Col>
                        }
                        {/* Mostra la lista dei prodotti del catalogo */}
                        <Col className={`list-container ${isProductVas ? 'margin-vas' : ''}`} span={options?.hasCategoryFilters ? 18 : 24}>
                            {
                                wrapperRef?.current &&
                                <CatalogList
                                    colNumbers={options?.hasCategoryFilters ? 3 : 4}
                                    pagination={pagination}
                                    onProductClick={(productId: string) => openProduct(productId)}
                                    type={type}
                                    section={section}
                                    catalog={catalog}
                                    totalPage={totalPage}
                                    page={page}
                                    setPage={setPage}
                                    isProductVas={isProductVas}
                                    paginationType={paginationType}
                                    refInitiativeRegistryLogicId={refInitiativeRegistryLogicId}
                                />
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
})