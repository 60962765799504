import {Fragment, useMemo, useState} from "react";
import {CloseOutlined, MenuOutlined, UserOutlined} from "@ant-design/icons";
import {Drawer, Dropdown} from "antd";
import {
    breakpointsMax,
    CMSCTA,
    CMSSection,
    getNavPathFromType,
    JCMSCTA,
    JCMSImage,
    NavigationTypes
} from "@jnext/commons";
import {mzUseNavigate, useTranslationWord} from "../../utils";
import {SolutionProjectImage} from "../../commons";
import LinksGenerate from "../../commons/LinksGenerate";
import {MenuDrawerItem} from "./MenuDrawerItem";
import {AuthService} from "../../service/AuthService";
import {useCMSStore} from "store/cmsStore";
import {useConsumerStore} from "store/consumerStore";
import {Language} from "../Language";
import {CartDropdown} from "./components";
import {CartSection} from "pages/Cart/models";
import {useWindowSize} from '@react-hook/window-size';
import {findCartSection} from "pages/Cart/utils";
import { cloneDeep } from "lodash"

type Props = {
    className?: string;
    openHeroSection?: boolean;
    toggleHeroSection?: () => void
};

const profileMenu = (
    userNavigation: CMSCTA[] | undefined,
    navigate: (e: string) => Promise<void>,
) => {
    if (!userNavigation?.length) return [];
    const menu = userNavigation?.map((user: CMSCTA) => {
        return user?.scope && {
            key: user.title,
            label: (
                <div
                    onClick={() => {
                        if (user?.scope) {
                            if (user?.scope === NavigationTypes?.LOGOUT) return AuthService.logout();
                            return navigate(getNavPathFromType(user?.scope));
                        }
                    }}
                >
                    {user.title}
                </div>
            ),
        }
    });

    return menu;
};

const Navbar = ({className, openHeroSection, toggleHeroSection}: Props) => {
    const [width] = useWindowSize()
    const translateWord = useTranslationWord();
    const {avatarURL, consumerInfo} = useConsumerStore();
    const {currentPage, structure, appSettings} = useCMSStore();
    const navigate = mzUseNavigate();
    const configHeader: CMSSection | undefined = useMemo(() => structure?.header, [structure]);

    const removeNullUndefinedKeys = (obj: unknown): any => {

        if (Array.isArray(obj)) {
            return obj.map(removeNullUndefinedKeys); // Process each item in an array
        } else if (typeof obj === "object" && obj !== null) {
            return Object.fromEntries(
                Object.entries(obj)
                    .filter(([_, value]) => value !== null && value !== undefined) // Remove keys with null or undefined values
                    .map(([key, value]) => [key, removeNullUndefinedKeys(value)]) // Recursively process nested objects
            );
        }
        return obj; // Return values that are not objects or arrays as is
    }


    const dedicatedUserNavigation = useMemo(() => {

        const removeNullUndefinedKeysCurrentPage = removeNullUndefinedKeys(cloneDeep(currentPage));

        if (!removeNullUndefinedKeysCurrentPage?.page?.structure) return undefined; // Return undefined if structure is missing

        // Check if at least one structure has mainNavigation defined
        const hasUserMainNavigation = removeNullUndefinedKeysCurrentPage.page.structure.some((structure: any) => "userNavigation" in structure);

        if (!hasUserMainNavigation) return undefined; // Return undefined if mainNavigation key is missing in all structures

        const userMainNavigationItems = removeNullUndefinedKeysCurrentPage.page.structure.flatMap((structure: { userNavigation: any; }) => structure.userNavigation || []);

        return userMainNavigationItems.length > 0 ? userMainNavigationItems : []; // Return [] if empty

    }, [currentPage]);


    const dedicatedMenuLinks = useMemo(() => {

        const removeNullUndefinedKeysCurrentPage = removeNullUndefinedKeys(cloneDeep(currentPage));

        if (!removeNullUndefinedKeysCurrentPage?.page?.structure) return undefined; // Return undefined if structure is missing

        // Check if at least one structure has mainNavigation defined
        const hasMainNavigation = removeNullUndefinedKeysCurrentPage.page.structure.some((structure: any) => "mainNavigation" in structure);

        if (!hasMainNavigation) return undefined; // Return undefined if mainNavigation key is missing in all structures

        const mainNavigationItems = removeNullUndefinedKeysCurrentPage.page.structure.flatMap((structure: { mainNavigation: any; }) => structure.mainNavigation || []);

        return mainNavigationItems.length > 0 ? mainNavigationItems : []; // Return [] if empty

    }, [currentPage]);


    const authorized = useMemo<boolean>(() => AuthService.authorized, []);
    const mainNavigation = structure?.mainNavigation?.filter(nav => nav.scope != NavigationTypes.FRIENDS ||
        (nav.scope === NavigationTypes.FRIENDS && consumerInfo?.gdprMgmConsent?.consent));

    // If the navigation contains 1 element, we treat it as a BUTTON indepently from its type
    const configButtonNavigation: CMSCTA | undefined = useMemo(() => (mainNavigation && mainNavigation.length === 1) ? mainNavigation[0] : undefined, [structure]);

    // If the navigation contains several elements (more than 1), we treat it as LINK indepently from their types
    const configMainNavigation: CMSCTA[] | undefined = useMemo(() => (mainNavigation && mainNavigation.length > 1) ? mainNavigation : undefined, [structure]);

    const userNavigation: CMSCTA[] | undefined = useMemo(() => structure?.userNavigation, [structure]);

    const languageSection: CMSSection | undefined = useMemo(() => configHeader?.sections?.find(e => e?.type === 'LANGUAGE_PICKER'), [configHeader]);
    const cartSection: CartSection | undefined = useMemo(() => configHeader?.sections?.find(findCartSection('CART_SECTION')) as CartSection, []);

    const photo = useMemo(() => avatarURL || appSettings?.avatarFallback, [avatarURL, appSettings]);


    // Links Generator
    const linksGenerate = useMemo(() => {
        return (<LinksGenerate links={dedicatedMenuLinks ? dedicatedMenuLinks as unknown as CMSCTA[] : configMainNavigation }/>)
    }, [configMainNavigation, dedicatedMenuLinks]);



    // Boolean to set mobile layout according to breakpoints 
    const mobileLayout = useMemo(() => {
        // By default the mobile layout is triggered at md breakpoint. If the navigation items are 8 o more, we trigger it at lg breakpoint 
        return width <= ((configMainNavigation && configMainNavigation.length < 8) ? breakpointsMax.md : breakpointsMax.lg)
    }, [width])

    // Drawer Visibility state and methods
    const [drawerVisibility, setDrawerVisibility] = useState(false);

    const showDrawer = () => {
        setDrawerVisibility(true);
    };

    const onClose = () => {
        setDrawerVisibility(false);
    };

    return (<>
            <div className={`navbarContainerRow ${location.pathname} ${mobileLayout ? "mobile" : ""} ${className ?? ''}`}>

                { // HAMBURGER MENU ICON
                    mobileLayout && !!configMainNavigation?.length && (
                        <button className="drawerMenuNavbarMobile" type="button"
                                aria-expanded={drawerVisibility}
                                onClick={() => showDrawer()}>
                            <MenuOutlined
                                aria-label={drawerVisibility ? translateWord('CLOSE_MENU') : translateWord('OPEN_MENU')}/>

                        </button>
                    )
                }


                {/* MENU LEFT SIDE */}
                <div
                    className={`leftSide ${authorized ? "authorized" : ""}`}
                    onClick={() => navigate(getNavPathFromType(authorized ? NavigationTypes.PRIVATE_HOME : NavigationTypes.PUBLIC_HOME))}
                >
                    {!!configHeader && configHeader?.enabled &&
                            <div className='logo'>
                                <JCMSImage image={configHeader?.image} height={40} defaultImageWidth={false}/>
                            </div>
                    }
                </div>

                {/* MENU CENTER SIDE */}
                <div
                    className={"centerSide"}
                >
                    {!mobileLayout && (!!configMainNavigation?.length || !!dedicatedMenuLinks?.length) && (
                        <nav className="links">
                            {linksGenerate}
                        </nav>
                    )}
                </div>

                {/* MENU RIGHT SIDE */}
                <div
                    className={"rightSide"}
                >
                    <>
                        {   // CART RENDERING
                            AuthService?.authorized && cartSection && (
                                <Fragment>
                                    <CartDropdown section={cartSection as CartSection}/>
                                </Fragment>
                            )
                        }
                        {
                            !mobileLayout && authorized && userNavigation?.length === 0 &&
                            <div>
                                {photo && (
                                    <SolutionProjectImage
                                        className="icon noPointer"
                                        name={photo}
                                    />
                                )}
                            </div>
                        }
                        {   // PROFILE DROPDOWN MENU

                            !mobileLayout && authorized && !!dedicatedUserNavigation?.length ?
                                (
                                    <Dropdown
                                        menu={{
                                            items: profileMenu(dedicatedUserNavigation, navigate) as any
                                        }}
                                        placement="bottomRight"
                                        arrow
                                        trigger={["click"]}
                                    >
                                        <div>
                                            {photo && (
                                                <SolutionProjectImage
                                                    className="icon"
                                                    name={photo}
                                                />
                                            )}
                                        </div>
                                    </Dropdown>
                                ) : dedicatedUserNavigation?.length === 0
                                        ? (<></>)
                                        : !mobileLayout && authorized && !!userNavigation?.length ? (
                                                <Dropdown
                                                    menu={{
                                                        items: profileMenu(userNavigation, navigate) as any
                                                    }}
                                                    placement="bottomRight"
                                                    arrow
                                                    trigger={["click"]}
                                                >
                                                    <div>
                                                        {photo && (
                                                            <SolutionProjectImage
                                                                className="icon"
                                                                name={photo}
                                                            />
                                                        )}
                                                    </div>
                                                </Dropdown>
                                                ) : (
                                                    <>
                                                        {   // BUTTON IN CASE OF SINGLE NAVIGATION ITEM
                                                            configButtonNavigation && <JCMSCTA {...configButtonNavigation} />
                                                        }
                                                    </>
                                                )
                        }
                        {
                            // LANGUAGE SECTION
                            !mobileLayout && languageSection?.enabled && <Language/>
                        }
                        {mobileLayout && authorized && !!userNavigation?.length && <>
                            {!openHeroSection && <UserOutlined className={'iconMobile'} onClick={toggleHeroSection}/>}
                            {openHeroSection &&
                                <CloseOutlined className={'iconMobile close'} onClick={toggleHeroSection}/>}
                        </>}
                    </>
                </div>
            </div>

            {/* DRAWER FOR MOBILE LAYOUT */}
            <Drawer
                className="navbarDrawer"
                title={
                    <div className="drawerCloseIcon" onClick={onClose}>
                        <CloseOutlined aria-label={translateWord('CLOSE')}/>
                    </div>
                }
                placement={"left"}
                closable={false}
                onClose={onClose}
                open={drawerVisibility}
                extra={
                    <div>
                        {languageSection?.enabled && <Language/>}
                    </div>
                }
            >
                <ul>

                    {   // DRAWER LINKS
                        configMainNavigation && configMainNavigation.map((cta, i: number) => (
                            <MenuDrawerItem cta={cta} onClose={onClose} key={i}/>
                        ))
                    }

                    {   // DRAWER USER NAVIGATION
                        userNavigation?.map((cta: CMSCTA, i: number) => {
                            return (
                                <MenuDrawerItem cta={cta} key={i} onClose={() => {
                                    onClose();
                                    if (cta?.scope) {
                                        if (cta?.scope === NavigationTypes?.LOGOUT) return AuthService.logout();
                                        return navigate(getNavPathFromType(cta?.scope));
                                    }
                                }}/>
                            )
                        })
                    }
                </ul>
            </Drawer>
        </>
    );
};

export default Navbar;
