import { Col, Typography } from 'antd';
import { FC } from 'react';
import {ProductContentItem} from "../ProductContent";

const { Text } = Typography

export interface CategoriesSectionProps {
    categories: ProductContentItem['sectors']

}
export const CategoriesSection: FC<CategoriesSectionProps> = ({ categories }) => {

    if(!categories || categories.length === 0) return <></>

    return(
        <Col span={24} className="categories">
            {
                categories?.map((category, i) => (
                    <Text key={i} className="category">{category.title?.toUpperCase() || '-'}</Text>
                ))
            }
        </Col>
    )
}
