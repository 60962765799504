import React, {useState} from "react";
import {Row} from "antd";
import {FORMAT_DATE, mzUseNavigate, useTranslationWord} from "../../../../../../utils";
import moment from "moment";
import {PointRow} from "../../PointRow";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {ComponentStatusTag, StatusTagType} from "../../../../../../commons/ComponentStatusTag/ComponentStatusTag";
import {CMSCTA, CMSSection, getNavPathFromType, JCMSCTA, JModal} from "@jnext/commons";
import VasInvoice from "../../VasInvoice";
import {CtaTypeEnum} from "@jnext/ts-axios-mz";

type TableDesktopViewProps = {
    historyPoints: any[],
    historyPointsCMS: CMSSection,
}
export const VasTableMobileView: React.FC<TableDesktopViewProps> = ({historyPoints, historyPointsCMS}) => {
    const translateWord = useTranslationWord();
    const navigate = mzUseNavigate();
    const [collapseStatus, setCollapseStatus] = useState<Record<number, any>>({});
    const [showModal, setShowModal] = React.useState<boolean>(false);

    const handleModal = () => {
        setShowModal(!showModal)
    }

    const toggleCollapse = (index: number) => {
        const status = collapseStatus[index] || false;
        setCollapseStatus({
            ...collapseStatus,
            [index]: !status,
        });
    }

    const disableInvoiceDownloadEnableModal = !!historyPointsCMS?.options?.disableInvoiceDownloadEnableModal ?? false;
    const modalCTA = historyPointsCMS?.cta?.find((cta: CMSCTA) => cta?.scope === "GO_TO_CONTACTS");

    return <>
        {
            historyPoints.length > 0 && historyPoints?.map((list: any, index: number) => {
                const initiative = list?.initiative;

                return (
                    <Row key={index} className='mobileRowTable'>
                        <p className="default-label">{historyPointsCMS.table?.initiative?.label.toUpperCase()}</p>

                        <div className='headTable vas-flex-column'>
                            <div className={"vas-mobile-spacing"}>
                                <div className='infoContainer vas-flex-column'>
                                    <Row>
                                        {initiative?.cms?.title && <div className='vas-flex-column'>
                                            <label className='causalText'>{initiative?.cms?.title}</label>
                                        </div>}
                                    </Row>
                                </div>
                                <div className={'arrowCell'}
                                     onClick={() => toggleCollapse(index)}>
                                    {!collapseStatus[index] && <DownOutlined/>}
                                    {collapseStatus[index] && <UpOutlined/>}
                                </div>
                            </div>
                        </div>
                        {
                            collapseStatus[index] &&
                            <div className='vas-flex-column'>
                                <div className={`pointDetails vas-mobile-spacing`}>
                                    <div>
                                        {list?.transactionDate && (
                                            <>
                                                <label className='dateTransactionText default-label'>
                                                    {historyPointsCMS.table?.start_date?.label.toUpperCase()}
                                                </label>
                                                <span>
                                                    {moment(list?.transactionDate).format(FORMAT_DATE)}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        {list?.amount &&
                                            <div className="val-alignment">
                                                <span
                                                    className="default-label">{historyPointsCMS.table?.points?.label.toUpperCase()}</span>
                                                <PointRow
                                                    amount={list?.amount}
                                                    currency={list?.currency}
                                                    src={list?.cmsDto?.imageRef}
                                                />
                                            </div>}
                                    </div>
                                </div>

                                <div className={`pointDetails default-label vas-mobile-spacing`}>
                                    {list.statusExternalId
                                        && <div className="default-label val-alignment">
                                            <label
                                                className="default-label">{historyPointsCMS.table?.status?.label.toUpperCase()}</label>
                                            <ComponentStatusTag type={StatusTagType.PURCHASE_VAS}
                                                                orderStatus={list.statusExternalId}/>
                                        </div>}
                                    {<div className="default-label val-alignment">
                                        <label
                                            className="default-label">{historyPointsCMS?.table?.invoice?.label}</label>
                                        <VasInvoice transactionLogicId={list?.logicId} hasInvoice={list.hasInvoice}
                                                    disableInvoiceDownloadEnableModal={disableInvoiceDownloadEnableModal}
                                                    handleModal={handleModal}/>
                                    </div>
                                    }
                                </div>
                            </div>
                        }

                    </Row>
                )
            })
        }
        <JModal
            width={520}
            title={translateWord("TITLE_INVOICE_MODAL")}
            children={<>{translateWord("BODY_INVOICE_MODAL")}</>}
            isModalVisible={showModal}
            handleCancel={() => setShowModal(false)}
            footer={<>
                {modalCTA && <JCMSCTA {...modalCTA} type={CtaTypeEnum.Button}
                                      action={() => navigate(getNavPathFromType("CONTACTS"))}/>}
            </>}
        />
    </>
}
