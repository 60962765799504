import React, {useEffect, useMemo, useState} from 'react';
import {CMSSection, JHTML} from '@jnext/commons';
import {observer} from "mobx-react";
import ProgressSection from "./component/ProgressSection";
import ActivityHistory from "./component/ActivityHistory";
import {MGMService} from "../../../service/MGMService";
import {MgmBoxType} from "@jnext/ts-axios-mz";
import {CardBoxMgm} from "./commons/CardBoxMgm";
import {MgmBox} from "@jnext/ts-axios-mz/models/mgm-box";
import {MgmDashboard} from "@jnext/ts-axios-mz/models";

const _ = require('lodash');

export enum BoxEnum {
    MEMBER = "MEMBER",
    POINTS = "POINTS",
    TOKEN = "TOKEN",
    REWARD_DIGITAL = "REWARD_DIGITAL",
    CATALOG = "CATALOG",
    MAIN_DASHBOARD = "MAIN_DASHBOARD",
}

interface BoxInfoProps {
    order: number,
    title: string,
    src: string,
    type: BoxEnum,
    point?: number
}

interface IntroducedFriendProps {
    sections: CMSSection[] | undefined;
    setAllLogicId: (logicIds: string[]) => void
}

function generateBoxInfo(dashboard: MgmDashboard, status: any[]): BoxInfoProps[] {
    let firstElement: BoxInfoProps[] = [{
        order: 0,
        type: BoxEnum.MAIN_DASHBOARD,
        title: status.find(st => st?.type === BoxEnum.MEMBER)?.title,
        src: status.find(st => st?.type === BoxEnum.MEMBER)?.image[0].src,
        point: dashboard?.invitedFriends
    }]
    let boxInfo = dashboard?.box?.map((ds: MgmBox) => {
        if (ds.enabled && ds.type) {
            const statusFound = status.find(st => st?.type === BoxEnum?.[ds?.type as MgmBoxType]);
            return {
                order: statusFound?.order,
                title: statusFound?.title,
                src: statusFound?.image[0]?.src,
                point: ds?.value
            }
        }
    }) as BoxInfoProps[];
    return [...firstElement, ...boxInfo];
}

const FriendBoard = ({sections, setAllLogicId}: IntroducedFriendProps) => {
    const friendBoardSection: CMSSection | undefined = useMemo(() => sections?.find((section: CMSSection) => (section?.enabled && section?.type === 'FRIEND_BOARD')), [sections]);
    const status = friendBoardSection?.options?.status as any[] || [];
    const progressCms: any = friendBoardSection?.options?.progress;
    const activityHistory = friendBoardSection?.options?.activityHistory as CMSSection;

    const [mgmDashboard, setMgmDashboard] = useState<MgmDashboard>();

    const [box, setBox] = useState<BoxInfoProps[]>();

    if (!friendBoardSection) {
        return <></>
    }

    useEffect(() => {
        (async () => {
            const dashboard = await MGMService.getMgmDashboard();

            if (dashboard) {
                setMgmDashboard(dashboard);
                setAllLogicId(_.union.apply(_, dashboard?.box?.map(b => b.logicIds)))
                setBox(generateBoxInfo(dashboard, status));
            }
        })();
    }, []);

    const enableActiveFriendsCounter = progressCms?.enableActiveFriendsCounter || false;

    return (<div className={'mgmRowDown'}>

            <div className={'friendBoard'}>
                {!!box?.length && <div className={'leftContainer'}>
                    <div className={'headerTitleContainer'}>
                        {friendBoardSection?.title &&
                            <JHTML.Title html={friendBoardSection?.title}/>
                        }
                        {friendBoardSection?.subTitle &&
                            <JHTML.SubTitle html={friendBoardSection?.subTitle}/>
                        }
                    </div>
                    <div className={'cardContainer'}>
                        {box?.slice()?.sort((a, b) => a.order > b.order ? 1 : -1)?.map((box: BoxInfoProps, index: number) => {
                            return (<CardBoxMgm
                                key={index}
                                title={box?.title}
                                src={box?.src}
                                point={box?.point}
                                type={box?.type}
                                enableActiveFriendsCounter={enableActiveFriendsCounter}
                                activeFriends={mgmDashboard?.activeFriends || 0}
                            />)
                        })}
                    </div>
                </div>}
                <div className={'rightContainer'}>
                    {progressCms?.enabled && mgmDashboard?.progress?.length &&
                        <ProgressSection
                            invitedFriends={mgmDashboard?.invitedFriends || 0}
                            activeFriends={mgmDashboard?.activeFriends || 0}
                            maxAssignable={mgmDashboard?.maxAssignable || 0}
                            progressCms={progressCms}
                            progress={mgmDashboard.progress}
                            enableActiveFriendsCounter={enableActiveFriendsCounter}
                        />
                    }

                    {activityHistory?.enabled &&
                        <ActivityHistory
                            activityHistory={activityHistory}
                        />
                    }
                </div>
            </div>
        </div>
    )
};

export default observer(FriendBoard);
