import React, {FC} from "react";
import "./CardPositionContainer.scss";
import {HighlightCard} from "../../../HighlightCard";

interface CardPositionContainerProps {
    cards: any[];
    isMainCard?: boolean;
    template?: any;
}

export const CardPositionContainer: FC<CardPositionContainerProps> = ({cards, template, isMainCard = false}) => {

    //split card in 2 array
    const splitCards = (cards: any[]) =>
        cards?.reduce(
            (acc: any[][], card: any, index: number) => {
                acc[index % 2].push(card);
                return acc;
            },
            [[], []]
        );

    return (
        <div className={"card-position-container"}>
            {splitCards(cards)?.map((columnCards, colIndex) => (
                <div
                    key={colIndex}
                    className={`card-position-column ${colIndex === 0 ? "col-first" : "col-second"}`}
                >
                    {columnCards?.map((card: any, cardIndex: number) => (
                        <HighlightCard key={cardIndex} isMainCard={isMainCard} card={card} isInGridLayout={true}
                                       template={template}/>
                    ))}
                </div>
            ))}
        </div>
    );
};
