import {Item} from "@jnext/ts-axios-mz";
import {CMSSection, getProductImage} from "@jnext/commons";
import {StarOutlined} from "@ant-design/icons";
import {generatePathUrl, useTranslationWord} from "../../../../utils";
import React from "react";

// interfaccia safe più flessibile che rappresenta solo le proprietà che utilizziamo perchè
// il tipo effettivo di wallet in reward.rewardWallets non ha la proprietà customData richiesta dal tipo RewardWallet
interface WalletDisplay {
    walletCms?: {
        title?: string;
        text?: string;
        imageRef?: string;
    };
    amount?: number;
    walletConfigurationLogicId?: string;
}

interface ProductCardProps {
    item?: Item;
    wallet?: WalletDisplay;
    bodyCms?: CMSSection;
    onClick: () => void;
}

export const ProductCard: React.FC<ProductCardProps> = ({ item, wallet, bodyCms, onClick }) => {
    const translateWord = useTranslationWord();
    
    return (
        <>
            {wallet && (
                <div className={'product-row'} onClick={onClick}>
                    <div className={'product-main-data'}>
                        {wallet.walletCms?.imageRef && (
                            <img 
                                className={'product-thumbnail'} 
                                src={wallet.walletCms.imageRef} 
                                aria-hidden={true}
                            />
                        )}
                        
                        {!wallet.walletCms?.imageRef && <figure/>}

                        <div className={'product-info'}>
                            <div className={'product-name'}>
                                <div>{wallet.walletCms?.title || '-'}</div>
                            </div>
                            <div className={'product-description'}>
                                <div>{wallet.walletCms?.text || '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className={'product-other-data'}>
                        <div className={'quantity-container'}>
                            <label>{bodyCms?.table?.quantity?.label}</label>
                            <div className={'quantity'}>{wallet.amount}</div>
                        </div>
                    </div>
                </div>
            )}

            {item && (
                <div className={'product-row'} key={item?.product?.logicId} onClick={onClick}>
                    <div className={'product-main-data'}>
                        {getProductImage(item?.product) && (
                            <img 
                                className={'product-thumbnail'}
                                src={generatePathUrl(getProductImage(item?.product))} 
                                aria-hidden={true}
                            />
                        )}

                        {!getProductImage(item?.product) && <figure/>}

                        <div className={'product-info'}>
                            <div className={'product-brand'}>{item?.product?.brands?.[0]?.title}</div>
                            <div className={'product-name'}>
                                <div dangerouslySetInnerHTML={{__html: item?.product?.title ?? ''}}/>

                                {item?.vouchers && (
                                    <div>
                                        {item?.vouchers?.map((el, i) => (
                                            <a 
                                                key={i} 
                                                href={el.content}
                                                target={'_blank'}
                                                title={el?.content}
                                                aria-label={`${translateWord('GO_TO')} ${el?.content}`}
                                                className={'voucher-link'}
                                            >
                                                {bodyCms?.table?.voucherLink?.label}
                                            </a>
                                        ))}
                                    </div>
                                )}
                            </div>

                            {(item?.value?.wallets || item?.value?.additionalCost) && (
                                <div className={'stars-container mobile'}>
                                    <StarOutlined/>
                                    <label>-</label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'product-other-data'}>
                        <div className={'quantity-container'}>
                            <label>{bodyCms?.table?.quantity?.label}</label>
                            <div className={'quantity'}>{item?.quantity}</div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};