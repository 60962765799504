import { CMSConfiguration, JPage } from "@jnext/commons";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useCMSStore } from "store/cmsStore";
import { getHomeSectionDom } from "../../../utils";
import {mzUseNavigate} from "../../../utils";

const HomePageStandard = () => {
    const { pages } = useCMSStore();
    const cmsConfig: CMSConfiguration | undefined = useMemo(() => pages?.homepage, [pages]);
    const mzNavigate = mzUseNavigate();

    if (!cmsConfig || !cmsConfig.page) return <div />;

    return (
        <JPage {...cmsConfig.page}>
            {!!cmsConfig?.section?.sections?.length && getHomeSectionDom(cmsConfig?.section?.sections, mzNavigate)}
        </JPage>

    );
};

export default observer(HomePageStandard);
