import React, {useEffect, useMemo} from "react";
import {getNavPathFromType, JCMSCTA, JHTML, JPage, JSection, JSectionsWrapper, NavigationTypes} from "@jnext/commons";
import {Col, Row} from "antd";
import {useCMSStore} from "../../store/cmsStore";
import {Navigate} from "react-router-dom";
import {CatalogService, LocalStorageCatalogData} from "../../service/CatalogService";
import {CatalogType} from "../../type/catalogTypes";
import MetaTags from "../../components/MetaTags/MetaTags";

export const CartInitiatives: React.FC = () => {
    const initiatives= useMemo<LocalStorageCatalogData | null>(() => CatalogService.getCatalogInfo(CatalogType.loyaltyCollection), []);

    const {pages, setCurrentPage} = useCMSStore();
    const config = useMemo(() => pages?.cart, [pages]);

    useEffect(() => {
        setCurrentPage(pages?.cart ?? undefined);
    }, [config?.page]);

    if (!config || !config?.enabled) {
        return <Navigate to='/404'/>
    }

    /**
     * If only one initiative -> redirect to cart initiative page
     */
    if(initiatives?.initiatives?.length == 1) {
        return <Navigate to={getNavPathFromType(NavigationTypes.CART, {initiativeLogicId: initiatives?.initiatives?.[0]?.logicId})} />
    }

    const sectionCMS = useMemo(() => config?.section?.sections?.find(el => el.type == `CARTS_LIST`) , [config]);

    return (
        <JPage {...(config)} enabled context="cart" className={'cart-initiatives'}>
            <MetaTags pageConfiguration={config.page}/>
            <JSection {...(sectionCMS)} context="cart">
                <JSectionsWrapper>
                    {
                        initiatives?.initiatives?.map((el, i) => (
                            <Row className={'initiative-row'} align={'middle'} justify={'space-between'} key={i}>
                                <Col className={'initiative-id'}>
                                    <JHTML.Title html={el.name!} level={5}/>
                                </Col>
                                <Col className={'initiative-cta'}>
                                    <JCMSCTA type={'BUTTON'} scope={NavigationTypes.CART}
                                             scopeParameters={{initiativeLogicId: el.logicId}}
                                             title={`Mostra iniziativa`}/>
                                </Col>
                            </Row>
                        ))
                    }
                </JSectionsWrapper>
            </JSection>
        </JPage>
    );
}