import {CMSSection} from "@jnext/commons";
import {observer} from "mobx-react";
import {useEffect, useMemo, useRef, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {useAppStore} from "../../store/appStore";
import {useCMSStore} from "../../store/cmsStore";
import {getHeaderSectionDom} from "../../utils";
import Footer from "../Footer";
import Navbar from "../Navbar";

const PageLayoutTemplate = () => {
    const heroRef = useRef<HTMLDivElement>(null);
    const {structure} = useCMSStore();
    const {mobileLayout} = useAppStore();
    const [openHeroSection, setOpenHeroSection] = useState<boolean>(false);
    const heroSection: CMSSection | undefined = useMemo(() => structure?.header?.sections?.find(e => e?.type === 'HERO_SECTION'), [structure]);
    const isNavbar = !!heroSection && heroSection?.enabled;

    // Tracking scrolling
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => window.scrollY === 0 ? setScrolled(false) : setScrolled(true));
    }, [scrolled]);

    // Track location changes
    const location = useLocation();

    // Reset hero section when location changes
    useEffect(() => {
        setOpenHeroSection(false); // Close hero section when the route changes
    }, [location]);

    const toggleHeroSection = () => {
        setOpenHeroSection(prevState => !prevState);
    }

    // Close hero section when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (heroRef.current && !heroRef.current.contains(event.target as Node)) {
                setOpenHeroSection(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="template">

            {!!heroSection ?
                <div className={`header-fixed${scrolled ? ' scrolled' : ''}`}>
                    <header className={`navbar ${mobileLayout ? 'mobile' : ''}`}>
                        <Navbar openHeroSection={openHeroSection} toggleHeroSection={toggleHeroSection} />
                        {getHeaderSectionDom(heroSection, isNavbar, mobileLayout, openHeroSection, heroRef)}
                    </header>
                </div> :
                <header className="navbar">
                    <Navbar />
                </header>
            }

            <Outlet />

            <footer className="footer">
                <Footer />
            </footer>
        </div>
    );
};

export default observer(PageLayoutTemplate);
