import React, {Fragment} from "react";
import {mzUseNavigate} from "utils/utils";
import {observer} from "mobx-react";
import {useParams} from "react-router-dom";


const ShareCode = () => {
    const mzNavigate = mzUseNavigate();
    const {shareCode} = useParams();

    /*useEffect(() => {
        (async()=> {
            //await mzNavigate(getNavPathFromType(NavigationTypes.REGISTRATION)+'?mgmCodeReferral='+shareCode)
            browserRedirect('https://demobrico.jnext-euc1-dev-aws.jakala.cloud/registration?mgmCodeReferral='+shareCode)
        })()
    }, []);*/

    return (
        <Fragment>
            <title>GitHub: Let’s build from here</title>
            <meta name="description"
                  content="GitHub is where over 100 million developers shape the future of software, together. Contribute to the open source community, manage your Git repositories, review code like a pro, track bugs and fea..."/>
            <meta property="og:url" content="https://github.com/"/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="GitHub: Let’s build from here"/>
            <meta property="og:description"
                  content="GitHub is where over 100 million developers shape the future of software, together. Contribute to the open source community, manage your Git repositories, review code like a pro, track bugs and fea..."/>
            <meta property="og:image"
                  content="https://github.githubassets.com/assets/campaign-social-031d6161fa10.png"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content="github.com"/>
            <meta property="twitter:url" content="https://github.com/"/>
            <meta name="twitter:title" content="GitHub: Let’s build from here"/>
            <meta name="twitter:description"
                  content="GitHub is where over 100 million developers shape the future of software, together. Contribute to the open source community, manage your Git repositories, review code like a pro, track bugs and fea..."/>
            <meta name="twitter:image"
                  content="https://github.githubassets.com/assets/campaign-social-031d6161fa10.png"/>
        </Fragment>
    );
}

export default observer(ShareCode);
