import React from "react";
import { Divider } from "antd";
import { mzUseNavigate, useTranslationWord } from "../../utils";
import { observer } from "mobx-react";
import {CMSCTA, JButton, JCMSCTA} from "@jnext/commons";
import { AuthService } from "../../service/AuthService";
import { getNavPathFromType } from "@jnext/commons";
import htmlParser from "html-react-parser";


type groupedMenuItemsType = {
    items_start: CMSCTA[];
    items_center: CMSCTA[];
    items_end: CMSCTA[];
}
export const CustomLink = ({
    link,
    children,
    className = "",
    onClick,
}: {
    link: CMSCTA;
    children: any;
    className?: string;
    onClick?: any;
}) => {
    const translateWord = useTranslationWord();
    const navigate = mzUseNavigate();
    /* const chooseLink = subString(window.location.pathname, '/');
     const routePath = link?.scope && getNavPathFromType(link.scope, link.scopeParameters);*/
    let isClickLink: boolean = false;
    const pathName = window.location.pathname;
    const routePath = link?.scope && getNavPathFromType(link.scope, link.scopeParameters);

    if (!AuthService.authorized) {
        if (pathName === '/public' && routePath === '/public') isClickLink = true
        else if (routePath && routePath !== '/public') {
            isClickLink = pathName.includes(routePath);
        }
    } else {
        if (pathName === '/' && routePath === '/') isClickLink = true
        else if (routePath && routePath !== '/') {
            isClickLink = pathName.includes(routePath);
        }
    }

    const internalPath = link?.href?.type === 'INTERNAL' ? link.href.value : null;


    return routePath || internalPath ? (
        <a
            //type={'link'}
            href={internalPath ? internalPath : routePath ?? '/'}
            onClick={(e) => {
                e.preventDefault()
                if (onClick) {
                    onClick();
                }
                if (link.scope === 'LOGOUT') return AuthService.logout()
                return navigate(internalPath ? internalPath : routePath ?? '/');
            }}
            className={`link${isClickLink ? " linkActive" : ""} ${className}`}
        >
            {children}
        </a>
    ) : (
        <a
            className={`link ${className}`}
            href={link?.href?.value || undefined}
            target={link?.href?.target || undefined}
            title={link?.href?.value}
            aria-label={link?.href?.target === "_blank" ? `${translateWord('GO_TO')} ${link?.href?.value}` : undefined}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
        >
            {children}
        </a>
    );
};

const LinksGenerate = ({
    links,
    divider = false,
}: {
    links: CMSCTA[] | undefined;
    divider?: boolean;
}) => {

    const groupedMenuItems:groupedMenuItemsType = {
        items_start: [],
        items_center: [],
        items_end: [],
    };

    links?.forEach((item) => {
        const alignClass = (item?.options?.align ?? 'center') === "start" ? "items_start" : (item?.options?.align ?? 'center') === "end" ? "items_end" : "items_center";
        groupedMenuItems[alignClass].push(item);
    });

    const generateMenuLinks = (groupedMenuItems: groupedMenuItemsType) => {

        return  Object?.entries(groupedMenuItems)?.map(([key, items]) => (

             <div key={key} className={`linkContainer ${!divider ? "mrg" : ""} ${key}`}>

                {items?.map((item, index) => (
                    <div key={index}>
                        {divider && index > 0 && <Divider className='divider' type="vertical"/>}

                        { item?.type === 'BUTTON'
                            ? <JCMSCTA {...item} />
                            : <CustomLink link={item} >{htmlParser(item?.title ?? '')}</CustomLink>
                        }
                    </div>

                ))}
            </div>

        ))
    }

    return (
        <div className="linksGenerate">
            {generateMenuLinks(groupedMenuItems)}
        </div>
    );
};

export default observer(LinksGenerate);
