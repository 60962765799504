import React, {Fragment} from "react";
import {
    AnalyticsEnum,
    AnalyticsEventsEnum,
    AnalyticsEventsMap,
    CookieManagersEnum,
    CustomAnalyticEventPayload,
    MatomoTracking
} from "@jnext/commons";
import {analytics} from "../store/analytics";

/**
 * function that generate the analytics scripts
 * @param initializationScript
 * **/
export const generateCookieBotScript = (initializationScript: Record<string, any>[]) => {
    return initializationScript?.map(script => {
        return (
            <script
                key={script?.id}
                id={script?.id}
                src={script?.src}
                data-cbid={script?.dataCbid}
                data-blockingmode={script?.dataBlockingmode}
                type={script?.type}
                async={script?.async}
            />
        )
    })
}

/**
 * function that generate the matomo scripts
 * @param initializationScript
 * **/
export const generateMatomoScript = (initializationScript: Record<string, any>[]) => {
    return initializationScript?.map((script, index) => {
        return (
                <script type="text/javascript" data-cookieconsent="statistics" key={index}>
                        {`
                    var _paq = window._paq = window._paq || [];
                    _paq.push(['setCustomUrl', window.location.pathname]);
                    _paq.push(['trackPageView']);
                    _paq.push(['enableLinkTracking']);
                    (function() {
                        var u='${script.url}';
                        _paq.push(['setTrackerUrl', u+'matomo.php']);
                        _paq.push(['setSiteId', '${script.siteId}']);
                        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                        g.async=true; g.src='${script.src}'; s.parentNode.insertBefore(g,s);
                    })();
                    `}
                </script>

        )
    })
}

/**
 * function that generate the Google Analytics scripts
 * @param initializationScript
 * **/
export const generateGoogleScriptGTM = (initializationScript: Record<string, any>[]) => {
    return initializationScript?.map((script, index) => {
        return (
            <script type="text/javascript" data-cookieconsent="statistics" key={index}>
                    {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${script?.id}');
            `}
            </script>

        )
    })
}

/**
 * function that generate the Google Analytics scripts
 * @param initializationScript
 * **/
export const generateGoogleScriptGA = (initializationScript: Record<string, any>[]) => {
    return initializationScript?.map((script) => {
        return (

                <script type="text/javascript" data-cookieconsent="statistics" async src={`https://www.googletagmanager.com/gtag/js?id=${script.id}`}>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${script.id}');
                    `}
                </script>
        )
    })
}

/**
 * function that generate the scripts
 * @param scripts
 * **/
export function generateScript(scripts: Record<string, any>) {
    return Object.keys(scripts)?.map((key) => {
        const script = scripts?.[key];
        if (script?.enabled) {
            switch (key) {
                case CookieManagersEnum.COOKIE_BOT:
                    return generateCookieBotScript(script?.initializationScript);

                case AnalyticsEnum.MATOMO:
                    return generateMatomoScript(script?.initializationScript);

                case AnalyticsEnum.GTM:
                case AnalyticsEnum.GOOGLE:
                    return generateGoogleScriptGTM(script?.initializationScript);

                case AnalyticsEnum.GA: {
                    return scripts = generateGoogleScriptGA(script?.initializationScript);
                }
            }
        }
    })
}

/**
 * function that tracks matomo events
 * @param obj
 * @param location
 * **/
export const matomoTrackingView = (obj: MatomoTracking, location?: string) => {
    const _paq = window._paq || [];
    if (obj.type === 'trackPageView') {
        _paq.push(['setCustomUrl', location]);
    }
    _paq.push([obj.type, obj?.category, obj?.action, obj?.name, obj?.value]);
}

export const googleTrackingView = (obj: any) => {
    const _dataLayer = window.dataLayer|| [];
    _dataLayer.push(obj);
}

/**
 * function that tracks google events
 * @param obj
 * **/
export const googleTrackingEvents = (obj: AnalyticsEventsMap) => {
    const _dataLayer = window.dataLayer|| [];
    _dataLayer.push({
        event: obj?.event, //"customEvent",
        eventCategory: obj?.category,
        eventAction: obj?.action,
        eventName: obj?.name,
        eventValue: obj?.value
    });
}

/**
 * function that returns only enabled analytics, adding the track event function specific to each analytic
 * @param scripts
 * **/
export function getEnabledScript(scripts: Record<string, any>) {
    let obj: any = {};

    Object.keys(scripts)?.forEach((key) => {
        const script = scripts?.[key];
        if (script?.enabled) {
            switch (key) {
                case AnalyticsEnum.MATOMO:
                    script.eventTrackingCallBack = ((obj: MatomoTracking) => matomoTrackingView(obj))
                    break
                case AnalyticsEnum.GA:
                case AnalyticsEnum.GTM:
                case AnalyticsEnum.GOOGLE:
                    script.eventTrackingCallBack = ((obj: AnalyticsEventsMap) => googleTrackingEvents(obj))
                    break
            }
            obj = { ...obj, ...scripts }
        }
    })
    return obj;
}

/**
 * function returns the parameters for tracking enabled events
 * @param eventTracking
 * @param event
 * **/
function getEventsTrack(eventTracking: AnalyticsEventsMap[], event: string) {
    return eventTracking.filter(event => event.enabled).find((track: AnalyticsEventsMap) => track.event === event)
}

/**
 * function that starts tracking automatically for each event
 * @param analyticEvent
 * @param customEventInfo
 * **/
export function trackingEventsMaps(analyticEvent: AnalyticsEventsEnum, customEventInfo?: CustomAnalyticEventPayload) {
    // Retrieving the analytics for the related Contxt
    const analyticScripts: Record<string, any> | undefined = analytics.analytics;

    // If there are no analytics we stop the function 
    if (!analyticScripts) return;
    
    // Iterating on each analytic to trigger the related tracking event if the latter is enabled 
    Object.keys(analyticScripts)?.forEach((analyticKey) => {

        const script = analyticScripts?.[analyticKey];
        if (script?.enabled) {
            const eventToTrack = getEventsTrack(script.eventTracking, analyticEvent) // get the single event from the analytic map
            if (eventToTrack) {
                const customEvent = customEventInfo?.find(c => c.analyticType === analyticKey)
                switch (analyticKey) {
                    case AnalyticsEnum.MATOMO:
                        const eventTrackingObject = { ...eventToTrack, type: 'trackEvent' }
                        return script.eventTrackingCallBack(customEvent ? ({ ...customEvent, type: 'trackEvent' }) : eventTrackingObject)
                    case AnalyticsEnum.GA:
                    case AnalyticsEnum.GTM:
                    case AnalyticsEnum.GOOGLE:
                        return script.eventTrackingCallBack(customEvent ? customEvent : eventToTrack)
                }
            }
        }
    })
}