import {JPage, JSection, JSectionsWrapper} from "@jnext/commons";
import {Col, Row} from "antd";
import React, {FC, useEffect, useMemo, useState} from "react";
import {CartProductItem} from "./components";
import {CartInfoSection, CartProductsListSection, CartSectionTypes,} from "./models";
import {CartService} from "service/CartService";
import {observer} from "mobx-react";
import {CartSummary} from "./components";
import './Cart.scss';
import {findCartSection, isInfoSection, isProductsListSection} from "./utils";
import {useTranslationWord} from "utils";
import {useCartStore} from "store/cartStore";
import {Navigate, useParams} from "react-router-dom";
import {BasketResponse, BasketTypeEnum, GetBasketResponseItem} from "@jnext/ts-axios-formatdigitalcollection";
import {useCMSStore} from "../../store/cmsStore";
import MetaTags from "../../components/MetaTags/MetaTags";
import MessageBox from "../../components/Messages/MessageBox";
import {useMessages} from "../../components/Messages/messageHooks";


const typeToShow = ["COMPANY_CLOSURE"]
export const Cart: FC = observer(() => {
    const translate = useTranslationWord();

    const {pages, setCurrentPage} = useCMSStore();
    const config = useMemo(() => pages?.cart, [pages]);

    useEffect(() => {
        setCurrentPage(pages?.cart ?? undefined);
    }, [config?.page]);

    if (!config || !config?.enabled) {
        return <Navigate to='/404'/>
    }

    const {initiativeLogicId} = useParams();

    const [basket, setBasket] = useState<BasketResponse>();
    const cartStore = useCartStore() // TODO Implement
    const sectionCMS = useMemo(() => config?.section?.sections?.find(el => el.type == `CART_SECTION_${initiativeLogicId}`) , [config]);

    const listSection = useMemo<CartProductsListSection | null>(() => {
        const _listSection = sectionCMS?.sections?.find(findCartSection(CartSectionTypes.CART_PRODUCTS_LIST))
        if (_listSection && isProductsListSection(_listSection)) {
            return _listSection
        }
        return null;

    }, [sectionCMS]);


    const cartInfoSection = useMemo<CartInfoSection | null>(() => {
        const _cartInfoSection = sectionCMS?.sections?.find(findCartSection(CartSectionTypes.CART_INFO))
        if (_cartInfoSection && isInfoSection(_cartInfoSection)) {
            return _cartInfoSection
        }

        return null;
    }, [sectionCMS]);


    /**
     * Remove item from cart
     * @param item
     */
    const removeBasketItem = async (item: GetBasketResponseItem) => {
        await CartService.remove(item, initiativeLogicId!, BasketTypeEnum.BasketPurchase);

        // Reload all basket data
        reloadBasket();
    }

    const updateBasketQuantity = async (item: GetBasketResponseItem, quantity: number) => {
        await CartService.update(item, initiativeLogicId!, BasketTypeEnum.BasketPurchase, quantity);

        // Reload all basket data
        reloadBasket();
    }

    /**
     * Reload basket data
     */
    const reloadBasket = async () => {
        const cartResponse = await CartService.getCart(initiativeLogicId!, BasketTypeEnum.BasketPurchase);
        setBasket(cartResponse);
    }

    /**
     * First open -> load basket data
     */
    useEffect(() => {
        reloadBasket();
    }, []);


    const {messages} = useMessages();

    return (
        <JPage {...(config)} enabled context="cart">
            <MetaTags pageConfiguration={config.page}/>
            <JSection {...(sectionCMS)} context="cart">
                <Row className="ant-row-context-cart">
                    <Col md={24} lg={16}>
                        <JSectionsWrapper>
                            {
                                basket?.items?.length === 0 && (
                                    <div className="cart-isempty">
                                        {
                                            translate('CART_ISEMPTY')
                                        }
                                    </div>
                                )
                            }
                            {
                                listSection && basket?.items?.map((item, i) => (
                                    <CartProductItem
                                        section={listSection}
                                        item={item}
                                        key={i}
                                        removeItem={() => removeBasketItem(item)}
                                        updateItemQuantity={(quantity) => updateBasketQuantity(item, quantity)}
                                    />
                                ))
                            }
                        </JSectionsWrapper>
                    </Col>
                    <div className={"cart-container-desktop"}>
                        <Col flex={'auto'}>
                            {
                                cartInfoSection && basket &&
                                (!!basket?.cashTotal && basket?.cashTotal > 0 || !!basket?.pointsTotal && basket?.pointsTotal > 0)
                                && (
                                    <>
                                        {
                                            messages
                                                .filter((message) => typeToShow.includes(message?.messageType ?? ''))
                                                .map((message) => {
                                                    return (
                                                        <MessageBox
                                                            msgTitle={message?.msgTitle ?? ''}
                                                            msgSubtitle={message?.msgSubtitle ?? ''}
                                                            msgBody={message?.msgBody ?? ''}
                                                            msgTitleColor={message?.msgTitleColor ?? ''}
                                                            msgSubtitleColor={message?.msgSubtitleColor ?? ''}
                                                            msgBodyColor={message?.msgBodyColor ?? ''}
                                                            fromDate={message?.fromDate ?? ''}
                                                            toDate={message?.toDate ?? ''}
                                                            msgLogoUrl={message?.msgLogoUrl ?? ''}
                                                            msgDisclaimerColor={message?.msgDisclaimerColor ?? ''}
                                                        />
                                                    )
                                                })}

                                        <CartSummary
                                            basket={basket}
                                            section={cartInfoSection}
                                            initiativeLogicId={initiativeLogicId!}
                                            basketType={BasketTypeEnum.BasketPurchase}
                                        />
                                    </>

                                )
                            }
                        </Col>
                    </div>

                </Row>
            </JSection>
        </JPage>
    )
})