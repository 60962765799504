import React from "react";
import {JCard, getProductImage} from "@jnext/commons";
import {CardTag} from "./CardTag";
import {GiftOutlined, LockOutlined} from "@ant-design/icons";
import {CatalogItemValue} from "../../commons/ItemValue/CatalogItemValue";
import {generatePathUrl, SentencesComposeComponent, useTranslationWord} from "../../utils";
import {ProductPreviewDto, ProductPreviewRedemptionModalityDto} from "@jnext/ts-axios-formatdigitalcollection";
import {Options} from "../CatalogSlider/LoyaltyCollectionSlider";

interface MyPriceProps {
    redemptionModality: ProductPreviewRedemptionModalityDto
}

const MyPrice: React.FC<MyPriceProps> = ({redemptionModality}) => {
    return redemptionModality ? (
        <div className={'item-value-container'}>
            <CatalogItemValue redemptionModality={redemptionModality}/>
        </div>
    ) : <></>;
}

export interface CatalogCardItemProps {
    isDigital?: boolean,
    showPrice?: boolean,
    pricePosition?: 'TOPRIGHT' | 'BOTTOMLEFT';
    showBrandIcon?: boolean;
    showTag?: boolean;
    data: ProductPreviewDto,
    onClick: () => void,
    isProductVas?: boolean,
    obtainableOptions?: any,
    navigationUrl?: string,
    isRewardMgm?: boolean,
    noOverlay?: boolean,
    positionCard?: 'vertical' | 'horizontal';
    options?: Options;
}

export const CatalogCardItem: React.FC<CatalogCardItemProps> = ({
                                                                    isDigital = false,
                                                                    showPrice = true,
                                                                    pricePosition = 'TOPRIGHT',
                                                                    showBrandIcon = true,
                                                                    showTag = false,
                                                                    data,
                                                                    onClick,
                                                                    obtainableOptions,
                                                                    isRewardMgm = false,
                                                                    noOverlay = false,
                                                                    isProductVas = false,
                                                                    positionCard = 'vertical',
                                                                    options
                                                                }) => {
    const sectors = data?.sectors;
    const brand = data?.brands?.[0];
    const translate = useTranslationWord();
    const ObtainableQuantity = ({obtainableQuantity, totalQuantity}:
                                { obtainableQuantity?: number, totalQuantity?: number }) => {

        if (obtainableQuantity === null || obtainableQuantity === undefined) {
            return <></>
        }

        let text = obtainableOptions?.initial?.text;
        let className;
        let obtainableImage;

        if (obtainableQuantity === 0) {
            className = obtainableOptions?.close?.color;
            text = obtainableOptions?.close?.text;
            obtainableImage = obtainableOptions?.close?.image?.[0];
        } else if (obtainableQuantity === 1) {
            className = obtainableOptions?.alert?.color;
            text = obtainableOptions?.alert?.text;
            obtainableImage = obtainableOptions?.alert?.image?.[0];
        } else if (obtainableQuantity === totalQuantity) {
            className = obtainableOptions?.initial?.color;
            text = obtainableQuantity + " " + obtainableOptions?.initial?.text;
            obtainableImage = obtainableOptions?.initial?.image?.[0];
        } else if (totalQuantity && (obtainableQuantity < totalQuantity)) {
            className = obtainableOptions?.active?.color;
            text = obtainableQuantity + " " + obtainableOptions?.active?.text;
            obtainableImage = obtainableOptions?.active?.image?.[0];
        }

        return (<div className={'card-obtainable-quantity'} style={{color: className}}>
            {!obtainableImage?.src ?
                <GiftOutlined/>
                :
                <figure>
                    <img src={generatePathUrl(obtainableImage.src)} alt={obtainableImage?.title} aria-hidden={true}/>
                </figure>
            }
            <SentencesComposeComponent className='point' text={text}/>
        </div>)
    }

    const positionCount: Record<string, number> = {};

    return (
        <div className={'card-catalog'}>
            {/* CARD COMPONENT */}
            <JCard
                coverImageUrl={generatePathUrl(getProductImage(data))}
                type={positionCard}
                headerContent={<>
                    {
                        showPrice && pricePosition === 'TOPRIGHT' && data?.redemptionModalities && data?.redemptionModalities?.length == 1 && (
                            <MyPrice redemptionModality={data?.redemptionModalities?.[0]}/>
                        )
                    }
                    {
                        showTag && (
                            <ul className={'tag-list-container'}>
                                {data?.tags?.map((el, idx) => (
                                    <li key={idx}>
                                        <CardTag
                                            tag={el}
                                            positionCount={positionCount}
                                        />
                                    </li>
                                ))}
                            </ul>
                        )
                    }
                </>
                }
                contentComponent={<div
                    className={`card-catalog-content ${positionCard === 'horizontal' ? 'flex-column' : ''}`}>
                    {
                        !!sectors && sectors?.length > 0 &&
                        <div className={'card-title'}
                             dangerouslySetInnerHTML={{__html: sectors?.map(el => (el as any)?.title).filter(el => el).join(', ')}}/>
                    }
                    <div className={'card-subtitle-container'}>
                        {
                            data?.title &&
                            <div className={'card-subtitle'} dangerouslySetInnerHTML={{__html: data?.title}}/>
                        }
                        {
                            options?.showDescription && data?.description &&
                            <div className={'card-subtitle'} dangerouslySetInnerHTML={{__html: data?.description}}/>
                        }
                    </div>
                    {
                        brand &&
                        <div className={'card-brand'}>
                            {
                                brand?.brandLogo &&
                                <div className={'card-brand-image'}>
                                    <figure>
                                        <img src={generatePathUrl(brand?.brandLogo)} alt={brand?.title}
                                             aria-hidden={true}/>
                                    </figure>
                                </div>
                            }
                            {
                                brand?.title &&
                                <div className={'card-brand-name'} dangerouslySetInnerHTML={{__html: brand?.title}}/>
                            }
                        </div>
                    }
                    {/* Obtainable Quantity*/}
                    {(data?.obtainableQuantity!! >= 0) && obtainableOptions &&
                        <ObtainableQuantity obtainableQuantity={data?.obtainableQuantity}
                                            totalQuantity={data?.totalQuantity}/>}

                    {
                        showPrice && pricePosition != 'TOPRIGHT' && data?.redemptionModalities && data?.redemptionModalities?.length == 1 && (
                            <MyPrice redemptionModality={data?.redemptionModalities?.[0]}/>
                        )
                    }


                </div>}
            />

            {/* CARD OVERLAY */}
            <div className={`card-overlay ${isProductVas || data?.purchaseEnabled ? 'pointer' : ''}`} onClick={() => {
                if (isProductVas || data?.purchaseEnabled) {
                    onClick();
                }
            }}>
                <div className={`card-overlay-content ${(isProductVas || noOverlay) ? 'noOverlay' : ''}`}>
                    <div className={'content'}>
                        {
                            data?.purchaseEnabled &&
                            <div className={'more'}>
                                {
                                    isRewardMgm
                                        ?
                                        translate('mgm_reward_details_button')
                                        :
                                        <>{translate('find_out_more')}</>
                                }

                            </div>
                        }

                        {
                            !data.purchaseEnabled &&
                            <div className={'lock'}>
                                <LockOutlined/>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}