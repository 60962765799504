import { action, makeObservable, observable } from "mobx";
import { useContext, createContext } from "react";

interface CheckoutStore {
    initiativeLogicId?: string;
    productLogicId?: string;
    quantity?: number;
    redemptionModalityLogicId?: string;
}

export class VasStore {
    checkout: CheckoutStore = {};


    constructor() {
        makeObservable(this, {
            checkout: observable,
            setServicePurchased: action,
            setCheckout: action
        });
    }

    setServicePurchased = (servicePurchased: boolean) => {
       sessionStorage.setItem('servicePurchased', servicePurchased.toString());
    }

    removeServicePurchased = () => {
        sessionStorage.removeItem('servicePurchased');
    }

    setCheckout = (checkout: CheckoutStore) => {
        this.checkout = checkout;
    }
}


export const vasStore = new VasStore();

export const useVasStore = () => useContext(
    createContext<VasStore>(vasStore)
);


