import React from 'react';
import './templates/T1/index.scss';
import { FC } from 'react';
import {CMSConfiguration, JSection, JSidebar} from "@jnext/commons";


export interface GenericPageProps {
    errorCode?: string,
    title?: string,
    additionalText?: string,
    cmsConfig: CMSConfiguration | undefined
}
export const GenericPage: FC<GenericPageProps> = (props) => {

    let cmsPage = props.cmsConfig?.page
    const cmsSection = props.cmsConfig?.section

    //Se presente il banner nella sezione, lo sovrascrivo a quello della pagina
    if(!!cmsPage && !!cmsSection && cmsSection.banner){
        cmsPage.banner = cmsSection.banner
    }

    const title = props.title || cmsSection?.title
    const additionalText = props.additionalText || cmsSection?.text
    const renderContent = (
        <JSection title={title} text={additionalText} subTitle={cmsSection?.subTitle}>
            <div className="coreuser-content">
                {
                    props.errorCode ? (
                        props.errorCode
                    ) : (
                        props.children
                    )
                }
            </div>
        </JSection>
    )

    if (cmsPage) {
        return (
            <div className='jpage coreuser-page'>
                <JSidebar page={cmsPage as any}>
                    {renderContent}
                </JSidebar>
            </div>
        )
    }
    return (
        <div className='jpage coreuser-page'>
            {renderContent}
        </div>
    )
}