import "./vas.scss";
import React, {FC, useEffect, useMemo, useState} from "react";
import {
    CMSCTA,
    CMSSection,
    getNavPathFromType,
    JCMSCTA,
    JCMSImage,
    JSection,
    JSkeletonSlider,
    NavigationTypes
} from "@jnext/commons";
import {JCardVas} from "./Components/JCardVas";
import {VasService} from "../../service/VasService";
import {PlanStatusEnum, ProductPreviewDto} from "@jnext/ts-axios-formatdigitalcollection";
import {AuthService} from "../../service/AuthService";
import {mzUseNavigate, useTranslationWord} from "../../utils";
import {JBoxInfoWarning} from "./Components/JBoxInfoWarning";
import {JComparisonTable} from "./Components/JComparisonTable";
import {PlanComparisonVasCmsDTO} from "@jnext/ts-axios-mz";
import {CMSService} from "../../service/CMSService";

interface JVasSectionProps {
    section?: CMSSection;
}

// Definisce il componente JVasCardSection che accetta una proprietà section di tipo CMSSection
export const JVasCardSection: FC<JVasSectionProps> = ({section}) => {

    const navigate = mzUseNavigate();
    const isAuth = useMemo(() => AuthService.authorized, []);

    // Ottiene la funzione di traduzione
    const translateWord = useTranslationWord();

    // Trova i pulsanti CTA (Call To Action) specifici nella sezione per utenti non autenticati
    const registrationBtn = !AuthService.authorized && section?.cta?.find((cta: CMSCTA) => cta?.scope === NavigationTypes.REGISTRATION);

    // Usa useMemo per memorizzare le options della sezione
    const options = useMemo(() => section?.options, [section]);

    // Stato per memorizzare le carte
    const [cards, setCards] = useState<ProductPreviewDto[]>([]);

    // Stato per indicare se è stato acquistato un abbonamento
    const [isSubscriptionPurchased, setIsSubscriptionPurchased] = useState<boolean>(false);

    const [vasCms, setVasCms] = useState<PlanComparisonVasCmsDTO[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const data = await CMSService.retrieveCMSVasConfig();
                setVasCms(data);
            } catch (err) {
                console.error('Errore nel caricamento della configurazione CMS:', err);
            }
        })();
    }, []);

    // Effettua una chiamata API per ottenere le carte VAS e aggiorna lo stato
    useEffect(() => {
        (async () => {
            if (!!options?.logicId) {
                const data = await VasService.getVasProducts(options.logicId as string);
                const isScheduled = data?.filter(card => card?.serviceDetails?.status === PlanStatusEnum.Scheduled);
                if (!!isScheduled?.length!) {
                    return navigate(getNavPathFromType(NavigationTypes.HOME));
                }
                if (data) {
                    setCards(data);
                    setIsSubscriptionPurchased(data?.filter(card => card?.serviceDetails?.status === PlanStatusEnum.Purchased).length > 0);
                }
            }
        })();
    }, [options?.logicId]);

    return (
        <div className={`${isAuth ? 'vas-card-section-container-auth' : 'vas-card-section-container'}`}>
            <JSection {...section} title={section?.title}>
                <div className={'vas-card-page'}>
                    <div className={`${!section?.image && !isSubscriptionPurchased ? 'vas-header' : 'vas-header_minor'}`}>
                        {/* Mostra l'immagine dell'intestazione se presente */}
                        {section?.image &&
                            <div className={'vas-header-image'}>
                                <JCMSImage image={section?.image}/>
                            </div>
                        }
                        {/* Mostra un messaggio di avviso se è stato acquistato un abbonamento */}
                        {isSubscriptionPurchased && <JBoxInfoWarning text={translateWord('charge_next_renewal')}/>}
                    </div>

                    {/* Mostra uno scheletro di caricamento se non ci sono carte */}
                    {!cards || cards.length === 0 && <JSkeletonSlider hasTitle={true}/>}

                    <div className={`${isAuth ? 'vas-card-container-auth' : 'vas-card-container'}`}>
                        {cards?.map((card, index) => {
                                return (
                                    <JCardVas
                                        key={index}
                                        isMainCard={(index % 2 === 0)}
                                        card={card}
                                        section={section}
                                        isSubscriptionPurchased={isSubscriptionPurchased}/>
                                )
                            }
                        )}
                    </div>

                    {
                        vasCms?.length > 0 && <>
                            <div className={`${isAuth ? 'vas-comparison-auth' : 'vas-comparison'}`}>
                                <JComparisonTable cards={cards} vasCms={vasCms}/>
                            </div>

                            {/* Mostra il pulsante di registrazione se presente e l'utente non è autenticato */}
                            {registrationBtn &&
                                <div className={'vas-registration-cta footer-margin'}>
                                    <JCMSCTA {...registrationBtn} />
                                </div>
                            }
                        </>
                    }
                </div>
            </JSection>
        </div>
    )
}
