import React, {useEffect, useMemo, useState} from "react";
import {CMSConfiguration, CMSSection, JCMSCTA, JHTML, JModal, NavigationTypes} from "@jnext/commons";
import {VasService} from "../../service/VasService";
import {useCMSStore} from "../../store/cmsStore";
import {browserRedirect, useTranslationWord} from "../../utils";
import {UserSubscriptionOverviewDto} from "@jnext/ts-axios-formatdigitalcollection";
import SubscriptionDetail from "../../components/Vas/Components/SubscriptionDetail";
import {useVasStore} from "../../store/VasStore";

export enum ModalType {
    MANAGE_PLAN = 'MANAGE_PLAN',
    CANCEL_PLAN = 'CANCEL_PLAN',
    CANCEL_ACTIVATION = 'CANCEL_ACTIVATION'
}

const SubscriptionDetails = () => {
    const [showModal, setShowModal] = useState<ModalType>();
    const [subscriptionData, setSubscriptionData] = useState<UserSubscriptionOverviewDto | undefined>(undefined);
    const {pages} = useCMSStore();
    const {removeServicePurchased} = useVasStore();
    const translateWord = useTranslationWord();

    const cmsConfigVas: CMSConfiguration | undefined = useMemo(() => pages?.vas, [pages?.vas]);
    const cmsConfig: CMSConfiguration | undefined = useMemo(() => pages?.profile, [pages?.profile]);
    const initiativeLogicId: string = cmsConfigVas?.section?.sections?.[0]?.options?.logicId as string;

    const activeSubscription = useMemo(() => subscriptionData?.hasSubscription, [subscriptionData]);
    const scheduledChange = useMemo(() => subscriptionData?.scheduledChange, [subscriptionData]);
    const subscriptionDetail = useMemo(() => subscriptionData?.currentSubscription, [activeSubscription]);

    const cmsSection: CMSSection | undefined = cmsConfig?.section?.sections?.find(section => section?.type === NavigationTypes.SUBSCRIPTION_DETAILS);
    const cmsSectionMain: CMSSection | undefined = cmsSection?.sections?.find(section => section?.type === 'MAIN');
    const goToVas = cmsSectionMain?.cta?.find(cta => cta?.scope === 'VAS');
    const cancelModalVas = cmsSectionMain?.cta?.find(cta => cta?.scope === 'CANCEL_MODAL');
    const confirmModalVas = cmsSectionMain?.cta?.find(cta => cta?.scope === 'CONFIRM_MODAL');
    const [refreshService, setRefreshService] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            if (initiativeLogicId || refreshService) {
                await getSubscriptionDetails()
            } else {
                console.log('Invalid initiativeLogicId:', initiativeLogicId);
            }
        })();
    }, [initiativeLogicId, refreshService]);

    async function getSubscriptionDetails() {
        try {
            const data = await VasService.getSubscriptionDetails(initiativeLogicId);
            setSubscriptionData(data);
        } catch (e: any) {
            console.log('Error fetching subscription details:', e);
        }

    }

    //gestisci metodo di pagamento e disattiva abbonamento
    const goToVasUserPortal = async () => {
        const res = await VasService.customerPortal(initiativeLogicId);
        setShowModal(undefined);

        if (res?.url) {
            removeServicePurchased();
            browserRedirect(res.url);
        }
    }

    //disattiva upgrade abbonamento
    const cancelSubscription = async () => {
        if(initiativeLogicId && scheduledChange?.referredRedemptionLogicId){
            const resp = await VasService.delVasSubscription(initiativeLogicId, scheduledChange?.referredRedemptionLogicId);
            setShowModal(undefined);
            if(resp === 200){
                await getSubscriptionDetails();
            }
        }
    }

    return (
        <div className="general-info-container">
            <label className="title">
                {cmsConfig?.section?.sections?.[0]?.sections?.[0]?.title}
            </label>

            {activeSubscription ?
                <>
                    {subscriptionDetail &&
                        <SubscriptionDetail
                            initiativeLogicId={initiativeLogicId}
                            subscriptionDetail={subscriptionDetail}
                            scheduledDateChange={scheduledChange?.activationDate}
                            onClick={(type: ModalType)=>setShowModal(type)}
                            cmsSection={cmsSectionMain}
                            setRefreshService={setRefreshService}
                        />}

                    {scheduledChange && <>
                        <div className={'line'}/>
                        <SubscriptionDetail
                            scheduledChange={scheduledChange}
                            onClick={(type: ModalType)=>setShowModal(type)}
                            cmsSection={cmsSectionMain}
                        />
                    </>}
                </>
                :
                <div className="details-wrapper">
                    <div className="no-subscription-text-container">
                        <div className="text-no-subscription">
                            <JHTML.Text html={translateWord("NO_SUBSCRIPTION_PURCHASED_TEXT")}/>
                        </div>
                        <div className="button-container">
                            {goToVas && <JCMSCTA {...goToVas} />}
                        </div>
                    </div>
                </div>}

            {showModal && <JModal
                width={520}
                title={translateWord(`TITLE_${showModal}_VAS`)}
                children={<>{translateWord(`TEXT_${showModal}_VAS`)}</>}
                isModalVisible={true}
                handleCancel={()=>setShowModal(undefined)}
                footer={<>
                    {cancelModalVas && <JCMSCTA {...cancelModalVas} action={()=>setShowModal(undefined)} />}
                    {confirmModalVas && <JCMSCTA
                        {...confirmModalVas}
                        action={(showModal === ModalType.CANCEL_ACTIVATION) ? cancelSubscription : goToVasUserPortal} />}
                </>}
            />}
        </div>
    );
};

export default SubscriptionDetails;
