import { NavigationType, NavigationTypes, getNavPathFromType } from "@jnext/commons";
import { GetConsumerDto } from "@jnext/ts-axios-coreuser";
import {FC, useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import { useConsumerStore } from "store/consumerStore";
import MicroApp from "../../commons/MicroApp";
import { LoginType } from "../../enums/LoginType";
import { AuthService } from "../../service/AuthService";
import { HttpService } from "../../service/HttpService";
import { useAppStore } from "../../store/appStore";
import {browserRedirect, decodeBody, mzUseNavigate} from "../../utils";
import {ConsumerService} from "../../service/ConsumerService";
import {useCMSStore} from "../../store/cmsStore";

interface LoginProps {
    layout?: 'page' | 'widget'
}
export const Login: FC<LoginProps> = (props) => {
    const navigate = mzUseNavigate();
    const [params] = useSearchParams()
    const { handleResponseError } = useAppStore();
    const { updateProfile } = useConsumerStore();

    const { setCurrentPage } = useCMSStore();

    useEffect(() => {
        setCurrentPage(undefined);
    }, []);

    return (<>
        {HttpService.env?.coreUser && (
            <MicroApp
                entry={`${HttpService.env?.coreUser}`}
                props={{
                    type: `login-${props.layout || 'widget'}`,
                    onSuccess: async(token: string, refreshToken: string, exp: number, consumerInfo: GetConsumerDto, flFinalization: boolean = false) => {
                        // set finalize status
                        if (flFinalization) {
                            // pass tokens to finalize
                            localStorage.setItem('tmp_refresh_token', refreshToken || '');
                            AuthService.setTempToken(token);
                            // pass tokens to finalize
                            return navigate(getNavPathFromType(NavigationTypes.REGISTRATION_FINALIZE));

                        } else {
                            AuthService.setToken(token);
                            AuthService.setRefreshToken(refreshToken);
                            AuthService.setLoginType(LoginType.standard);
                            await ConsumerService.retrieveConsumer();
                            await updateProfile();
                            const decodedToken = decodeBody(token);

                            await AuthService.timerRefreshToken(decodedToken);

                            // Navigate into website
                            const redirectParam = params.get('redirect')
                            const redirectPath = redirectParam?.startsWith('/') && redirectParam

                            if(HttpService.landingPageScope){
                                browserRedirect(getNavPathFromType(HttpService.landingPageScope));
                            }
                            else
                            {
                                browserRedirect(redirectPath || getNavPathFromType(NavigationTypes.PRIVATE_HOME));
                            }

                        }

                    },
                    onError: handleResponseError,
                    onNavigate(type: NavigationType) {
                        return navigate(getNavPathFromType(type))
                    },
                    keyCloakClientId: HttpService.env?.keycloakClientID as string,
                }}
            />
        )}
    </>
    )
}