import { CMSSection, JRangePicker, JSelect, OptionDto } from "@jnext/commons"
import { Col, Row } from "antd"
import locale_IT from "antd/es/date-picker/locale/it_IT"
import locale_ES from "antd/es/date-picker/locale/es_ES"
import locale_EN from "antd/es/date-picker/locale/en_GB"
import "moment/locale/it"
import "moment/locale/es"
import "moment/locale/en-gb"
import React, { useState } from "react"
import { FORMAT_DATE, useTranslationWord } from "../../../../../utils"
import { RedemptionResponse } from "@jnext/ts-axios-mz"

const _ = require("lodash")

interface FiltersHeaderProps {
  initiatives: RedemptionResponse[];
  handleChange: any;
  closeFilters: () => void;
  cms: CMSSection;
  showInitiative: boolean;
  hideWalletFilters?: boolean;
}

type OptionType = {
  id: string;
  label: string;
};

const FiltersHeader: React.FC<FiltersHeaderProps> = ({
                                                       initiatives,
                                                       handleChange,
                                                       closeFilters,
                                                       cms,
                                                       showInitiative,
                                                       hideWalletFilters = false
                                                     }) => {
  const translateWord = useTranslationWord()

  // filters values
  const [initiative, setInitiative] = useState<undefined | string>()
  const [status, setStatus] = useState<undefined | string>()
  const [date, setDate] = useState<string[]>()

  const lang = localStorage.getItem("ln");

  const localeMap = {
    it: locale_IT,
    es: locale_ES,
    gb: locale_EN
  }

  const defaultOptionValues = [
    {
      "id": "all",
      "label": "Tutti"
    },
    {
      "id": "ORDER_NOT_APPROVED",
      "label": "Ordine non approvato"
    },
    {
      "id": "ORDER_APPROVED",
      "label": "Ordine approvato"
    },
    {
      "id": "ORDER_ISSUE",
      "label": "Problema con l'ordine"
    },
    {
      "id": "ORDER_SENT",
      "label": "Ordine inviato"
    },
    {
      "id": "ORDER_DELIVERED",
      "label": "Ordine consegnato"
    },
    {
      "id": "ORDER_ANNULLED",
      "label": "Ordine annullato"
    },
    {
      "id": "ORDER_DELETED",
      "label": "Ordine cancellato"
    },
    {
      "id": "ORDER_EXPIRED",
      "label": "Ordine scaduto"
    },
    {
      "id": "ORDER_INSERTED",
      "label": "Ordine inserito"
    },
    {
      "id": "REDEMPTION_WAITING",
      "label": "Redenzione in attesa"
    },
    {
      "id": "REDEMPTION_NOT_ACCEPTED",
      "label": "Redenzione non accettata"
    },
    {
      "id": "REDEMPTION_NOT_APPROVED",
      "label": "Redenzione non approvata"
    },
    {
      "id": "REDEMPTION_DELETED",
      "label": "Redenzione cancellata"
    },
    {
      "id": "REDEMPTION_EXPIRED",
      "label": "Redenzione scaduta"
    }
  ]

  function isValidOptionTypeArray(value: any): value is OptionType[] {
    return (
      value && // Controlla che il valore esista (non sia null o undefined)
      Array.isArray(value) && // Controlla che sia un array
      value.length > 0 && // Controlla che l'array non sia vuoto
      value.every(option =>
        option && // Controlla che ogni elemento esista
        typeof option.id === "string" && // Controlla che id sia una stringa
        typeof option.label === "string" // Controlla che label sia una stringa
      )
    )
  }

  const OrderAndRedemptionOptions = isValidOptionTypeArray(cms?.filters?.status?.placeholder)
    ? cms?.filters?.status?.placeholder
    : defaultOptionValues

  const handleChangeStatus = (selectedId: string | number) => {
    const selectedOption = OrderAndRedemptionOptions?.find(option => option?.id === selectedId)
    if (selectedOption) {
      setStatus(selectedOption?.label as string)
      handleChange(initiative, selectedOption?.id, date)
    }
  }

  return (
    <Row className="filter" gutter={[32, 12]}>
      <Col span={24}>
        <div className={"backButton"} onClick={closeFilters}>
          &#60; &nbsp; {translateWord("goBack")}
        </div>
      </Col>
      {
        showInitiative && !hideWalletFilters &&
        <Col className="selectStyle" sm={24} md={8} xl={6}>
          <JSelect
            value={initiative}
            label={translateWord("INITIATIVE")}
            onChange={(e: any) => {
              if (e === 0) {
                setInitiative(undefined)
                return handleChange(undefined, status, date)
              }
              setInitiative(e)
              handleChange(e, status, date)
            }}
            options={
              [
                {
                  id: 0,
                  label: cms?.filters?.initiative?.placeholder[0]
                } as OptionDto
              ].concat(
                initiatives?.map((el: RedemptionResponse) => ({
                  id: el.referredInitiativeLogicId,
                  label: el.initiativeName
                }))
              )
            }
            placeholder={cms?.filters?.initiative?.label}
          />
        </Col>
      }
      {!hideWalletFilters && <Col className="selectStyle" sm={24} md={8} xl={6}>
        <JSelect
          value={OrderAndRedemptionOptions?.find(option => option?.label === status)?.id}
          label={translateWord("STATUS")}
          options={OrderAndRedemptionOptions?.map(option => ({
            id: option?.id,
            label: option?.label
          }))}
          onChange={handleChangeStatus}
          placeholder={cms?.filters?.status?.label}
        />
      </Col>
      }
      <Col className="selectStyle" sm={24} md={8} xl={6}>
        <JRangePicker
          showTime={false}
          format={FORMAT_DATE}
          label={translateWord("DATES_RANGE")}
          onChange={(period: any) => {
            const fromDate = period?.[0]?.format()
            const toDate = period?.[1]?.format()
            setDate([fromDate, toDate])
            handleChange(initiative, status, [fromDate, toDate])
          }}
          placeholder={[cms?.filters?.date?.placeholder?.[0] || "", cms?.filters?.date?.placeholder?.[1] || ""]}
          locale={localeMap[lang as keyof typeof localeMap] || locale_IT}
        />
      </Col>
    </Row>
  )
}

export default FiltersHeader
