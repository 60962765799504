import {useEffect, useMemo} from 'react';
import { ProfileContent } from "../../components";
import { observer } from "mobx-react";
import './style.scss';
import {CMSCTA, CMSConfiguration, JPage, JSection, breakpointsMin, JHTML} from "@jnext/commons";
import {Outlet, useLocation} from "react-router-dom";
import { Col, Row } from "antd";
import {
    useWindowSize,
} from '@react-hook/window-size';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useCMSStore } from 'store/cmsStore';
import {TranslateComponent} from "../../utils";

function tmpNormalizer(config: CMSConfiguration | undefined, tabsCTA: CMSCTA[], titlePlaceholder: string) {

    const _config: CMSConfiguration = JSON.parse(JSON.stringify(config || {
        enabled: true
    }))
    if (!_config.page) {
        _config.page = {
            enabled: true,
            title: '',
            subTitle: ''
        }
    }
    if (!!tabsCTA) {
        _config.section = {
            enabled: true,
            title: titlePlaceholder,
            type: 'TABS_LIST',
            sections: tabsCTA.map(cta => ({
                enabled: true,
                type: cta.scope,
                title: cta.title,
                cta: [cta]
            }))
        }
    }

    return _config

}
const Profile = () => {
    const [width] = useWindowSize();
    const isMobile = useMemo<boolean>(() => width < breakpointsMin?.md, [width]);
    const location = useLocation();

    const { t: translate } = useTranslation()
    const { structure, pages, setCurrentPage } = useCMSStore();
    const consumerInfo: CMSCTA[] | undefined = useMemo(() => structure?.profileNavigation, [structure]);
    const config: CMSConfiguration = useMemo(() => tmpNormalizer(pages.profile, consumerInfo || [], translate('profile')), [pages, structure]);

    useEffect(() => {
        setCurrentPage(pages?.profile ?? undefined);
    }, [config?.page]);

    if (!config || !config?.enabled) {
        return <Navigate to='/404' />
    }

    return (
        <JPage {...config.page} title='' enabled>
            <JSection {...config.section} title={isMobile ? config.page?.title : ''}>
                {
                    isMobile && (window?.location?.pathname === '/profile/contestHistory' || location?.pathname === '/profile/contestHistory') &&

                    <TranslateComponent text={'IW_HISTORY_LEGEND'} className={'iw-legend'}/>
                }

                <div className='profileContainer'>
                    <div className="profileContent">
                        {
                            !isMobile && config.page?.title &&
                            <JHTML.Title className="titleContainer" html={config.page?.title}/>
                        }

                        <Row className={isMobile ? 'mobile' : 'desktop'}>
                            <Col xs={24} md={7} xl={6}>
                                <ProfileContent mobileView={isMobile} sections={config.section?.sections || []}/>
                            </Col>
                            <Col className="outlet" xs={24} md={17} xl={18}>
                                <JSection>
                                    <Outlet/>
                                </JSection>
                            </Col>
                        </Row>
                    </div>
                </div>

            </JSection>

        </JPage>
    )
}

export default observer(Profile);