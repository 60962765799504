import React from "react";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {generatePathUrl, useTranslationWord} from "../../../../utils";

enum TransactionEnum {
    'POSITIVE' = 'POSITIVE',
    'NEGATIVE' = 'NEGATIVE'
}

interface Currency {
    externalId?: string;
}

interface PointRowProps {
    amount: number;
    currency?: Currency;
    src?: string;
}

export const PointRow: React.FC<PointRowProps> = ({amount, currency, src}) => {

    const translateWord = useTranslationWord();
    let styleColor = (amount >= 0) ? 'toGreen' : 'toRed';
    let transaction = (amount >= 0) ? TransactionEnum.POSITIVE : TransactionEnum.NEGATIVE;

    return (
        <div className={`pointRow`}>
            {transaction === TransactionEnum.POSITIVE && <PlusOutlined className={styleColor}/>}

            {transaction === TransactionEnum.NEGATIVE && <MinusOutlined className={styleColor}/>}

            {src && <img className={`walletImage ${styleColor}`} src={generatePathUrl(src)} aria-hidden={true}/>}
            {amount && <label className={`pointText ${styleColor}`}> {Math.abs(amount)} </label>}
            {currency?.externalId &&
                <label className={`pointText ${styleColor}`}>{translateWord(currency?.externalId)}</label>}
        </div>
    )
}
