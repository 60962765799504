import { generatePathUrl } from "../../../../utils"
import { JHTML } from "@jnext/commons"
import React from "react"
import "./infoBoxWarning.scss"

type JBoxInfoWarningProps = {
  text: string;
  isScheduled?: boolean;
};

export function JBoxInfoWarning({ text, isScheduled = false }: JBoxInfoWarningProps) {
  return (
    <div className={"info-box"}>
      <img src={generatePathUrl("warning-error.svg")} className={isScheduled ? "bg" : ""} aria-hidden={true} />
      <JHTML.Text html={text} />
    </div>
  )
}