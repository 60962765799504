import './howToSection.scss';
import React, {FC, useMemo} from "react";
import {
    breakpointsMin,
    CMSSection, JCMSCTA, JHTML, NavigationTypes,
} from "@jnext/commons";
import {AuthService} from "../../../../service/AuthService";
import {useWindowSize} from "@react-hook/window-size";

interface HowToSectionProps {
    section?: CMSSection;
}


const HowToSection: FC<HowToSectionProps> = ({section}) => {

    // Usa useMemo per memorizzare le options della sezione
    const options = useMemo(() => section?.options, [section]);
    const [width] = useWindowSize();

    const isTabletLayout = useMemo(() => (
        width < breakpointsMin.md
    ), [width]);

    const howToBtn = !AuthService.authorized && section?.cta?.find((cta) => cta?.scope === NavigationTypes.PUBLIC_HOW_TO);

    return(
        <div className={`${(options?.isColumnDisplay || isTabletLayout) ? 'how_to_container_column' : 'how_to_container_row'}`}>
            <div className={"left_side"}>
                {section?.text && <JHTML.Text html={section.text}/>}
            </div>

            <div className={"right_side"}>
                {/* Mostra il pulsante CTA "How To" se presente e l'utente non è autenticato */}
                {howToBtn &&
                    <div className={'vas-header-cta'}>
                        <JCMSCTA  {...howToBtn} />
                    </div>
                }
            </div>
        </div>
    );
}

export default HowToSection;