import moment from "moment";
import {FORMAT_DATE, useTranslationWord} from "../../../../utils";
import React from "react";
import {DeliveryVoucherContentDto} from "@jnext/ts-axios-reward";
import {CMSSection} from "@jnext/commons";

interface VoucherDetailProps {
    vouchers: DeliveryVoucherContentDto[];
    bodyCms?: CMSSection;
}

const VoucherDetail: React.FC<VoucherDetailProps> = ({vouchers, bodyCms}) => {
    const translateWord = useTranslationWord();

    return (
        <>
            {vouchers?.map((el: DeliveryVoucherContentDto, i) => (
                <div key={i}>
                    {bodyCms?.table?.voucherLink && el.content && (
                        <a
                            href={el.content}
                            target={"_blank"}
                            title={el?.content}
                            aria-label={`${translateWord("GO_TO")} ${el?.content}`}
                            className={"voucher-link"}
                        >
                            {bodyCms?.table?.voucherLink?.label}
                        </a>
                    )}
                    {el?.expiryDateLink && bodyCms?.table?.expiryDateLink && (
                        <div className={"voucher-details-label"}>
                            <span>{bodyCms?.table?.expiryDateLink?.label}</span>
                            <span>
                                {moment(el?.expiryDateLink)?.format(FORMAT_DATE) || translateWord("NO_DATE")}
                           </span>
                        </div>
                    )}
                    {el?.expiryDateVoucher && bodyCms?.table?.expiryDateVoucher && (
                        <div className={"voucher-details-label"}>
                            <span>{bodyCms?.table?.expiryDateVoucher?.label}</span>
                            <span>
                                {moment(el?.expiryDateVoucher)?.format(FORMAT_DATE) || translateWord("NO_DATE")}
                            </span>
                        </div>
                    )}
                </div>
            ))}
        </>
    );
};

export default VoucherDetail;
