import {CMSCTA, CMSPage, CMSSection} from "@jnext/commons";
import {StepperSection} from "./stepperModel";
import {CartInfoSection} from "../Cart/models";

// CMS Models ----------------
export interface CheckoutCMSConfig {
    enabled: boolean,
    page: CMSPage,
    section: CheckoutSection
}

export enum CheckoutMainSectionTypes {
    DIRECT_CHECKOUT = 'DIRECT_CHECKOUT',
    BASKET_CHECKOUT = 'BASKET_CHECKOUT',
}

export enum CheckoutSectionTypes {
    DATA_INSERT = 'DATA_INSERT',
    CONFIRM = 'CONFIRM',
    SUMMARY = 'SUMMARY',
}

export interface CheckoutDataInsertOptions {
    prefillUserData: boolean|null;
    fastDigitalCheckout: boolean|null;
    showModalConfirmPayment: boolean|null;
}

export enum CheckoutDataInsertSectionTypes {
    MAIN = 'MAIN',
}

export enum CheckoutConfirmSectionTypes {
    ORDERS = 'ORDERS',
    USER_DATA = 'USER_DATA',
    CART_INFO = 'CART_INFO',
}

export enum CheckoutSummarySectionTypes {
    MAIN = 'MAIN',
    ORDER = 'ORDER',
}


/******************** SECTION ********************/
export interface CheckoutDataSection extends CMSSection {
    type: CheckoutSectionTypes.DATA_INSERT,
    cta: [CMSCTA]
    sections?: (CMSSection & { type: CheckoutDataInsertSectionTypes })[],
}

export interface CheckoutConfirmSection extends CMSSection {
    type: CheckoutSectionTypes.CONFIRM,
    sections?: (CMSSection & { type: CheckoutConfirmSectionTypes })[]
}

export interface CheckoutSummarySection extends CMSSection {
    type: CheckoutSectionTypes.SUMMARY,
    sections?: (CMSSection & { type: CheckoutSummarySectionTypes })[]
}

export interface CheckoutSection extends CMSSection {
    sections: CheckoutMainSection[]
}

export interface CheckoutMainSection extends CMSSection {
    type: CheckoutMainSectionTypes,
    sections: CheckoutSubMainSection[]
}

export type CheckoutSubMainSection =
    CheckoutDataSection
    | CheckoutConfirmSection
    | CheckoutSummarySection
    | StepperSection
    | CartInfoSection