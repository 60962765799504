import {CMSConfiguration} from "@jnext/commons";
import {PlanComparisonVasCmsDTO, VasCmsApi, VasCmsDTO, WebAppApi} from "@jnext/ts-axios-mz";
import axios from "axios";
import {CMSConfig, CMSPages, CMSPagesConfig, CMSStructureConfig} from "../store/cmsStore";
import {AuthService} from "./AuthService";
import {HttpService} from "./HttpService";
import type {ConfigurationVasCmsDTO} from "@jnext/ts-axios-mz/models/configuration-vas-cms-dto";

export class CMSService {

    static async retrieveCMSConfig() {
        const webAppApi: WebAppApi = new WebAppApi(
            undefined,
            HttpService.servicePath('solution-mz'),
            axios as any
        );
        const response: any = AuthService.authorized ? await webAppApi.getWebAppPrivate() : await webAppApi.getWebAppPublic();
        const config: CMSConfig = response?.data?.configuration;

        return this.beautifyData(config);
    }

    static async retrieveCMSVasConfig(): Promise<PlanComparisonVasCmsDTO[]> {
        const vasCmsApi = new VasCmsApi(
            undefined,
            HttpService.servicePath('solution-mz'),
            axios as any
        );

        const response: any = AuthService.authorized
            ? await vasCmsApi.getVasCmsPrivate()
            : await vasCmsApi.getVasCmsPublic();

        return response?.data?.configuration?.planComparison ?? [];
    }


    static beautifyData({appSettings, pages, structure}: CMSConfig) {
        return {
            appSettings: appSettings || {},
            pages: this.getPages(pages || {}),
            structure: this.getStructure(structure || {}),
        }
    }


    static getPages(pagesConfig: CMSPagesConfig) {
        const pages: any = {};
        for (const [key, value] of Object.entries<CMSConfiguration>(pagesConfig)) {
            if (!!value && value.enabled) {
                pages[key as `${CMSPages}`] = value
            }
        }

        return pages;
    }

    static getStructure(structureConfig: CMSStructureConfig) {
        const structure: any = {};

        structure.footer = structureConfig.footer
        for (const [key, value] of Object.entries<any>(structureConfig)) {
            if (value) {
                structure[key as keyof CMSStructureConfig] = value
            }
        }

        return structure
    }
}