import {CMS_CONFIG} from "pages/Cart/cms";
import {CartCMSConfig} from "pages/Cart/models";
import {
    AddBasketItemRequest,
    BasketResponse,
    BasketTypeEnum,
    DigitalCollectionApi,
    GetBasketResponseItem,
    ProductDetailRedemptionModalityDto
} from "@jnext/ts-axios-formatdigitalcollection";
import {HttpService} from "./HttpService";
import axios from "axios";
import {WalletPoints} from "@jnext/ts-axios-wallet";
import {CatalogService, LocalStorageCatalogData} from "./CatalogService";
import {ModuleInitiativeDto} from "@jnext/ts-axios-coreconfiguration";
import {cartStore} from "../store/cartStore";

export class CartService {

    static async getValidInitiatives(basketType: BasketTypeEnum, initiativesId: LocalStorageCatalogData | null): Promise<ModuleInitiativeDto[]> {
        const validInitiatives: ModuleInitiativeDto[] = [];

        // no values
        if (!initiativesId) {
            return validInitiatives;
        }

        for (let i = 0; i < initiativesId?.initiatives?.length; i++) {
            const id = initiativesId.initiatives[i].logicId!
            try {
                const response = await this.getCart(id, basketType);

                if (response) {
                    validInitiatives.push(initiativesId.initiatives[i]);
                }
            } catch (e) {

            }
        }

        return validInitiatives;

    }

    static async getCart(initiativeLogicId: string, basketType: BasketTypeEnum): Promise<BasketResponse> {
        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);
        const res = await instance.getBasket(initiativeLogicId, basketType);
        return res?.data;
    }

    static async getCartPreview(initiativeLogicId: string, basketType: BasketTypeEnum): Promise<BasketResponse> {
        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);
        const res = await instance.getBasketPreview(initiativeLogicId, basketType);
        return res?.data;
    }

    static canAddToCart(basket: BasketResponse | undefined, wallets: WalletPoints[], redemptionModality: ProductDetailRedemptionModalityDto, quantityToAdd: number) {
        // Get wallet numbers
        const neededWalletNumber = wallets?.find(wallet => wallet?.logicId?.toLowerCase() === redemptionModality?.walletConfigurationLogicId?.toLowerCase())?.number || 0;

        // Item price
        const itemPrice = (CatalogService.getPrice(redemptionModality, wallets)?.points?.value || 0) * quantityToAdd;

        // Basket total if exists
        const basketTotal = !!basket && basket?.pointsTotal ? basket?.pointsTotal : 0;

        return neededWalletNumber - basketTotal - itemPrice >= 0;
    }

    static async add(initiativeLogicId: string, basketType: BasketTypeEnum, addBasketRequest: AddBasketItemRequest) {

        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);
        const res = await instance.addBasketItem(initiativeLogicId, basketType, addBasketRequest);

        if (basketType == BasketTypeEnum.BasketPurchase) {
            cartStore?.setBasketItems(res?.data?.items);
        }

        return res?.data;
    }

    static async remove(item: GetBasketResponseItem, initiativeLogicId: string, basketType: BasketTypeEnum) {

        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);
        const res = await instance.deleteBasketItem(initiativeLogicId, basketType, item?.catalogGroupProductLogicId!);

        if (basketType == BasketTypeEnum.BasketPurchase) {
            cartStore?.setBasketItems(res?.data?.items)
        }

        return res?.data;
    }

    static async update(item: GetBasketResponseItem, initiativeLogicId: string, basketType: BasketTypeEnum, quantity: number, purchaseParameters?: any[]) {

        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);
        const res = await instance.editBasketItem(initiativeLogicId, item?.catalogGroupProductLogicId!, basketType,
            {
                quantity: quantity,
                purchaseParameters : purchaseParameters ? purchaseParameters : [],
            });

        if (basketType == BasketTypeEnum.BasketPurchase) {
            cartStore?.setBasketItems(res?.data?.items)
        }

        return res?.data;
    }

    static getCMSConfig() {
        return new Promise<CartCMSConfig>((resolve, reject) => {
            setTimeout(() => {
                resolve(CMS_CONFIG)
            }, 154);
        })
    }
}