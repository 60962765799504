import React, { FC, useMemo } from "react"
import "./HighlightCard.scss"
import { JCMSCTA, useJCommonContext } from "@jnext/commons"
import { AuthService } from "../../service/AuthService"
import { Row } from "antd"
import { ArrowDownOutlined } from "@ant-design/icons"
import { ProductMetadataDto } from "@jnext/ts-axios-formatdigitalcollection"

interface HighlightCardProps {
  card: ProductMetadataDto;
  isMainCard?: boolean;
  isInGridLayout?: boolean;
  template?: any;
}

export const HighlightCard: FC<HighlightCardProps> = ({
                                                        card,
                                                        isMainCard = false,
                                                        isInGridLayout = false,
                                                        template
                                                      }) => {

  const isAuth = useMemo(() => AuthService.authorized, [])
  const { getImageURL } = useJCommonContext()

  const background = { background: (isMainCard ? card?.background : template?.backgroundCta) }
  const color = { color: (isMainCard ? card?.color : template?.colorCta) }

  return (
    <div
      className={isInGridLayout
        ? `grid-container ${isMainCard ? "main-container" : "minor-container"}`
        : "slider-container"}
      style={background}
    >

      {card?.logo && !isAuth &&
        <div className={"img-container"}>
          <img alt={"logo"} src={getImageURL(card?.logo)} aria-hidden={true} />
        </div>
      }

      {card?.title && <p style={color} className={isAuth ? "title-text-slider" : "title-text-grid"}
                         dangerouslySetInnerHTML={{ __html: card?.title }}></p>}

      {card?.description && <p style={color} className={"description-text"}
                               dangerouslySetInnerHTML={{ __html: card?.description }}></p>}

      {/*isAuth andrà rimosso quando ci sarà un servizio dedicato*/}
      {!isAuth && card?.cta && card?.cta.map((cta: any, index: number) => {
        const isButton = cta?.scope === "button"
        const ctaColor = cta?.color ? { color: cta?.color } : { color: card?.color }

        return <div
          className="cta-container"
          style={{
            justifyContent:
              cta?.position === "center"
                ? "center"
                : cta?.position === "end"
                  ? "flex-end"
                  : "flex-start"
          }}>
          {isButton ?
            <JCMSCTA
              {...cta}
              key={index}
              type={cta?.type || "button"}
              scope={cta?.scope}
              scopeParameters={cta?.scopeParameters}
              color={ctaColor}
              title={cta?.title}
              action={cta?.action}
              options={cta?.options}
            />
            :
            <Row className="row-linkAndLogo">
              <a
                className={"cta-link-text"}
                style={ctaColor}
                href={cta?.href}
                dangerouslySetInnerHTML={{ __html: cta?.title }}
              />
              {!cta?.image ? (
                <ArrowDownOutlined style={ctaColor} className={"icon-style"} />
              ) : (
                <div className="img-logo-container">
                  <img
                    alt="logoLink"
                    src={getImageURL(cta?.image)}
                    aria-hidden={true}
                  />
                </div>
              )}
            </Row>
          }
        </div>
      })}
    </div>
  )
}
