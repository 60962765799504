import {CMSSection} from "@jnext/commons";
import React, {useState} from "react";
import {Col, Row } from "antd";
import {FORMAT_DATE, generatePathUrl} from "../../../../../utils";
import moment from "moment";
import {PointRow} from "../PointRow";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {ComponentStatusTag, StatusTagType} from "../../../../../commons/ComponentStatusTag/ComponentStatusTag";


type TableDesktopViewProps = {
    historyPoints: any[],
    historyPointsCMS: CMSSection,
}
export const TableMobileView : React.FC<TableDesktopViewProps> = ({historyPoints, historyPointsCMS}) =>{
    const [collapseStatus, setCollapseStatus] = useState<Record<number, any>>({});

    const toggleCollapse = (index: number) => {
        const status = collapseStatus[index] || false;

        setCollapseStatus({
            ...collapseStatus,
            [index]: !status,
        });
    }

    return <>
        {
                historyPoints.length > 0 && historyPoints?.map((list: any, index: number)=>{
                    const initiative = list?.initiative;
                    return(
                            <Row key={index} className='mobileRowTable'>
                                <Col  xs={24} sm={24} className='headTable'>
                                    <Row>
                                        {initiative?.cms?.image && <Col xs={4} sm={3}>
                                            <img className='causalImage' src={generatePathUrl( initiative?.cms?.image)} aria-hidden={true}/>
                                        </Col>
                                        }
                                        <Col className='infoContainer' xs={12} sm={15}>
                                            <Row>
                                                {initiative?.cms?.title && <Col xs={24} sm={24}>
                                                    <label className='causalText'>{ initiative?.cms?.title}</label>
                                                </Col>}
                                                {list?.transactionDate &&  <Col xs={24} sm={24}>
                                                    <label className='dateTransactionText'>
                                                        {moment(list?.transactionDate)?.format(FORMAT_DATE)}
                                                    </label>
                                                </Col>}
                                                {initiative?.type === 'catalog' &&
                                                        <div
                                                                className={'goToProduct'}
                                                                onClick={()=>/*navigate(getNavPathFromType(NavigationTypes.CATALOG, col?.initiative?.sourceLogicId)*/ console.log('show Product')}>
                                                            {historyPointsCMS?.table?.goToProduct?.label}
                                                        </div>}
                                            </Row>
                                        </Col>
                                        {list?.amount && <Col xs={5} sm={4}>
                                            <PointRow amount={list?.amount} src={list?.cmsDto?.imageRef}/>
                                        </Col>}
                                        {list.statusExternalId && <Col xs={2} sm={2} className={'arrowCell'} onClick={() => toggleCollapse(index)}>
                                            {!collapseStatus[index] && <DownOutlined/>}
                                            {collapseStatus[index] && <UpOutlined/>}
                                        </Col>}
                                    </Row>
                                </Col>
                                {
                                        collapseStatus[index] && list.statusExternalId && <Col xs={24} sm={24}>
                                            <Row className={`pointDetails`} justify={'start'} align={"middle"}>
                                                <Col xs={24} sm={12}>
                                                    <label className='detailTitle'>{historyPointsCMS?.table?.point_date?.label}</label>
                                                    <ComponentStatusTag type={StatusTagType.TRANSACTION} orderStatus={list.statusExternalId}/>
                                                </Col>
                                                {/*{list?.dateValidatePoint && <Col xs={12} sm={8} offset={4}>
                                        <label className='detailTitle'>{historyPointsCMS?.table?.point_date?.label}</label>
                                        <label className='detailText'>
                                            {moment(list?.dateValidatePoint)?.format(FORMAT_DATE)}
                                        </label>
                                    </Col>}*/}
                                            </Row>
                                        </Col>
                                }
                            </Row>
                    )
                })
        }
    </>
}