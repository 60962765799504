import {CMSSection} from "@jnext/commons";
import React, {useEffect} from "react";
import ScoreCounterContest from "../../HeroCardContest/ScoreCounterContest";
import {useWalletStore} from "store/walletStore";
import {observer} from "mobx-react";

export const WalletSection = observer(({section, inNavbar, scrolled}: {
    section: CMSSection,
    inNavbar?: boolean,
    scrolled?: boolean
}) => {
    const {updateWallet, wallet} = useWalletStore();

    useEffect(() => {
        if (location.pathname !== "/" && !location.pathname.includes("catalog")) {
            updateWallet();
        }
    }, []);


    if (!section?.enabled || !wallet || wallet?.length === 0) return <React.Fragment/>;

    return <ScoreCounterContest walletPoints={wallet} inNavbar={inNavbar} scrolled={scrolled}/>;
});