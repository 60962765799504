import React, { FC, useMemo, useState } from "react"
import {
  breakpointsMin,
  CMSCTA,
  CMSSection,
  FORMAT_DATE,
  getNavPathFromType,
  JCMSCTA,
  JHTML,
  JSlider,
  NavigationTypes
} from "@jnext/commons"
import { JModalVas } from "../JModalVas"
import { AuthService } from "../../../../service/AuthService"
import "./cardVas.scss"
import {
  getBackgroundCard,
  myStyleBackground,
  myStyleStructure,
  mzUseNavigate, toFixedValue,
  useTranslationWord
} from "../../../../utils"
import { useCMSStore } from "../../../../store/cmsStore"
import { CartService } from "../../../../service/CartService"
import {
  BasketResponse,
  BasketTypeEnum,
  PlanStatusEnum,
  ProductPreviewDto
} from "@jnext/ts-axios-formatdigitalcollection"
import moment from "moment"
import { HighlightCard } from "../../../HighlightCard"
import { CardPositionContainer } from "../CardPositionContainer"
import { useWindowSize } from "@react-hook/window-size"

interface JCardVasProps {
  isMainCard: boolean
  card?: ProductPreviewDto;
  section?: CMSSection;
  isSubscriptionPurchased?: boolean;
}

// Definisce il componente JCardVas che accetta le proprietà card, section e isSubscriptionPurchased
export const JCardVas: FC<JCardVasProps> = ({
                                              isMainCard = false,
                                              card,
                                              section,
                                              isSubscriptionPurchased
                                            }) => {
  // Ottiene la funzione di navigazione
  const navigate = mzUseNavigate()
  // Ottiene la funzione di traduzione
  const translateWord = useTranslationWord()

  // Ottiene la struttura del CMS dal CMSStore
  const { structure } = useCMSStore()

  const template = useMemo(() => getBackgroundCard(structure, card?.logicId as string), [structure])

  const myBackground = useMemo(() => myStyleBackground(template), [template])
  const myStyle = useMemo(() => myStyleStructure(template), [template])

  // Stato per gestire la visibilità del Modal
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  // Usa useMemo per memorizzare se il piano è stato acquistato
  const isPurchased = useMemo(() => (card?.serviceDetails?.status === PlanStatusEnum.Purchased), [card?.serviceDetails])

  // Usa useMemo per memorizzare il prezzo del prodotto
  const price = useMemo(() => card?.redemptionModalities?.[0], [card?.redemptionModalities])

  // Usa useMemo per memorizzare lo stato di autenticazione
  const isAuth = useMemo(() => AuthService.authorized, [])

  // Trova il pulsante CTA per selezionare un abbonamento
  let selectSubscriptionCMS = section?.cta?.find((cta: CMSCTA) => cta?.scope === NavigationTypes.DATA_ENTRY_CHECKOUT)

  // Trova il pulsante CTA per gestire un abbonamento
  let schedulateSubscriptionCMS = section?.cta?.find((cta: CMSCTA) => cta?.scope === NavigationTypes.SUBSCRIPTION_DETAILS)

  // Trova il pulsante CTA per ulteriori informazioni
  const moreInfoBtn = section?.cta?.find((cta: CMSCTA) => cta?.scope === "MORE_INFO")

  // Trova i pulsanti CTA (Call To Action) specifici nella sezione per utenti non autenticati
  const registrationBtn = !AuthService.authorized && section?.cta?.find((cta: CMSCTA) => cta?.scope === NavigationTypes.REGISTRATION)

  const cardMetadata = useMemo(() => card?.cardMetadata, [card])

  const slideShow = useMemo(() => (cardMetadata?.length ?? 0) < 3 ? cardMetadata?.length ?? 0 : 2, [cardMetadata])

  // Ottiene la larghezza della finestra
  const [width] = useWindowSize()

  // Determina se il layout è mobile
  const isMobileLayout = useMemo(() => (
    width < breakpointsMin.xs
  ), [width])

  // Determina se il layout è tablet
  const isTabletLayout = useMemo(() => (
    width < breakpointsMin.md
  ), [width])

  // Funzione per aggiungere un prodotto, se la risposta contiene i parametri di acquisto, naviga alla pagina di checkout, altrimenti naviga alla pagina di selezione dell'abbonamento altrimenti direttamente al checkout
  async function addToBasket() {
    try {

      if (!card?.serviceDetails?.initiativeLogicId) {
        console.error("Missing required initiativeLogicId")
        return
      }

      const res: BasketResponse = await CartService.add(
        card.serviceDetails.initiativeLogicId,
        BasketTypeEnum.DirectPurchase,
        {
          productLogicId: card?.serviceDetails?.productLogicId,
          redemptionModalityLogicId: card?.redemptionModalities?.[0]?.logicId,
          quantity: 1
        }
      )

      if (!res?.items?.length) {
        console.error("Invalid response: missing items")
        return
      }

      const firstItem = res.items[0]
      const isPurchaseParameters = firstItem.purchaseParameters

      if (Array.isArray(isPurchaseParameters) && isPurchaseParameters.length > 0) {
        return navigate(getNavPathFromType(selectSubscriptionCMS?.scope as string, {
          initiativeLogicId: card?.serviceDetails?.initiativeLogicId,
          catalogGroupProductLogicId: firstItem.catalogGroupProductLogicId
        }), {
          state: {
            isTypeService: true
          }
        })
      }

      return navigate(getNavPathFromType(NavigationTypes.CHECKOUT as string, {
        initiativeLogicId: card?.serviceDetails?.initiativeLogicId,
        basketType: BasketTypeEnum.DirectPurchase
      }), {
        state: {
          isTypeService: true
        }
      })
    } catch (error) {
      console.error("Error adding to basket:", error)
    }
  }

  // Component to display the title section
  const TitleSection = ({ isRow = true, isMainCard = true, isFooter = false }: {
    isRow?: boolean,
    isMainCard?: boolean,
    isFooter?: boolean
  }) => {
    return (
      <div
        className={`title-container${isRow ? "-row" : "-col"} ${isMainCard || isAuth ? "main-title" : ""} ${isFooter ? "footer-title" : ""}`}>
        {/* Mostra il sottotitolo */}
        <JHTML.SubTitle styleSpan={myStyle} html={translateWord(`vas-card-title`)} />
        {/* Mostra il titolo del prodotto */}
        {card?.title && <JHTML.Title styleSpan={myStyle} html={card?.title} />}

      </div>
    )
  }

  // Component to display the button section
  const BtnSection = () => {
    switch (card?.serviceDetails?.status) {
      case PlanStatusEnum.Purchased:
        return (<>
          {selectSubscriptionCMS &&
            <JCMSCTA
              style={{ background: `${template?.backgroundCta}` }}
              {...selectSubscriptionCMS}
              disabled={isPurchased}
              title={`<span>
                            ${translateWord(`subscription_${card?.serviceDetails?.status}`)}</span>`
              }
              action={addToBasket} />
          }</>)
      case PlanStatusEnum.AvailableForPurchase:
        return (<>
          {selectSubscriptionCMS &&
            <JCMSCTA
              style={{ background: `${template?.backgroundCta}` }}
              {...selectSubscriptionCMS}
              disabled={isPurchased}
              title={`<span style="color: ${template?.colorCta}">
                            ${translateWord(`subscription_${card?.serviceDetails?.status}`)}</span>`
              }
              action={addToBasket} />
          }</>)
      case PlanStatusEnum.Scheduled:
        return (<>
          {schedulateSubscriptionCMS &&
            <JCMSCTA
              style={{ background: `${template?.backgroundCta}` }}
              {...schedulateSubscriptionCMS}
              title={`<span style="color: ${template?.colorCta}">
                            ${translateWord(`subscription_${card?.serviceDetails?.status}`, { date: moment(card?.serviceDetails?.activationDate)?.format(FORMAT_DATE) })}}
                            </span>`}
            />
          }</>)
      default:
        return (<></>)
    }
  }

  // Component to display the button to buy
  const BtnToBuy = () => {
    return (<>
      {/* Sezione contenente i pulsanti per selezionare/acquistare un piano se sono configurati da CMS per utenti autenticati */}
      {isAuth && <>
        {/* Mostra il pulsante per selezionare un piano se non è stato acquistato un abbonamento oppure per acquistare un piano se è stato acquistato almeno un abbonamento */}
        <div className={`${!isPurchased ? "vas-cta-purchase" : "vas-cta-purchased"}`}>
          {!isSubscriptionPurchased ?
            <>
              {selectSubscriptionCMS && <JCMSCTA
                {...selectSubscriptionCMS}
                style={{ background: `${template?.backgroundCta}` }}
                title={`<span style="color: ${template?.colorCta}">${selectSubscriptionCMS?.title}</span>`}
                action={addToBasket}
              />}
            </>
            :
            <BtnSection />
          }
        </div>
      </>}
    </>)
  }

  // Component to display the registration button
  const RegistrationBtn = () => {
    return (<>
      {registrationBtn &&
        <div className={`vas-registration-cta`}>
          <JCMSCTA {...registrationBtn}
                   style={{ background: `${template?.backgroundCta}` }}
                   title={`<span style="color: ${template?.colorCta}">${registrationBtn?.title}</span>`}
          />
        </div>
      }
    </>)
  }

  return (
    <div
      className={`vas-card${isAuth ? "_private" : `_public${isMainCard ? "_main-card" : ""}`}`}
      style={myBackground}>

      <div className={"vas-card-header"}>
        <div className={"left-side"}>
          <TitleSection isMainCard={isMainCard} />
          {/* Mostra la descrizione del prodotto */}
          <div className={`description-style ${!isMainCard && !isAuth ? "not-main-description" : ""}`}
          >
            {card?.description &&
              <JHTML.SubTitle styleSpan={{ color: `${template?.colorSecondary || template?.colorPrimary}` }}
                              html={card?.description} />}
          </div>

          {/* Mostra la descrizione del prodotto */}
          <div className={`long-description-style ${isMainCard ? "" : "not-main-long-description"}`}>
            {card?.longDescription &&
              <JHTML.Text styleSpan={{ color: `${template?.colorSecondary || template?.colorPrimary}` }}
                          html={card?.longDescription} />}
          </div>
        </div>

        <div className={"right-side"}>
          <div className={"side-container"}>
            {/* Mostra il contenitore del prezzo se disponibile */}
            {price?.sellPrice &&
              <div style={myStyle} className={"price"}>{"€" + toFixedValue(price?.sellPrice)}</div>}
            {price?.pricingModel?.cronDescription &&
              <div style={myStyle}
                   className={"cronDescription"}>{price?.pricingModel?.cronDescription}</div>}
            {/* Mostra il pulsante di acquisto se l'utente è autenticato */}
            {isAuth && <BtnToBuy />}
            {/* Mostra il pulsante di registrazione se presente e l'utente non è autenticato */}
            {!isAuth && <RegistrationBtn />}
          </div>
        </div>
      </div>

      <div className={"vas-card-body"}>
        {/* Mostra le carte VAS in un JSlider */}
        {isAuth && cardMetadata && <JSlider
          slidesToShow={isMobileLayout || isTabletLayout ? 1 : slideShow}
          arrows={false}
          dots={false}
          centerMode={isMobileLayout || isTabletLayout}
        >
          {cardMetadata?.map((card, index) => (
            <HighlightCard
              key={index}
              card={card}
              isMainCard={isMainCard}
              template={template}
            />
          ))}

        </JSlider>}

        {/*Rivedere logica di quando va mostrato e con cosa */}
        {!isAuth && cardMetadata && <CardPositionContainer
          cards={cardMetadata}
          isMainCard={isMainCard}
          template={template}
        />}

      </div>

      {!isAuth && <div className={`vas-card-footer ${!isMainCard ? "minor-title" : ""}`}>
        <JHTML.Title styleSpan={myStyle}
                     html={translateWord(`CARD_FOOTER_${card?.title?.toUpperCase()}`)} />
        <TitleSection isRow={false} isFooter={true} />
        <div className={"btn-container"}>
          {/* Mostra il pulsante per ulteriori informazioni */}
          {moreInfoBtn && <div className={"vas-card-more-info"}>
            <JCMSCTA {...moreInfoBtn} action={() => setIsModalVisible(true)} />
          </div>}
          {/* Mostra il pulsante di registrazione se presente e l'utente non è autenticato */}
          <RegistrationBtn />
        </div>
      </div>}

      {isModalVisible &&
        <JModalVas
          title={card?.title}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          infoProducts={cardMetadata} />}
    </div>
  )
}
