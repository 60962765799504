import React, {useMemo, useState} from "react";
import {PlanStatusEnum, ProductPreviewDto} from "@jnext/ts-axios-formatdigitalcollection";
import "./cardSubscription.scss";
import {
    CMSCTA,
    CMSSection,
    FORMAT_DATE,
    getNavPathFromType,
    JCMSCTA,
    JHTML,
    NavigationTypes
} from "@jnext/commons";
import {JModalVas} from "../JModalVas";
import {
    getBackgroundCard,
    myStyleBackground,
    myStyleStructure,
    mzUseNavigate,
    useTranslationWord
} from "../../../../utils";
import {useCMSStore} from "../../../../store/cmsStore";
import moment from "moment";

// Component to display subscription card
export const CardSubscription = ({product, section}: {
    product: ProductPreviewDto;
    section?: CMSSection;
}) =>{
    const navigate = mzUseNavigate();
    const translateWord = useTranslationWord();

    // Get CMS structure from the CMSStore
    const {structure} = useCMSStore();

    // Get background template for the card
    const template = useMemo(()=> getBackgroundCard(structure, product?.logicId as string),[structure, product?.logicId])

    const myBackground = useMemo(()=> myStyleBackground(template), [template])
    const myStyle = useMemo(()=> myStyleStructure(template), [template])

    // State to manage modal visibility
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    // State to store product information
    const [infoProducts, setInfoProducts] = useState<any[]>([])

    const options = section?.options;

    // Find the purchase button from the section's CTA
    let purchaseBtn = section?.cta?.find((cta: CMSCTA ) => ((cta?.scope === NavigationTypes.CATALOG_LOYALTY_COLLECTION
        || cta?.scope === NavigationTypes.CATALOG_DIGITAL_WALLET) && !cta?.options?.isPurchased));

    // Find the more info button from the section's CTA
    const moreInfoBtn = section?.cta?.find((cta: CMSCTA ) => (options?.showSubscriptionMoreInfo != false) && cta?.scope === 'MORE_INFO');

    // Find the manage button from the section's CTA
    const manageBtn = section?.cta?.find((cta: CMSCTA ) => cta?.scope === 'SUBSCRIPTION_DETAILS');

    // Get the status of the product
    const status = useMemo(() => product?.serviceDetails?.status, [product?.serviceDetails?.status]);

    return(<>
        <div  className={'card-subscrpition'} style={myBackground}>
            <div className="card-leftSide">

                <JHTML.Text styleSpan={myStyle} html={translateWord(`vas-card-title_upper`)} />

                {/* Display product title */}
                {product?.title && <JHTML.Title styleSpan={myStyle}  html={product?.title}/>}

                {/* Display product description */}
                {product?.description && <JHTML.SubTitle styleSpan={myStyle} html={product?.description}/>}

                {/* Display product longDescription */}
                {product?.longDescription && <JHTML.SubTitle styleSpan={myStyle} html={product?.longDescription}/>}

            </div>

            <div className="card-rightSide">
                {moreInfoBtn && <div className={'btn-more-info'}>
                    {/* Button to show more information */}
                    <JCMSCTA
                        {...moreInfoBtn}
                        action={() => {
                            if(product?.cardMetadata) {
                                setInfoProducts(product.cardMetadata)
                            }
                            setIsModalVisible(true)
                        }}/>
                </div>}

                {purchaseBtn && (status === PlanStatusEnum.AvailableForPurchase) &&
                    <div className={'btn-purchase'}>
                        {/* Button to purchase */}
                        <JCMSCTA
                            {...purchaseBtn}
                            style={{background:`${template?.backgroundCta}`}}
                            title={`<span style="color:${template?.colorCta}">${purchaseBtn.title}</span>`}
                            action={()=> {
                                sessionStorage.setItem('refClusterLogicId', product?.serviceDetails?.clusteredInitiatives?.[0]?.refClusterLogicId as string);
                                navigate(getNavPathFromType(purchaseBtn?.scope as NavigationTypes, {initiativeLogicId: purchaseBtn?.scopeParameters?.initiativeLogicId}));
                            }}/>
                    </div>}

                {manageBtn && (status === PlanStatusEnum.Purchased || status === PlanStatusEnum.Scheduled) &&
                    <div className={'btn-purchase'}>
                        {/* Button to manage subscription */}
                        <JCMSCTA
                            {...manageBtn}
                            title={manageBtn?.title || translateWord(`subscription_${product?.serviceDetails?.status}`,
                                {date: moment(product?.serviceDetails?.activationDate)?.format(FORMAT_DATE)})} />
                    </div>}

            </div>
        </div>

        {/* Show modal if isModalVisible is true */}
        {isModalVisible &&
            <JModalVas
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                infoProducts={infoProducts} />}

    </>)
}