import {decodeToken} from "react-jwt";
import React, {useEffect} from "react";
import "../commons/style.scss";
import StyleT1 from "../style/T1/variableT1";
import StyleT2 from "../style/T2/variableT2";
import "style/T1/";
import {MicroApp} from "qiankun";
import {useNavigate} from "react-router-dom";
import {
    CMSAppSettingConfiguration,
    CMSColorsConfiguration,
    CMSFontsConfiguration,
    CMSStructureConfig
} from "../store/cmsStore";
import i18n from "i18next";
import {TemplateType} from "../enums/TemplateType";
import variables from '../style/variable';
import {HttpService} from "../service/HttpService";
import {AuthService} from "../service/AuthService";
import {GetConsumerDto} from "@jnext/ts-axios-coreuser";
import {ClusterDto} from "@jnext/ts-axios-coreuser/models/cluster-dto";
import {Settings} from "react-slick";
import {
    CMSSection,
    CMSStaticSection,
    getNavPathFromType,
    imageBaseUrl,
    imageProviderBaseUrl,
    JCMSStaticSections,
    JFieldDTO, NavigationTypes, TemplateVas
} from "@jnext/commons";
import AdvertisementsCarousel from "../commons/Advertisements/AdvertisementsCarousel";
import DigitalWalletSlider from "../components/CatalogSlider/DigitalWalletSlider";
import LoyaltyCollectionSlider from "../components/CatalogSlider/LoyaltyCollectionSlider";
import HeroCard from "../components/HeroCard";
import Events from "../pages/Events";
import Contests from "../pages/Contests";
import HeroCardMobile from "../components/HeroCard/mobile";
import {ContestDetailResponse} from "@jnext/ts-axios-contestformat";
import {EventDetails, MgmInitiativeDto} from "@jnext/ts-axios-mz";
import {HowToSection} from "../commons";
import RewardAwardedSlider from "../components/RewardAwarded/RewardAwardedSlider";
import {JVasBoxSection} from "../components";
import {FieldDto} from "@jnext/ts-axios-formatdigitalcollection";

function decodeBody(value: string) {
    if (value) {
        let decToken: any = decodeToken(value);
        return decToken?.exp;

    }
}

function cleanObjStyle(obj: { [x: string]: any; }) {
    let newObj = {...obj};
    for (let propName in newObj) {
        if (!!newObj[propName] === null || newObj[propName] === undefined || newObj[propName] === '') {
            delete newObj[propName];
        }
    }
    return newObj;
}

function getCmsStyle(appSettings: CMSAppSettingConfiguration | undefined) {
    let style = {};
    let colors: CMSColorsConfiguration | undefined = appSettings?.template?.colors;
    let fonts: CMSFontsConfiguration | undefined = appSettings?.template?.fonts;
    if (!!colors || !!fonts) {
        style = {...colors, ...{'--font-family': fonts?.primary}} as React.CSSProperties;
    }

    return cleanObjStyle(style);
}

function printStyleInDom(css: string) {
    let head = document.head || document.getElementsByTagName('head')[0];
    let style: any = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }
}

export function setGlobalVars(appSettings: CMSAppSettingConfiguration | undefined) {

    const templateName = appSettings?.template?.code;
    const globalStyle = (() => {
        switch (templateName) {
            case TemplateType.TEMPLATE_T2:
                return {...StyleT2, ...variables, ...getCmsStyle(appSettings)};
            case TemplateType.TEMPLATE_T1:
            default:
                return {...StyleT1, ...variables, ...getCmsStyle(appSettings)};
        }
    })()

    for (const [name, value] of Object.entries(globalStyle)) {
        document.documentElement.style.setProperty(name, value as string);
    }

}


function getAppStyle(templateName: TemplateType) {
    switch (templateName) {
        case TemplateType.TEMPLATE_T1:
            return () => import("../style/T1/index");
        case TemplateType.TEMPLATE_T2:
            return () => import("../style/T2/index");
        default:
            return () => import("../style/T1/index");
    }
}

function getSplitPath() {
    return window.location.pathname?.split("/");
}

function getPathName() {
    if (window.location.pathname) {
        const nameSplit = getSplitPath();
        const pHomeIndex = nameSplit.findIndex((v) => v === "privateHome");
        let pathname = pHomeIndex
            ? nameSplit.slice(pHomeIndex + 1).join("/")
            : nameSplit[nameSplit?.length - 1];
        if (pathname === "") {
            pathname = "/";
        }
        return pathname;
    }
}

function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
}

function convertMsToHM(milliseconds: number) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = seconds >= 30 ? minutes + 1 : minutes;
    minutes = minutes % 60;
    hours = hours % 24;
    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

function getPointIcon(walletInfoBeats: any, walletLogicId?: string) {
    const walletCms = walletInfoBeats?.find(
        (beat: any) => beat?.walletCode === walletLogicId
    );
    return walletCms;
}

function isWalletEmpty(wallet: any): boolean {
    let total = 0;
    (wallet || []).forEach((points: any) => {
        total += points.walletPoints;
    });
    return total === 0;
}

/**
 * List of microapps
 */
let microAppInstances: MicroApp[] = [];

function addMicroAppInstance(instance: MicroApp) {
    microAppInstances.push(instance);
}

function getMicroAppInstances(): MicroApp[] {
    return microAppInstances;
}

/**
 * Destory microapp instances
 */
async function destroyMicroAppInstances() {
    try {
        for (let i = 0; i < microAppInstances.length; i++) {
            await microAppInstances[i].unmount();
        }
    } catch (e) {
        console.log("destroyMicroAppInstances Error: ", e);
    }

    // empty array
    microAppInstances = [];
}

/**
 * Destory microapp instances and navigate
 */
let lastRoute: string;

function mzUseNavigate() {
    const navigate = useNavigate();
    let lastRoute: string;

    return async (path: string, state?:any) => {
        // If lastRoute is empty -> set actual pathname
        if (!lastRoute) {
            lastRoute = window.location.pathname;
        }

        // if the new route is included in actual -> return
        if (lastRoute.endsWith(path)) {
            return;
        }

        // destroy all apps
        await destroyMicroAppInstances();

        // use react navigate
        navigate(path, state);

    };
}

// On URL change save locally new state
window.addEventListener('popstate', function (event) {
    lastRoute = window.location.pathname;
});

function removeMicroAppDuplicatedStyles() {
    // Styles from main app head
    const headStyles = document.querySelectorAll("head style");

    // Styles in qiankun head
    const qiankunStyles = document.querySelectorAll("qiankun-head style");

    headStyles.forEach((singleHeadStyle) => {
        const headStyleMinified = singleHeadStyle.innerHTML
            .replaceAll(" ", "")
            .replaceAll("\n", "");

        // Iterate all qiankun styles
        qiankunStyles.forEach((singleQiankunStyle) => {
            const qiankunStyleMinified = singleQiankunStyle.innerHTML
                .replaceAll(" ", "")
                .replaceAll("\n", "");

            // If same remove child style
            if (headStyleMinified.localeCompare(qiankunStyleMinified) === 0) {
                singleQiankunStyle.remove();
            }
        });
    });
}

function randomId() {
    return (
        "_" +
        Math.random()
            .toString(36)
            .slice(2, 9)
    );
}

function isObject(value: any) {
    return typeof value === 'object' && value !== null;
}

function isHttpError(e: any) {
    return isObject(e) && [500, 504].indexOf(e?.response?.status) !== -1;
}

function browserRedirect(path: string) {
    window.location.replace(path);
}

function showBody() {
    document.body.classList.add('show');
}

function getErrorMessage(error: any) {
    return error?.response?.data?.messageCode;
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function onClickOutside(ref: any, callback: () => any) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: { target: any; }) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


function setI18Resources(ln: string, res: any) {
    i18n.addResourceBundle(ln, 'translation', res);
}

/**
 * Get cluster from consumerDto
 * @param GetConsumerDto
 */
function getCluster(consumerInfo: GetConsumerDto | undefined) {
    const categoriesStandard: ClusterDto[] | undefined = consumerInfo?.categoriesStandard;
    const categoryProfile: ClusterDto | undefined = consumerInfo?.categoryProfile;

    if (!!categoriesStandard && categoriesStandard?.length > 0) {
        const standard = categoriesStandard.find((category: any) => category?.cmsCategoryConfig?.visible);
        if (!!standard) {
            return standard;
        }
    }
    if (categoryProfile && categoryProfile?.cmsCategoryConfig?.visible) {
        return categoryProfile;
    }
}

function subString(word: string, char: string) {
    if (char) {
        return word?.substring(word?.indexOf(char), word?.lastIndexOf(char)) || word;
    }
}

function generatePathUrl(name?: string) {
    return imageBaseUrl(HttpService.env?.pathImg, AuthService?.loginInfo, name);
}

function generateProviderPathUrl(name?: string) {
    return imageProviderBaseUrl(HttpService.env?.pathImg, AuthService?.loginInfo, name);
}

function catalogSlickConfig(settings: Settings, config?: any) {
    let sliderConfig = {...settings, ...config};
    //set responsive config
    sliderConfig?.responsive?.map((config: any) => {
        config.settings.slidesToScroll = parseInt((config?.settings?.slidesToShow).toFixed(0));
        return config;
    })
    return sliderConfig;
}

function getHeaderSectionDom(
    section?: CMSSection | undefined,
    inNavbar: boolean = false,
    mobileLayout: boolean = false,
    openHeroSection: boolean = false,
    heroRef?: React.RefObject<HTMLDivElement>
) {
    if (section?.enabled) {
        switch (section?.type) {
            case 'HERO_SECTION':
                return (
                    <>
                        {
                            mobileLayout ?
                                <>
                                    {openHeroSection &&
                                        <HeroCardMobile
                                            sections={section?.sections}
                                            heroRef={heroRef}/>}
                                </>
                                : <HeroCard sections={section?.sections} inNavbar={inNavbar}/>
                        }
                    </>
                );
        }
    }
}


function getHomeSectionDom(sections?: CMSSection[] | undefined, mzNavigate?: (path: string) => Promise<void>,) {
    return sections?.map((section: CMSSection, i: number) => {
        if (section?.enabled) {
            switch (section?.type) {
                /* case 'INTRO_BANNER': return (
                     <BannerPublic section={section} projectIntroChildren={<Login />} />
                 )*/
                case 'MAIN_SLIDER':
                    return (
                        <AdvertisementsCarousel key={i} section={section}/>
                    )
                case 'HERO_SECTION':
                    return (
                        <HeroCard key={i} sections={section?.sections}/>
                    )
                case 'EVENTS':
                    return (
                        <Events key={i} section={section} component="carousel" minHeight={8} loaderRows={4}/>
                    )
                case 'CONTESTS':
                    return (
                        <Contests key={i} section={section} component="carousel"/>
                    )
                case 'CATALOG_DIGITAL_WALLET':
                    return (
                        <DigitalWalletSlider key={i} section={section}/>
                    )
                case 'CATALOG_LOYALTY_COLLECTION':
                    return (
                        <LoyaltyCollectionSlider key={i} section={section}/>
                    )
                case 'STATIC_HOW_TO':
                    return (
                        <HowToSection key={i} cmsSection={section}
                                      onNavigate={(scope) => mzNavigate && mzNavigate(getNavPathFromType(scope))}/>
                    )
                case 'VAS':
                    return (
                        <JVasBoxSection key={i} section={section}/>
                    )
            }
        }
    });
}

function getMGMSectionDom(
    section?: CMSSection | undefined,
    initiatives?: MgmInitiativeDto,
    mzNavigate?: (path: string) => Promise<void>,
    key?: number) {
    if (section?.enabled) {
        switch (section?.type) {
            case 'STATIC_CONTENT':
                return (
                    <JCMSStaticSections.Content {...section as CMSStaticSection.Content} key={key}/>
                )
            case 'EVENTS':
                return (<>
                        {!!initiatives?.eventInitiatives && <Events
                            key={key}
                            section={section}
                            component="carousel"
                            minHeight={8}
                            loaderRows={4}
                            events={initiatives?.eventInitiatives as EventDetails[]}/>}
                    </>

                )
            case 'CONTESTS':
                return (<>
                        {initiatives?.contestInitiatives &&
                            initiatives?.contestInitiatives?.length > 0 && <Contests
                                key={key}
                                section={section}
                                component="carousel"
                                contests={initiatives?.contestInitiatives as ContestDetailResponse[]}/>
                        }
                    </>
                )
            case 'REWARDS':
                return (<>
                    {initiatives?.redemptions && initiatives?.redemptions?.length > 0 &&
                        <RewardAwardedSlider key={key} section={section} redemptions={initiatives?.redemptions}/>
                    }
                </>)

            case 'STATIC_HOW_TO': return (
                <HowToSection key={key} cmsSection={section} onNavigate={(scope)=>mzNavigate && mzNavigate(getNavPathFromType(scope))} />
            )
            case 'CATALOG_DIGITAL_WALLET': {
                return initiatives?.digitalWalletInitiatives?.map(walletInitiative => {
                    return (<DigitalWalletSlider key={key} section={section} initiativeLogicIdProps={walletInitiative?.initiativeLogicId} walletInitiative={walletInitiative?.products}/>)
                });
            }
            case 'CATALOG_LOYALTY_COLLECTION': {
                return initiatives?.loyaltyCollectionInitiatives?.map(loyaltyInitiative => {
                    return (<LoyaltyCollectionSlider key={key} section={section} initiativeLogicIdProps={loyaltyInitiative?.initiativeLogicId} loyaltyInitiative={loyaltyInitiative?.products}/>)
                });
            }
        }
    }
}

export function getPageSectionDom(sections?: CMSSection[] | undefined, mzNavigate?: (path: string) => Promise<void>,) {
    return sections?.map((section: CMSSection, i: number) => {
        if (section?.enabled) {
            switch (section?.type) {
                case 'STATIC_CONTENT':
                    return (
                            <JCMSStaticSections.Content {...section as CMSStaticSection.Content} key={i}/>
                    )
                case 'EVENTS':
                    return (
                            <Events key={i} section={section} component="carousel" minHeight={8} loaderRows={4}/>
                    )
                case 'CONTESTS':
                    return (
                            <Contests key={i} section={section} component="carousel"/>
                    )
                case 'CATALOG_DIGITAL_WALLET':
                    return (
                            <DigitalWalletSlider key={i} section={section}/>
                    )
                case 'CATALOG_LOYALTY_COLLECTION':
                    return (
                            <LoyaltyCollectionSlider key={i} section={section}/>
                    )
                case 'STATIC_HOW_TO':
                    return (
                            <HowToSection key={i} cmsSection={section}
                                          onNavigate={(scope) => mzNavigate && mzNavigate(getNavPathFromType(scope))}/>
                    )
                default:
                    break;
            }
        }
    });
}

function getParametersUrl() {
    const queryParameters = new URLSearchParams(window.location.search);
    const size = Array.from(queryParameters).length;
    if (size > 0) {
        localStorage.setItem('queryParameters', queryParameters?.toString());
    }
}

/**
 * return fields with values
 * @param JFieldDTO[]
 */
function populateFieldValues(fields: JFieldDTO[], initialValues?: Record<string, string>): FieldDto[]{
    const queryParameters = new URLSearchParams(initialValues ?? localStorage.getItem('queryParameters') ?? window.location.search);
    return fields.map((field: JFieldDTO) => {
        const value = queryParameters.get(field.hurlId);
        if (value) {
            field.values = [value]
        }
        return field;
    })
}

function getCorrectSection(cmsSections: CMSSection[] | undefined, sectionType: string) {
    return cmsSections?.find((section: CMSSection) => section.type === sectionType);
}

function getBackgroundCard(structure: CMSStructureConfig, clusterLogicId: string): TemplateVas {
    return  (structure as any)?.templateVas?.find((template: { clusterLogicId: any; }) => template.clusterLogicId === clusterLogicId)
}

function goToNoAuth() {
    if(window.location.pathname.includes(getNavPathFromType(NavigationTypes.NO_AUTH))) return;
    return browserRedirect(getNavPathFromType(NavigationTypes.NO_AUTH));
}

function myStyleBackground(template: any){
    return {background: template?.background}
}

function myStyleStructure(template: any, isColorSecondary: boolean = false){
    if(template?.backgroundImage){
        return {
            backgroundImage: template.backgroundImage,
            backgroundClip: `text`,
            WebkitBackgroundClip : `text`,
            MozBackgroundClip: `text`,
            WebkitTextFillColor: `transparent`,
            textFillColor: `transparent`,
            MozTextFillColor: `transparent`,
            color: `transparent`,
            display: `inline-block`,
        }
    }
    if(template?.colorSecondary && isColorSecondary){
        return {
           color: template?.colorSecondary
        }
    }
    return {color: template?.colorPrimary}
}

function toFixedValue(value: number | string, decimals?: number) {
    !decimals && (decimals = 2);
    return Number(value)?.toFixed(decimals)?.replace('.',',');
}


export {
    decodeBody,
    getPathName,
    convertMsToHM,
    getPointIcon,
    isWalletEmpty,
    addMicroAppInstance,
    getMicroAppInstances,
    mzUseNavigate,
    removeMicroAppDuplicatedStyles,
    randomId,
    isHttpError,
    browserRedirect,
    showBody,
    destroyMicroAppInstances,
    getErrorMessage,
    onClickOutside,
    setI18Resources,
    getAppStyle,
    printStyleInDom,
    getCluster,
    subString,
    generatePathUrl,
    generateProviderPathUrl,
    catalogSlickConfig,
    getHomeSectionDom,
    getParametersUrl,
    getHeaderSectionDom,
    getMGMSectionDom,
    populateFieldValues,
    getCorrectSection,
    getBackgroundCard,
    goToNoAuth,
    myStyleBackground,
    myStyleStructure,
    toFixedValue
};