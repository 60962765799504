import React, {Fragment} from "react";
import {JCMSCTA} from "@jnext/commons";
import {CheckoutSubMainSection, CheckoutSummarySection, CheckoutSummarySectionTypes} from "../../models";
import {generatePathUrl, useTranslationWord} from "../../../../utils";
import {BasketResponse} from "@jnext/ts-axios-formatdigitalcollection";
import {ProductRow} from "../DataSummary/ProductsBox";

interface CheckoutCompleteProps {
    cmsConfig: CheckoutSubMainSection;
    redemptionId: string;
    basket: BasketResponse;
    showDeliveryDaysInfo: boolean;
    genericDeliveryDays?: number
}

export const CheckoutComplete: React.FC<CheckoutCompleteProps> = ({cmsConfig, redemptionId, basket, showDeliveryDaysInfo= false , genericDeliveryDays}) => {
    const cms = (cmsConfig as CheckoutSummarySection);
    const mainSection = cms?.sections?.find((el: any) => el.type == CheckoutSummarySectionTypes.MAIN);
    const headImg = mainSection?.image?.[0]?.src;
    const orderCta = mainSection?.cta;
    const translateWord = useTranslationWord();

    return <div className={'checkout-complete-step'}>
        <div className={'checkout-content-container'}>
            <div className={'checkout-content'}>
                {
                    mainSection &&
                    <div className={'content-head'}>
                        {
                            headImg &&
                            <img className={'head-image'}
                                 src={generatePathUrl(headImg)} aria-hidden={true}/>
                        }
                        {mainSection?.title && <h1 className={'title'} dangerouslySetInnerHTML={{__html: mainSection.title}}/>}
                        {mainSection?.text && <div className={'description'} dangerouslySetInnerHTML={{__html: mainSection.text}}/>}
                    </div>
                }


                {
                    basket &&
                    <div className={'orders-container'}>
                        <div className={'single-order'} >
                            <div className={'list-container'}>
                                <ul>

                                    {
                                        basket?.shipments?.sort((a,b) => a?.productType?.localeCompare(b?.productType ?? '') || (a?.deliveryDays ?? 0) - ( b?.deliveryDays ?? 0 ))
                                            .map((items, index) => (
                                                <Fragment key={index}>
                                                    {
                                                        showDeliveryDaysInfo &&

                                                            <Fragment>
                                                                {
                                                                    (items?.productType ?? 'ITEM') === 'ITEM'
                                                                        ?
                                                                        <p className={'delivery_description'}>
                                                                            <strong>
                                                                                { items.deliveryDays ? translateWord('deliveryDaysCartConfirmed', { count: items.deliveryDays }) : translateWord('deliveryDaysCartConfirmedGeneric', { count: genericDeliveryDays } ) }
                                                                            </strong>
                                                                        </p>
                                                                        :
                                                                        <p className={'delivery_description'}>
                                                                            <strong>{ translateWord('deliveryDaysCartConfirmed_vouchers') }</strong>
                                                                        </p>
                                                                }

                                                            </Fragment>
                                                    }

                                                    {
                                                        items?.items?.map((product, productIndex) => (
                                                            <li key={productIndex}>
                                                                <ProductRow item={product}/>
                                                            </li>
                                                        ))
                                                    }
                                                </Fragment>
                                            ))
                                    }

                                </ul>
                            </div>

                            {
                                showDeliveryDaysInfo &&

                                    <div className={'disclaimer'}>
                                        <p>{translateWord('checkOutDisclaimer')}</p>
                                    </div>
                            }

                        </div>
                    </div>
                }

                {
                    orderCta && orderCta?.length > 0 &&
                    <div className={'order-cta'}>
                        {orderCta.map((cta, index) => (
                            <JCMSCTA {...cta}/>
                        ))}
                    </div>
                }
            </div>
        </div>
    </div>;
}


const AmountRender = ({amount, cashAmount}:{amount?: number, cashAmount?: number}) =>{
    if((amount && amount != 0) &&   (cashAmount && cashAmount != 0)){
        return (<div className={'product-value'}>
            {amount} + {cashAmount}
        </div>)
    }
    if((amount && amount != 0) && (!cashAmount || cashAmount === 0)){
        return (<div className={'product-value'}>
            {amount}
        </div>)
    }
    if((!amount || amount != 0) && (cashAmount || cashAmount != 0)){
        return (<div className={'product-value'}>
            {cashAmount}
        </div>)
    }
    return <></>
}


/*const ProductRow = ({product, index}: { product: Item | undefined, index: number }) => {
    const prod = product?.product;
    const thumbNailImage = getProductImage(prod);

    return <div key={index} className={'product-row'}>
        {
            thumbNailImage &&
            <img className={'img-container'}
                 src={generatePathUrl(thumbNailImage)}/>
        }
        <div className={'product-data'}>
            {prod?.brands?.[0]?.title && <div className={'product-brand'}>{prod?.brands?.[0]?.title}</div>}
            {prod?.title && <div
                className={'product-name'}>{(product?.quantity && (product?.quantity + ' X ')) + prod?.title}</div>}
        </div>
        <RewardItemValue
            amount={product?.amount}
            cashAmount={product?.cashAmount}
            walletId={product?.referredWalletConfigurationLogicId}
        />

        {/!*<AmountRender amount={product?.amount} cashAmount={product?.cashAmount}/>*!/}
    </div>;
}*/
