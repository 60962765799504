import {FC, Fragment, useEffect, useMemo} from "react";
import {ProductDetail} from "./ProductDetail";
import {ProductCMS, ProductContexts, ProductMainSectionTypes} from "./models";
import {useParams} from "react-router-dom";
import {useCMSStore} from "../../store/cmsStore";


export interface DigitalWalletProductProps {
    private: boolean
}

export const DigitalWalletProduct: FC<DigitalWalletProductProps> = props => {
    const {pages, setCurrentPage} = useCMSStore();
    const cmsConfig: ProductCMS = useMemo(() => pages?.productDetails as ProductCMS, [pages]);

    useEffect(() => {
        setCurrentPage(pages?.productDetails ?? undefined);
    }, [cmsConfig?.page]);

    const {initiativeLogicId} = useParams();

    const digitalWalletCms = useMemo(() => {

        // Search needed section
        const neededSection = cmsConfig?.section?.sections.find(el => el.type == `INITIATIVE_${initiativeLogicId}`);

        // Fallback section
        const fallBackSection = cmsConfig?.section?.sections.find(el => el.type == ProductMainSectionTypes.DIGITAL_WALLET)

        return neededSection ? neededSection : fallBackSection;

    }, [cmsConfig, initiativeLogicId]);

    return (
        <Fragment>
            {
                cmsConfig && digitalWalletCms && digitalWalletCms?.sections && (
                    <ProductDetail
                        context={ProductContexts.DIGITAL_WALLET}
                        productsCMS={cmsConfig}
                        productSections={digitalWalletCms?.sections}
                        private={props.private}
                        initiativeLogicId={initiativeLogicId!}
                    />
                )
            }
        </Fragment>
    )
}