import React from "react";
import {CMSCTA, CMSSection, FORMAT_DATE, getNavPathFromType, JCMSCTA, JModal, JTable} from "@jnext/commons";
import moment from "moment";
import {PointRow} from "../../PointRow";
import {OrderOrderStatusEnum} from "@jnext/ts-axios-mz/models/order";
import {ComponentStatusTag, StatusTagType} from "../../../../../../commons/ComponentStatusTag/ComponentStatusTag";
import {generatePathUrl, mzUseNavigate, useTranslationWord} from "../../../../../../utils";
import VasInvoice from "../../VasInvoice";
import {CtaTypeEnum} from "@jnext/ts-axios-mz";

interface columnsProps {
    cms: CMSSection;
    disableInvoiceDownloadEnableModal: boolean;
    handleModal: () => void;
}

const columns = ({
                     cms,
                     disableInvoiceDownloadEnableModal,
                     handleModal
                 }: columnsProps) => {
    return [
        {
            title: cms?.table?.causal?.label,
            dataIndex: 'initiative',
            render: (initiative: any) => (
                <div className="causalInfo">
                    {initiative?.cms?.image && (
                        <img
                            className="causalImage"
                            alt={"img"}
                            src={generatePathUrl(initiative?.cms?.image)}
                            aria-hidden={true}
                        />
                    )}
                    {initiative?.cms?.title && (
                        <label className="causalText">{initiative?.cms?.title}</label>
                    )}
                </div>
            ),
        },
        {
            title: cms?.table?.start_date?.label,
            dataIndex: 'transactionDate',
            render: (transactionDate: Date) => (
                <label className="dateTransactionText">{moment(transactionDate).format(FORMAT_DATE)}</label>
            ),
        },
        {
            title: cms?.table?.points?.label,
            dataIndex: 'amount',
            render: (amount: number, row: any) => (
                <PointRow amount={amount} currency={row?.currency} src={row?.cmsDto?.imageRef}/>
            ),
        },
        {
            title: cms?.table?.status?.label,
            dataIndex: 'statusExternalId',
            render: (status: OrderOrderStatusEnum) => (
                <ComponentStatusTag type={StatusTagType.PURCHASE_VAS} orderStatus={status}/>
            ),
        },
        {
            title: cms?.table?.invoice?.label,
            render: (transaction: any) => (
                <VasInvoice
                    transactionLogicId={transaction.logicId}
                    hasInvoice={transaction.hasInvoice}
                    disableInvoiceDownloadEnableModal={disableInvoiceDownloadEnableModal}
                    handleModal={handleModal}
                />
            ),
        },
    ];
};

type TableDesktopViewProps = {
    historyPoints: any[];
    historyPointsCMS: CMSSection;
};

export const VasTableDesktopView: React.FC<TableDesktopViewProps> = ({historyPoints, historyPointsCMS}) => {
    const navigate = mzUseNavigate();

    const disableInvoiceDownloadEnableModal = !!historyPointsCMS?.options?.disableInvoiceDownloadEnableModal ?? false;

    const [showModal, setShowModal] = React.useState<boolean>(false);
    const translateWord = useTranslationWord();

    const handleModal = () => {
        setShowModal(!showModal)
    }

    const modalCTA = historyPointsCMS?.cta?.find((cta: CMSCTA) => cta?.scope === "GO_TO_CONTACTS");

    return (
        <>
            {historyPoints.length > 0 && (
                <div className="profile-table">
                    <JTable
                        columns={columns({
                            cms: historyPointsCMS,
                            disableInvoiceDownloadEnableModal,
                            handleModal
                        })}
                        dataSource={historyPoints}
                        pagination={false}
                    />
                </div>
            )}
            <JModal
                width={520}
                title={translateWord("TITLE_INVOICE_MODAL")}
                children={<>{translateWord("BODY_INVOICE_MODAL")}</>}
                isModalVisible={showModal}
                handleCancel={() => setShowModal(false)}
                footer={<>
                    {modalCTA && <JCMSCTA {...modalCTA} type={CtaTypeEnum.Button}
                                          action={() => navigate(getNavPathFromType("CONTACTS"))}/>}
                </>}
            />
        </>
    );
};
