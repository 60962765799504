import {CMSSection, getNavPathFromType, NavigationTypes} from "@jnext/commons";
import {Item, RedemptionResponseStatusEnum} from "@jnext/ts-axios-mz";
import React from "react";
import {EventResponse} from "@jnext/ts-axios-eventformat";
import {ProductCard} from "./ProductCard";
import {EventExecutionHistoryDto} from "@jnext/ts-axios-eventformat/models/event-execution-history-dto";
import {RedemptionService} from "../../../../service/RedemptionService";
import {mzUseNavigate} from "../../../../utils";

interface ProductListProps {
    event: EventResponse
    eventExecution: EventExecutionHistoryDto | undefined
    bodyCms?: CMSSection
}

export const ProductList: React.FC<ProductListProps> = ({event, bodyCms, eventExecution}) => {
    const navigate = mzUseNavigate();

    const onClickCb = async (redemptionId: string) => {
        try {
            const res = await RedemptionService.retrieveRedemptionDetails(redemptionId);
            if (res) {
                if (res?.status === RedemptionResponseStatusEnum.WaitingForUser) {
                    return navigate(getNavPathFromType(NavigationTypes.REDEEM, { redemptionId }));
                } else if (res?.status === RedemptionResponseStatusEnum.Completed) {
                    return navigate(getNavPathFromType(NavigationTypes.HISTORY_REWARDS_CATALOG_DETAILS, { redemptionId }));
                }
            }
            return null;
        } catch (error: any) {
            if (error.response && error.response.status === 404) {
                console.error('Resource not found:', error.response.data);
            } else {
                console.error('An error occurred:', error);
            }
            return null;
        }
    };

    return (
        <ul className={'reward-list'}>
            {
                event?.rewards?.map((reward, i) => (
                    <li key={i}>
                        <div className={'products-groups'}>
                            <div className={'product-group'}>
                                <div className={'left-side'}>
                                    {/* PRODUCTS IN EVENT */}
                                    {reward.rewardItems?.map((item) => {
                                        let redemptionId: string | undefined;

                                        if (eventExecution) {
                                            eventExecution.assignedBehaviourList?.forEach(behaviour => {
                                                behaviour.reward?.rewardItems?.forEach(executionItem => {
                                                    if (executionItem.product?.logicId === item.product?.logicId) {
                                                        redemptionId = executionItem.redemptionLogicId;
                                                    }
                                                });
                                            });
                                        }

                                        return (
                                            <ProductCard 
                                                key={item.product?.logicId} 
                                                item={item} 
                                                bodyCms={bodyCms}
                                                onClick={async() => {
                                                    if(redemptionId){
                                                        await onClickCb(redemptionId);
                                                    }
                                                }}
                                            />
                                        );
                                    })}
                                    
                                    {/* WALLETS IN EVENT */}
                                    {reward.rewardWallets?.map((wallet, index) => {
                                        let walletId: string | undefined;
                                        
                                        if (eventExecution) {
                                            eventExecution.assignedBehaviourList?.forEach(behaviour => {
                                                behaviour.reward?.rewardWallets?.forEach(executionWallet => {
                                                    if (executionWallet.walletConfigurationLogicId === wallet.walletConfigurationLogicId) {
                                                        walletId = executionWallet.walletConfigurationLogicId;
                                                    }
                                                });
                                            });
                                        }
                                        
                                        return (
                                            <ProductCard 
                                                key={`wallet-${index}-${wallet.walletConfigurationLogicId}`}
                                                wallet={wallet}
                                                bodyCms={bodyCms}
                                                onClick={() => {
                                                    // Wallet non ha un redemptionId, quindi non possiamo navigare
                                                    console.log('Wallet clicked:', wallet);
                                                }}
                                            />
                                        );
                                    })}
                                    
                                    {/* NO REWARDS CASE */}
                                    {(!reward.rewardItems || reward.rewardItems.length === 0) && 
                                     (!reward.rewardWallets || reward.rewardWallets.length === 0) && (
                                        <div className="no-rewards">
                                            {bodyCms?.table?.noRewards?.label || 'No rewards available'}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </li>
                ))
            }
        </ul>
    );
};