import React, {Fragment} from 'react'
import { JScoreCounter } from '@jnext/commons';
import { WalletPoints } from "@jnext/ts-axios-wallet";
import { Divider } from 'antd';
import { observer } from "mobx-react";
import { SolutionProjectImage } from "../../commons";
import { SentencesComposeComponent } from "../../utils";

type Props = {
    align?: string,
    title?: string,
    walletPoints: WalletPoints[],
    inNavbar?: boolean,
    scrolled?:boolean
}

const ScoreCounter = ({ align = 'horizontal', walletPoints, inNavbar, scrolled}: Props) => {
    return (
        <>
            <div className='scoreCounterContainer'>
                <div className={`scoreCounterInnerContainer ${align}`}>
                    {
                        walletPoints?.map((point: WalletPoints, i: number) => {
                            return (
                                <Fragment key={i}>
                                    { inNavbar ?
                                        <div className={`fixedScoreCounterPointsContainer ${scrolled ? 'scrolled' : ''}`}>
                                            <div className={`fixedScoreCounterInnerPointsContainer`}>
                                                <SolutionProjectImage name={point.cmsConfig?.imageRef}/>
                                                <p className={`points`}>{point?.number}</p>
                                            </div>
                                            {point.cmsConfig?.title &&
                                                <SentencesComposeComponent className='point' text={point?.cmsConfig?.title}
                                                                           options={{count: point?.number}}/>
                                            }
                                        </div>
                                        :
                                        <div className={`scoreCounterPointsContainer`}>
                                            <JScoreCounter
                                                classNameContainer={`scoreCounterInnerPointsContainer`}
                                                classNamePoint='points'
                                                point={point?.number as number}
                                            >
                                                <SolutionProjectImage name={point?.cmsConfig?.imageRef}/>
                                            </JScoreCounter>
                                            {point?.cmsConfig?.title &&
                                                <SentencesComposeComponent className='point' text={point.cmsConfig?.title}
                                                                           options={{count: point.number}}/>
                                            }
                                        </div>}
                                    {walletPoints.length !== (i + 1) && <Divider type="vertical" />}
                                </Fragment>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default observer(ScoreCounter)