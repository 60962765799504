import React, {Fragment, useMemo} from "react";
import {CheckoutDataSection, CheckoutSubMainSection} from "../../models";
import {DataForm} from "./DataForm";
import {
    BasketResponse,
    BasketTypeEnum,
    CheckoutRequest,
    GetBasketResponseItem,
    ProductTypeEnum
} from "@jnext/ts-axios-formatdigitalcollection";
import {getProductImage} from "@jnext/commons";
import {generatePathUrl, useTranslationWord} from "../../../../utils";
import {ProductType} from "../../../../enums/ProductType";

interface BasketItemProps {
    item: GetBasketResponseItem;
}

const BasketItem: React.FC<BasketItemProps> = ({item}) => {
    const productImg = getProductImage(item?.cms);

    return <div className={'cart-product'}>
        {
            productImg &&
            <figure className={'img-container'}>
                <img src={generatePathUrl(productImg)} alt={""} aria-hidden={true}/>
            </figure>
        }
        <div className={`product-data ${!productImg ? 'not-border' : ''}`}>
            <div className={`left-side ${!productImg ? 'not-padding' : ''}`}>
                <div className={'brand-name'}>{item?.brands?.[0]?.title}</div>
                <div className={'product-name'}>{item?.cms?.title}</div>
            </div>
            <div className={'right-side'}>
            </div>
        </div>

    </div>;
}

interface DataFormProps {
    onComplete: (checkoutRequest: CheckoutRequest) => void;
    cmsConfig: CheckoutSubMainSection;
    initiativeLogicId: string;
    basketType: BasketTypeEnum;
    basket: BasketResponse;
    initialValues: CheckoutRequest | undefined;
    showDeliveryDaysInfo?: boolean;
    genericDeliveryDays?: number,
    options?: Record<string, string>;
}

export const DataFormContainer: React.FC<DataFormProps> = ({
                                                               onComplete,
                                                               cmsConfig,
                                                               initiativeLogicId,
                                                               basketType,
                                                               basket,
                                                               initialValues,
                                                               showDeliveryDaysInfo = false,
                                                               genericDeliveryDays,
                                                               options
                                                           }) => {

    const isDirectPurchase = useMemo(() => basketType == BasketTypeEnum.DirectPurchase, [basketType]);
    const translateWord = useTranslationWord();

    return (
        <>
            <div className={`data-form-step ${isDirectPurchase ? 'direct-purhcase' : ''}`}>

                {
                    // isDirectPurchase && basket?.items &&
                    isDirectPurchase && basket?.shipments?.[0]?.items &&
                    <div className={'cart-items'}>

                        {
                            showDeliveryDaysInfo &&

                                <Fragment>
                                    {
                                        (basket?.shipments?.[0]?.productType ?? 'ITEM') === ProductType.PHYSICAL
                                            &&
                                            <p className={'delivery_description'}>
                                                <strong>
                                                    { basket?.shipments?.[0]?.deliveryDays ? translateWord('deliveryDaysCartConfirm', { count: basket?.shipments?.[0]?.deliveryDays }) : translateWord('deliveryDaysCartConfirmGeneric' , { count: genericDeliveryDays }) }
                                                </strong>
                                            </p>
                                    }

                                    {
                                        (basket?.shipments?.[0]?.productType ?? 'VOUCHER') === ProductTypeEnum.Voucher
                                        &&
                                        <p className={'delivery_description'}>
                                                <strong>
                                                    { translateWord('deliveryDaysCartConfirm_vouchers') }
                                                </strong>
                                            </p>
                                    }
                                </Fragment>

                        }

                        <BasketItem item={basket?.shipments?.[0]?.items?.[0]}/>
                    </div>
                }


                <div className={'form-container'}>
                    <DataForm
                        cmsConfig={cmsConfig as CheckoutDataSection}
                        onComplete={onComplete}
                        initiativeLogicId={initiativeLogicId}
                        basket={basket}
                        initialValues={initialValues}
                        options={options}
                    />
                    <label className={'required_disclaimer'}>{translateWord('REQUIRED_FIELDS_DISCLAIMER')}</label>
                </div>
            </div>
        </>
    );
}