import {observer} from "mobx-react";
import React from "react";
import {JHTML} from "@jnext/commons";
import {InfoProgressSection, Position} from "../commons/InfoProgressSection";
import {MgmProgress} from "@jnext/ts-axios-mz/models/mgm-progress";

interface ProgressSectionProps {
    progressCms: Record<string, any>;
    progress: MgmProgress;
    invitedFriends: number;
    activeFriends: number;
    maxAssignable: number
    enableActiveFriendsCounter?: boolean
}

const ProgressSection = ({
                             progressCms,
                             progress,
                             invitedFriends,
                             activeFriends,
                             maxAssignable,
                             enableActiveFriendsCounter = false
                         }: ProgressSectionProps) => {
    const title = progressCms?.title;
    const titleColumnOne = progressCms?.columnOne;
    const titleColumnTwo = progressCms?.columnTwo;

    const getMaxRequiredFriends = (arr: MgmProgress) => {
        if (!Array.isArray(arr) || arr.length === 0) return 0;
        return arr.reduce((max, obj) => {
            return obj?.requiredFriends ?? 0 > max ? obj?.requiredFriends ?? 0 : max;
        }, -Infinity);
    }

    const maxRequiredFriends = getMaxRequiredFriends(progress);

    const limitedActiveFriends = Math.min(activeFriends, maxAssignable === maxRequiredFriends ? maxAssignable : maxRequiredFriends);
    const limitedInvitedFriends = Math.min(invitedFriends, maxAssignable === maxRequiredFriends ? maxAssignable : maxRequiredFriends);

    const numberOfInvitedFriends = progress?.findIndex((prog) => prog.requiredFriends === limitedInvitedFriends);
    const numberOfActiveFriends = progress?.findIndex((prog) => prog.requiredFriends === limitedActiveFriends);

    const dotPosition = 80 * ((enableActiveFriendsCounter ? numberOfActiveFriends : numberOfInvitedFriends) + 1) - 40;

    return (
        <div className={'progressContainer'}>
            {title && <div className={'titleProgressContainer'}>
                <JHTML.Title html={title}/>
            </div>}
            <div className={'titleContainer'}>
                {titleColumnOne && <JHTML.SubTitle html={titleColumnOne}/>}
                {titleColumnTwo && <JHTML.SubTitle html={titleColumnTwo}/>}
            </div>
            <div className={'graphContainer'}>
                <div className={'graphLeftContainer'}>
                    <div className={'section'}>
                        {progress?.map((option: any, key: number) => {
                            return (<InfoProgressSection key={key} position={Position.left} option={option}/>)
                        })}
                    </div>
                </div>
                <div id={'progress'} style={{height: `${(80 * progress?.length) - 10}px`}}>
                    {dotPosition > 0 && <div className={`progressStatus`} style={{height: `${dotPosition}px`}}/>}
                    <div className={'dotPointer'} style={{top: `${dotPosition > 0 ? (dotPosition - 10) : 0}px`}}/>
                </div>
                <div className={'graphRightContainer'}>
                    <div className={'section'}>
                        {progress?.map((option: any, key: number) => {
                            return (<InfoProgressSection key={key} option={option}/>)
                        })}
                    </div>
                </div>
            </div>
        </div>)
}

export default observer(ProgressSection);
