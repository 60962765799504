import {
    AnalyticsEventsEnum,
    CMSConfiguration,
    CompositorProvider,
    FormOutput,
    JCMSCTA,
    JFieldDTO,
    JFormCompositor,
    JHTML,
    jModalSuccess,
    JPage,
    JSection,
    RenderAfterFieldProps,
} from '@jnext/commons';
import {IssueTypeInfoDto} from '@jnext/ts-axios-coreconfiguration';
import {ComponentConfigOption} from '@jnext/ts-axios-coreuser/models';
import {Order} from '@jnext/ts-axios-mz';
import {Col, Form, FormInstance, Row, UploadFile} from 'antd';
import {FieldData} from 'rc-field-form/es/interface';
import {FC, Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {ContactService} from 'service/ContactService';
import {useAppStore} from 'store/appStore';
import {useCMSStore} from 'store/cmsStore';
import {useLoadingStore} from 'store/loadingStore';
import {trackingEventsMaps, useTranslationWord} from "../../utils";
import {contactFields, getOrderField} from './form';
import {Item} from "@jnext/ts-axios-mz/models/item";
import moment from "moment";

interface RenderOrderProps {
    items?: Item[]
    date?: string
}

const RenderOrder: FC<RenderOrderProps> = ({items, date}) => {
    if (!items || items.length === 0) return <></>;
    return (
        <div className='order-details'>
            <Row justify={'space-between'}>
                <Col>
                    <ul className={"contact-order-details"}>
                        {items.map((item, i) => (
                            <li key={i} className={"contact-order-details__item"}>
                                <span className={"contact-order-details__item__quantity"}>{item.quantity} x </span>
                                <span className={"contact-order-details__item__name"}>{item.product?.title ?? "-"}</span>
                                {item.product?.brands && item.product.brands[0] &&
                                    <span className={"contact-order-details__item__brand"}>({item.product.brands[0].title ?? "-"})</span>
                                }
                            </li>
                        ))
                        }
                    </ul>
                </Col>
                <Col>
                    {date}
                </Col>
            </Row>
        </div>
    )
}

export interface ContactFormDataOutput {
    name: string,
    lastname: string,
    email: string,
    reason: string,
    subargument: string,
    message: string,
    files: UploadFile[],
    order?: string,
}

const Contacts: FC = () => {
    const ref = useRef<any>()
    const {handleResponseError} = useAppStore()
    const {pages, setCurrentPage, appSettings} = useCMSStore();
    const translate = useTranslationWord()
    const config: CMSConfiguration | undefined = useMemo(() => pages?.contact, [pages]);

    const [formInstance, setFormInstance] = useState<FormInstance<any>>()
    const [fields, setFields] = useState<JFieldDTO[]>(contactFields(translate))
    const [orders, setOrders] = useState<Order[]>([])
    const [selectedOrder, setSelectedOrder] = useState<RenderOrderProps | undefined>()
    const [orderOptions, setOrderOptions] = useState<ComponentConfigOption[]>([])
    const [issuesList, setIssuesList] = useState<IssueTypeInfoDto[]>([])
    const cta = useMemo(() => config?.section?.cta?.[0], [config]);
    const options =  useMemo(() => config?.section?.options, [config]);
    const thankYouSection = useMemo(() => config?.section?.sections?.find(s => s.type === 'THANKYOU_MESSAGE'), [config]);

    const {setLoading} = useLoadingStore();

    useEffect(() => {
        setCurrentPage(pages?.contact ?? undefined);
    }, [config?.page]);

    if (!config) {
        return <Navigate to='/404'/>
    }
    useEffect(() => {
        (async () => {

            const issues = await ContactService.getIssueTypes()
            const orders = await ContactService.getOrders()
            const orderOptions = orders.map(ordersToOptions())

            if (orders.length === 0) {
                setIssuesList(issues?.filter((issue: any) => !issue?.rewardIssue))
            } else {
                setIssuesList(issues)
            }
            setOrders(orders)
            setOrderOptions(orderOptions)
            setFormInstance(ref.current)
        })();

    }, [])


    useEffect(() => {
        const reasonOptions: ComponentConfigOption[] = issuesList.map(issuesToOptions())
        setFieldOptions('reason', reasonOptions)
    }, [issuesList])

    function setFieldOptions(hurlId: string, options: ComponentConfigOption[]) {
        setFields(prev => {
            const field = prev.find(f => f.hurlId === hurlId)
            if (field) {
                if (!field.cmsConfig.componentConfig) field.cmsConfig.componentConfig = {options: []}
                field.cmsConfig.componentConfig.options = options
            }
            return [
                ...prev
            ]
        })
    }

    function addOrderField() {
        const orderField = fields.find(f => f.hurlId === 'order')
        if (!orderField) {
            const field = getOrderField(orderOptions, translate)
            const subargsField = fields.findIndex(f => f.hurlId === 'subargument')
            setFields(prev => {
                prev.splice(subargsField + 1, 0, field)
                return [...prev]
            })

        }
    }

    function removeOrderField() {
        let newFields = [...fields];
        const orderField = newFields.findIndex(f => f.hurlId === 'order');
        if (orderField !== -1) {
            newFields.splice(orderField, 1)
            return setFields(newFields)

        }
    }

    function onChangeReason(logicId: string) {
        const issue = issuesList.find(issue => issue.logicId === logicId)
        if (issue && issue.children) {
            const subargumentOptions = issue.children.map(issuesToOptions())
            setFieldOptions('subargument', subargumentOptions)
        }
        resetFields()
        setSelectedOrder(undefined)
        removeOrderField()
    }

    function resetFields(fieldsId: string[] = ['subargument', 'order']) {
        for (const hurlId of fieldsId) {
            formInstance?.resetFields([['data', hurlId]])
        }
    }

    async function onChangeSubargument(logicId: string, reasonLogicId?: string) {
        const reasonIssue = issuesList.find(issue => issue.logicId === reasonLogicId)
        setSelectedOrder(undefined)
        if (reasonIssue && reasonIssue.children?.length) {
            const issue = reasonIssue.children.find(issue => issue.logicId === logicId)
            if (issue && issue.rewardIssue) {
                resetFields(['order'])
                addOrderField()
                return;
            }
            removeOrderField();

        }
        resetFields(['order'])
    }

    const onChangeOrder = (logicId: string) => {
        if (logicId) {
            const order = orders.find(o => o.logicId === logicId)
            if (order) {
                const items: Item[] = []
                for (const orderItem of order.products) {
                    items.push(orderItem);
                }
                setSelectedOrder({
                    items,
                    date: order.date ? moment(order.date).format(appSettings?.formatDate || 'DD/MM/YYYY') : ''
                })
            }
        } else {
            setSelectedOrder(undefined)
        }
    }
    const onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]) => {
        const fieldChanged = changedFields[0]
        if (fieldChanged) {
            const name = fieldChanged.name as string[]
            const hurlId = name[name.length - 1]
            const value = fieldChanged.value
            switch (hurlId) {
                case 'reason':
                    onChangeReason(value)
                    break;

                case 'subargument':
                    const reason = allFields.find((f: any) => f.name[f.name.length - 1] === 'reason')
                    onChangeSubargument(value, reason?.value)
                    break;

                case 'order':
                    onChangeOrder(value)
                    break;
            }

        }
    }
    const onFinish = async (output: FormOutput) => {
        setLoading(true)

        try {

            const data: Record<string, unknown> = output.data

            const reason = issuesList.find(issue => issue.logicId === data.reason)
            const subargument = reason?.children?.find(issue => issue.logicId === data.subargument)
            const order = orderOptions?.find(order => order.value === data.order)

            const payload: ContactFormDataOutput = {
                name: data.name as string,
                lastname: data.lastname as string,
                email: data.email as string,
                message: data.message as string,
                reason: reason?.title || reason?.externalId || reason?.logicId || '-',
                subargument: subargument?.title || subargument?.externalId || subargument?.logicId || '-',
                order: order?.label.title,
                files: data.files as UploadFile[]
            }
            trackingEventsMaps(AnalyticsEventsEnum.CONTACT_FORM)
            await ContactService.postIssue(payload)

            if (thankYouSection && thankYouSection?.enabled !== false) {
                jModalSuccess(thankYouSection.title, thankYouSection.text, undefined, null);
            }

            setSelectedOrder(undefined)
            formInstance?.resetFields()
            removeOrderField()
        } catch (error) {
            handleResponseError(error)
        } finally {
            setLoading(false)
        }
    }

    const renderAfterField: RenderAfterFieldProps | undefined = selectedOrder ? {
        'order': {
            element: <RenderOrder {...selectedOrder} />
        }
    } : undefined
    return (
        <Fragment>
            {
                config.page && (
                    <JPage {...config.page} options={config.page?.options || {headerAlign: 'center'}}>
                        <JSection {...config.section}>
                            <div className='contact-form-container form-container'>
                                {
                                    !!fields.length && (
                                        <Form onFieldsChange={onFieldsChange} ref={ref} onFinish={onFinish}>
                                            <CompositorProvider>
                                                <JFormCompositor
                                                    renderAfterField={renderAfterField}
                                                    fields={fields}
                                                    formId='upload'
                                                    withoutFormComponent
                                                    overrideColsProps={{
                                                        'JFILE': {
                                                            span: 24
                                                        }
                                                    }}
                                                    providerProps={{
                                                        showFieldsLabel: true
                                                    }}
                                                />
                                            </CompositorProvider>
                                            {
                                                cta && (
                                                    <JCMSCTA {...cta} />
                                                )
                                            }
                                            {options?.['show_disclaimer_required_fields'] && (
                                                <div className='show_disclaimer_required_container'>
                                                    <JHTML.Text html={translate('REQUIRED_FIELDS_DISCLAIMER')}/>
                                                </div>
                                            )}
                                        </Form>
                                    )
                                }
                            </div>

                        </JSection>
                    </JPage>

                )
            }
        </Fragment>
    )
}

export default Contacts;

function issuesToOptions() {
    return (issue: IssueTypeInfoDto) => ({
        value: issue.logicId,
        label: {
            title: issue.title || issue.externalId || issue.logicId
        }
    });
}

function ordersToOptions() {
    return (order: Order) => ({
        value: order.logicId,
        label: {
            title: order.friendlyId
        }
    });
}
