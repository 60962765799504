import React, { useEffect, useMemo, useState } from "react";
import { CompositorProvider, JCMSCTA, JFieldDTO, JFormCompositor, JHTML } from "@jnext/commons";
import { Form } from "antd";
import { CheckoutDataInsertSectionTypes, CheckoutDataSection } from "../../models";
import { CheckoutService } from "../../../../service/CheckoutService";
import { BasketResponse, CheckoutRequest, ProductTypeEnum } from "@jnext/ts-axios-formatdigitalcollection";
import { useTranslationWord } from "../../../../utils";
import i18n from "i18next";

interface DataFormProps {
    onComplete: (checkoutRequest: CheckoutRequest) => void;
    cmsConfig: CheckoutDataSection;
    initiativeLogicId: string;
    basket: BasketResponse;
    initialValues: CheckoutRequest | undefined;
    options?: Record<string, string>;
}

// DataForm component definition
export const DataForm: React.FC<DataFormProps> = ({
                                                      cmsConfig,
                                                      onComplete,
                                                      basket,
                                                      initialValues,
                                                      options
                                                  }) => {

    // Extract the main section from the CMS configuration
    const mainSection = cmsConfig?.sections?.find((el: any) => el.type == CheckoutDataInsertSectionTypes.MAIN);
    // Extract the first CTA from the CMS configuration
    const cta = cmsConfig?.cta?.[0];
    // Determine if the basket contains physical items
    const isPhysical = useMemo(() => basket?.items?.find(el => el.productType == ProductTypeEnum.Item), [basket?.items]);
    // Determine if the basket contains service items
    const isService = useMemo(() => basket?.items?.find(el => el.productType == ProductTypeEnum.Service), [basket?.items]);
    // State to hold form fields
    const [formFields, setFormFields] = useState<JFieldDTO[]>([]);
    // Filter form fields based on whether the basket contains physical items
    const filteredFormFields = useMemo(() => formFields.slice(0, isPhysical ? 50 : 1), [isPhysical, formFields]);
    // Translation function
    const translate = useTranslationWord();

    // Effect to fetch and set form fields
    useEffect(() => {
        (async () => {
            const fields = await CheckoutService.getCheckoutForm();
            fields?.forEach(field => {
                field.readOnly = false;
                const fieldValue = (initialValues?.address as any)?.[field.hurlId];

                // Set initial values and read-only status for fields
                if (initialValues && fieldValue) {
                    field.values = [fieldValue];
                    if (isService || options?.readOnlyUserData) {
                        field.readOnly = true;
                    }
                }
            });

            // Translate field titles
            fields?.forEach(field => {
                field.cmsConfig.title = i18n.exists(field.cmsConfig.title)
                    ? translate(field.cmsConfig.title)
                    : field?.cmsConfig.fallBackTitle;
            });

            // Set options for country fields
            if (options && (options['showCountriesInRedemptionForm'] || options['hideCountriesInRedemptionForm'])) {
                fields.map((obj) => {
                    if (obj.type === 'JCOUNTRY') {
                        obj.cmsConfig.componentConfig.options = {
                            ...obj.cmsConfig.componentConfig.options,
                            ...options
                        };
                    }
                });
            }

            setFormFields(fields);
        })();
    }, []);

    return (
        <Form
            name="checkoutForm"
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={(values) => {
                // Handle form submission
                onComplete({
                    address: {
                        name: values.data?.name?.toString(),
                        surname: values.data?.surname?.toString(),
                        email: values.data?.email?.toString(),
                        street: values.data?.street?.toString(),
                        postalCode: values.data?.postalCode?.toString(),
                        country: values.data?.country?.toString(),
                        province: values.data?.province?.toString(),
                        note: values.data?.note?.toString(),
                        city: values.data?.city?.toString(),
                        phone: values.data?.phone?.toString(),
                    }
                });
            }}
        >
            {
                filteredFormFields &&
                <CompositorProvider>
                    <div className={'form-section-container'}>
                        <div className={'form-section'}>
                            <div className={'form-section-title'}>{mainSection?.title}</div>
                            <JFormCompositor
                                fields={filteredFormFields}
                                formId='mainDataForm'
                                withoutFormComponent
                                options={cmsConfig?.options}
                            />
                        </div>

                        {
                            cta &&
                            <div className={'cta-container'}>
                                <JCMSCTA {...cta} />
                            </div>
                        }

                        {
                            cmsConfig?.options?.['show_disclaimer_required_fields'] && (
                                <div className='show_disclaimer_required_container'>
                                    <JHTML.Text html={translate('REQUIRED_FIELDS_DISCLAIMER')} />
                                </div>
                            )
                        }

                    </div>
                </CompositorProvider>
            }
        </Form>
    );
}