// Import necessary modules and components
import { PlanStatusEnum, ProductPreviewDto } from "@jnext/ts-axios-formatdigitalcollection"
import { useEffect, useMemo, useState } from "react"
import { generatePathUrl, getBackgroundCard, mzUseNavigate, useTranslationWord } from "../../utils"
import { getNavPathFromType, JCMSCTA, NavigationTypes } from "@jnext/commons"
import { useCMSStore } from "../../store/cmsStore"

interface TabBtnProps {
  products: ProductPreviewDto[] | undefined
  onClickTab: (logicId: string) => void
  indexActiveTab: number;
  containerElement?: HTMLElement
}

// Component to display the "Buy" button for unlocked products
const BtnUnlocked = ({ products, indexChoose }: { products: ProductPreviewDto[] | undefined, indexChoose: number }) => {
  const navigate = mzUseNavigate()
  const translateWord = useTranslationWord()
  const status = products?.[indexChoose]?.serviceDetails?.status

  // Get the CMS structure from the CMSStore
  const { structure } = useCMSStore()

  // Get the background template for the card
  const template = useMemo(() => getBackgroundCard(structure, products?.[indexChoose].logicId as string), [structure])

  return (
    <>
      {/* Display the "Buy" button if the product is not purchased */}
      {(status !== PlanStatusEnum.Purchased) &&
        <div className={`toBuyContainer`}>
          <JCMSCTA
            disabled={status === PlanStatusEnum.Scheduled}
            style={{ background: `${template?.colorLinear}` }}
            type={"BUTTON"}
            color={"primary"}
            title={`${translateWord("buyNewPurchase") + " " + products?.[indexChoose]?.title}`}
            action={() => navigate(getNavPathFromType(NavigationTypes.VAS))}
          />
          <div className={"imgLocked"}>
            <img className={"locked"} src={generatePathUrl("locked.svg")} aria-hidden={true} />
          </div>
        </div>}
    </>
  )
}

// Define the TabBtn component that accepts the properties products, onClickTab, indexActiveTab, and containerElement
export function TabBtn({ products, onClickTab, indexActiveTab, containerElement }: TabBtnProps) {
  // Get the translation function
  const translateWord = useTranslationWord()

  // Get the CMS structure from the CMSStore
  const { structure } = useCMSStore()

  // State to store the index of the selected tab
  const [indexChoose, setIndexChoose] = useState<number>(0)

  // Update the selected tab index when indexActiveTab changes
  useEffect(() => {
    setIndexChoose(indexActiveTab)
  }, [indexActiveTab])

  return (
    <div className={"tabBtnContainer"}>
      <div className={"tabBtnSpace"}>
        {/* Map the products to create the tab buttons */}
        {products && products?.map((product, key) => {
          const background = getBackgroundCard(structure, product.logicId as string)
          if (containerElement && indexChoose === key) {
            containerElement.style.background = background?.backgroundLinear as string
          }
          // Check if the product is purchased
          return (
            <div key={product?.logicId}
                 className={`tabBtn ${indexChoose === key ? "active" : "noActive"}`}
                 style={{
                   background: `${background?.colorLinear}`
                 }}
                 onClick={() => {
                   // Set the selected tab index and call the onClickTab function
                   setIndexChoose(key)
                   sessionStorage.setItem("refClusterLogicId", product?.serviceDetails?.clusteredInitiatives?.[0]?.refClusterLogicId as string)
                   onClickTab(product?.logicId as string)
                 }}
            >
              <div className={"titleTabBtn"}>
                {/* Display the tab title with the appropriate translation */}
                {`${translateWord(product.serviceDetails?.status === PlanStatusEnum.Purchased ? "vas-card-title_upper" : "selectPurchasePlan")}`}
                <span>{product?.title}</span>
              </div>
            </div>
          )
        })}
      </div>
      <BtnUnlocked products={products} indexChoose={indexChoose} />
    </div>
  )
}