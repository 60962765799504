export const initializeCookieConsent = async (analytics: Record<string, any> | undefined, cookieBanner: Record<string, any> | undefined) => {

  const removeAnalytics = async (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {

        // Removing GTM
        let gtmFrame = document.getElementById("gtm-script")
        if (gtmFrame) gtmFrame.remove()

        // Removing GA
        let gaFrame = document.getElementById("ga-script")
        if (gaFrame) gaFrame.remove()
        let gaInitFrame = document.getElementById("ga-init-script")
        if (gaInitFrame) gaInitFrame.remove()

        // Removing Matomo
        let matomoFrame = document.getElementById("matomo-script")
        if (matomoFrame) matomoFrame.remove()

        resolve(true)
      } catch (e) {
        console.log("Error removing analytics:", e)
        reject(false)
      }
    })
  }

  const declineAnalytics = async (): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      try {

        const consent = window.Cookiebot?.consent

        if (!consent.statistics) {
          // Removing cookies

          // GTM consent update
          if (window.dataLayer) {
            window.dataLayer.push({
              event: "cookie_consent_update",
              cookie_consent: {
                statistics: false,
                analytics: false
              }
            })
          }

          if (typeof window.gtag === "function") {
            window.gtag("consent", "update", {
              "analytics_storage": "denied"
            })
          }

          if (window._paq) {
            window._paq.push(["forgetCookieConsentGiven"])
            window._paq.push(["optUserOut"])
          }

          await removeAnalytics()

        }

        resolve(true)

      } catch (e) {
        console.log("Error declining analytics:", e)
        reject(false)
      }
    })
  }

  const loadCookiebot = async (cookieBotScript: Record<string, any>): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {

        cookieBotScript?.initializationScript?.forEach(function(scriptConfig: {
          id: string;
          src: string;
          type: string;
          async: boolean;
          dataCbid: string;
          dataBlockingmode: string
        }) {
          // Check if the script with the given ID already exists
          if (!document.getElementById(scriptConfig.id)) {
            // Create a new script element
            const scriptElement = document.createElement("script")
            scriptElement.id = scriptConfig.id
            scriptElement.src = scriptConfig.src
            scriptElement.type = scriptConfig.type || "text/javascript"
            scriptElement.async = scriptConfig.async || false

            // Add additional attributes if present
            if (scriptConfig.dataCbid) {
              scriptElement.setAttribute("data-cbid", scriptConfig.dataCbid)
            }
            if (scriptConfig.dataBlockingmode) {
              scriptElement.setAttribute("data-blockingmode", scriptConfig.dataBlockingmode)
            }

            // Append the script to the head of the document

            const head = document.head
            head.insertBefore(scriptElement, head.firstChild)

          }
        })

        resolve(true)
      } catch (e) {
        console.log("Error loading Cookiebot:", e)
        reject(false)
      }
    })
  }

  const loadGTM = async (gtmScript: Record<string, any>): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {

        // Load the GTM script
        let script = document.createElement("script")
        script.id = "gtm-script"
        script.type = "text/javascript"
        script.setAttribute("data-cookieconsent", "statistics")
        script.innerHTML = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmScript?.initializationScript?.[0]?.id}');
        `
        script.async = true
        document.head.appendChild(script)
        resolve(true)
      } catch (e) {
        console.log("Error loading GTM:", e)
        reject(false)
      }
    })
  }

  const loadGA = async (gaScript: Record<string, any>): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {

        // Load the GA script
        let scriptGA = document.createElement("script")
        scriptGA.async = true
        scriptGA.id = "ga-script"
        scriptGA.type = "text/javascript"
        scriptGA.setAttribute("data-cookieconsent", "statistics")
        scriptGA.src = `https://www.googletagmanager.com/gtag/js?id=${gaScript?.initializationScript?.[0]?.id}`
        document.head.appendChild(scriptGA)

        // Initialize GA with the given ID
        let scriptGAInit = document.createElement("script")
        scriptGAInit.async = true
        scriptGAInit.id = "ga-init-script"
        scriptGAInit.type = "text/javascript"
        scriptGAInit.setAttribute("data-cookieconsent", "statistics")
        scriptGAInit.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gaScript?.initializationScript?.[0]?.id}');
        `
        document.head.appendChild(scriptGAInit)

        // GA initialization
        if (typeof window.gtag === "function") {
          window.gtag("consent", "update", {
            "analytics_storage": "granted"
          })
        }

        resolve(true)
      } catch (e) {
        console.log("Error loading GTM:", e)
        reject(false)
      }
    })
  }

  const loadMatomo = async (matomoScript: Record<string, any>): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        // Load the matomo script
        let scriptMatomo = document.createElement("script")
        scriptMatomo.type = "text/javascript"
        scriptMatomo.setAttribute("data-cookieconsent", "statistics")
        scriptMatomo.id = "matomo-script"

        scriptMatomo.innerHTML = `
          var _paq = window._paq = window._paq || [];
          _paq.push(['setCustomUrl', window.location.pathname]);
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          _paq.push(['rememberCookieConsentGiven']);
          _paq.push(['setConsentGiven']);
          (function() {
              var u='${matomoScript?.initializationScript?.[0]?.url}';
              _paq.push(['setTrackerUrl', u+'matomo.php']);
              _paq.push(['setSiteId', '${matomoScript?.initializationScript?.[0]?.siteId}']);
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.async=true; g.src='${matomoScript?.initializationScript?.[0]?.src}'; s.parentNode.insertBefore(g,s);
          })();
      `
        document.head.appendChild(scriptMatomo)
        resolve(true)
      } catch (e) {
        console.log("Error loading GTM:", e)
        reject(false)
      }
    })
  }

  const handleConsent = async (analytics: Record<string, any> | undefined): Promise<boolean> => {

    return new Promise(async (resolve, reject) => {
      try {

        const consent = window.Cookiebot?.consent

        // First, remove any previously loaded analytics to avoid duplication
        await removeAnalytics()

        // If statistics are allowed, load analytics tools
        if (consent?.statistics) {
          if (analytics?.gtm?.enabled || analytics?.google?.enabled) await loadGTM(analytics?.gtm)
          if (analytics?.ga?.enabled) await loadGA(analytics?.ga)
          if (analytics?.matomo?.enabled) await loadMatomo(analytics?.matomo)
        }

        // If marketing is allowed, load marketing tools (Example: Facebook Pixel)
        if (consent?.marketing) {
          // loadFacebookPixel();
        }

        resolve(true)
      } catch (e) {
        console.log("Error handling user consent:", e)
        reject(false)
      }
    })
  }

  if (cookieBanner?.cookiebot?.enabled) {

    try {
      await loadCookiebot(cookieBanner?.cookiebot)
      window.addEventListener("CookiebotOnAccept", async () => {
        await handleConsent(analytics)
      })
      window.addEventListener("CookiebotOnDecline", async () => {
        await declineAnalytics()
      }) // Handle full rejection
    } catch (e) {
      console.error("Error initializing analytics:", e)
    }
  }
}