import {
    AnalyticsActionsEnum,
    AnalyticsEnum,
    AnalyticsEventsEnum, CMSCTA,
    CustomAnalyticEventPayload,
    FormOutput,
    getNavPathFromType,
    JCMSCTA,
    JCMSCTAProps,
    NavigationTypes,
    RedemptionFormOutput,
    CMSHref
} from '@jnext/commons';
import {Col, Form, notification} from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useMemo, useState } from 'react';
import { AuthService } from 'service/AuthService';
import {
    ProductContexts,
    ProductFormSection,
    ProductFormSections,
    ProductFormSectionTypes as PCFormSectionTypes,
} from '../../../models';
import { ProductContentItem } from '../../ProductContent';
import { AttrbutesForm } from './AttributesForm';
import { FormCTA } from './FormCTA';
import './FormSection.scss';
import { QuantityInput } from './QuantityForm';
import { RedemptionModesForm } from './RedemptionModesForm';
import { CartService } from "../../../../../service/CartService";
import {BasketTypeEnum} from "@jnext/ts-axios-formatdigitalcollection";
import { useLoadingStore } from "../../../../../store/loadingStore";
import {mzUseNavigate, trackingEventsMaps, useTranslationControlWord} from "../../../../../utils";
import { useWalletStore } from "../../../../../store/walletStore";

export interface FormSectionProps {
    item: ProductContentItem,
    section: ProductFormSection,
    onChangeProduct: (productLogicId: string) => void,
    context: ProductContexts
    initiativeLogicId: string;
}

// Filter function to check if form sections are enabled
function filterFormSections(): (value: ProductFormSections) => boolean {
    return s => s?.enabled !== false;
}

// Function to get the minimum availability from given values
export function getMinAvailability(availibility?: number | null, obtainableQuantity?: number | null, availabilityThreshold?: number | null) {
    const arr: any[] = [availibility, obtainableQuantity, availabilityThreshold].filter(a => a !== undefined && a !== null);
    if (arr?.length === 0) {
        return null
    }
    return Math.min(...arr);
}

export const FormSection: FC<FormSectionProps> = observer((props) => {

    const {wallet} = useWalletStore();
    const {setLoading} = useLoadingStore();
    const navigate = mzUseNavigate();
    const translateWord = useTranslationControlWord();
    const [quantity, setQuantity] = useState<number>(1);
    const [canAddToCart, setCanAddToCart] = useState<boolean>(false);

    // Set availability props
    const maxProduct: number = 10;
    const cardActionItems = useMemo(() => props.item.cardActionItems, [props.item.cardActionItems]);

    const maxAvailabilitySelect = useMemo(() => getMinAvailability(props.item.availability, props.item.obtainableQuantity, maxProduct), [props])
    const maxAvailabilityDisplay = useMemo(() => getMinAvailability(props.item.availability, props.item.obtainableQuantity, props.item.availabilityThreshold), [props])

    // Filter and memoize form sections
    const formSections = useMemo(() => props.section.sections?.filter(filterFormSections()), [])

    // Check if purchase is enabled
    const purchaseEnabled = useMemo(() => {
        return !!(props.item.availability !== 0 && props.item.purchaseEnabled && AuthService.authorized)
    }, [props.item.purchaseEnabled, props.item.availability])

    //const purchaseDisabledReason = props?.item?.purchaseDisabledReason?.find(el => el.cause == 'NEED_PLAN');

    // Handle form value changes
    const onValuesChange = (changedFields: FormOutput) => {
        if (changedFields['data']['attributes']) {
            props.onChangeProduct(changedFields['data']['attributes'] as string)
        }
    }

    // Check if redemption modes form should be shown
    const showRedemptionModesForm = useMemo(() => (
        purchaseEnabled && props.item.redemptionModalities && props.item.redemptionModalities?.length > 0
    ), [purchaseEnabled])

    // Check if quantity form should be shown
    const showQuantityForm = useMemo(() => (
        purchaseEnabled && maxAvailabilitySelect !== 0 && props.context === ProductContexts.COLLECTION
    ), [purchaseEnabled])


    // Get CTA FORM title based on product type
    const getCtaFormTitle = (cta: CMSCTA) => {
        const translate = translateWord(`TITLE_PRODUCT_${props?.item?.productType?.toUpperCase()}`);
        if(translate) return translate;
        return cta.title

    }

    // Memoize form purchase CTA
    const formPurchaseCta = useMemo(() => {
        if (!!cardActionItems && cardActionItems?.length > 0) {
            const ctaCards: CMSCTA[] = []
            for (const cta of cardActionItems) {
                let ctaCard: CMSCTA = {
                    title: cta?.title ?  getCtaFormTitle(cta as unknown as CMSCTA) : translateWord('BUY_NEW_PLAN'),
                    scope: cta?.href?.value,
                    color: 'primary',
                    type: 'BUTTON',
                    href: cta?.href as CMSHref
                }
                ctaCards.push(ctaCard);
            }
            return ctaCards;
        }
        return null
    },[cardActionItems]);

    // Memoize form CTA
    const formCTA = useMemo(() => {
        const ctaList = props.section.cta;
        for (const cta of ctaList) {
            if (cta.scope === 'REDEEM') {
                cta.title = getCtaFormTitle(cta);
                (cta as JCMSCTAProps).action = async () => {

                    const commonAnalyticsPayload = {
                        event: AnalyticsEventsEnum.REDEEM_REWARD_CTA,
                        category: AnalyticsEventsEnum.REDEEM_REWARD_CTA,
                        action: AnalyticsActionsEnum.CTA,
                        name: "REDEEM_CTA",
                        value: props.item.title ?? props.item.shortDescription ?? props.item.longDescription ?? props.item.externalId ?? props.item.logicId ?? "No product info"
                    }

                    const analyticsEventPayload: CustomAnalyticEventPayload = [
                        {
                            analyticType: AnalyticsEnum.MATOMO,
                            ...commonAnalyticsPayload
                        },
                        {
                            analyticType: AnalyticsEnum.GOOGLE,
                            ...commonAnalyticsPayload
                        }
                    ];

                    trackingEventsMaps(AnalyticsEventsEnum.REDEEM_REWARD_CTA, analyticsEventPayload)
                    //setRedeemModal(true)
                    // Can add to cart
                    // On click add item to cart
                    if (await addToCart(BasketTypeEnum.DirectPurchase, props.item.redemptionModalities?.[0]?.logicId!, 1, canAddToCart)) {
                        // Redirect to checkout
                        await navigate(getNavPathFromType(NavigationTypes.CHECKOUT, {
                            initiativeLogicId: props.initiativeLogicId,
                            basketType: BasketTypeEnum.DirectPurchase
                        }));
                    }


                }
            }
        }
        return ctaList
    }, [])


    // Handle form submission
    const onFormSubmit = async (values: FormOutput<RedemptionFormOutput>) => {
        const canAddToCart = await canAddToCartFunc();
        setCanAddToCart(canAddToCart)
        if(values?.data){
            trackingEventsMaps(AnalyticsEventsEnum.ADD_TO_CART_CTA);
            if (await addToCart(BasketTypeEnum.BasketPurchase, values.data?.redemptionModality?.toString(), parseInt(quantity?.toString()), canAddToCart)) {

                notification.success({
                    message: translateWord('PRODUCT_ADDED_TO_CART_TITLE'),
                    description: translateWord('PRODUCT_ADDED_TO_CART'),
                    onClick: () => {
                    },
                });

            }
        }else {
            notification.error({
                message: translateWord('modalErrorTitle'),
                description: translateWord('modalErrorContent'),
                onClick: () => {
                },
            });
        }
    }

    // Get basket based on basket type
    const getBasket = async (basketType: BasketTypeEnum) => {

        try {
            return await CartService.getCart(props.initiativeLogicId, basketType);

        } catch (e) {
            // No basket exists
            return undefined
        }

    }

    // Check if item can be added to cart
    async function canAddToCartFunc() {
        let canAddToCart = false;
        let redemptionModality = props.item.redemptionModalities?.filter(el => wallet.find(w => w.logicId === el.walletConfigurationLogicId))[0];
        if (redemptionModality) {
            canAddToCart = CartService.canAddToCart(await getBasket(BasketTypeEnum.BasketPurchase), wallet, redemptionModality, quantity);
        }
        return canAddToCart
    }

    // Add item to cart
    const addToCart = async (basketType: BasketTypeEnum, redemptionModalityLogicId: string, quantity: number, canAddToCart: boolean): Promise<boolean> => {
        // Set loader
        setLoading(true);
        // sessionStorage.removeItem("basketType");
        try {
            // Get wallet for redemption
            const redemptionModality = props.item.redemptionModalities?.find(el => el.logicId == redemptionModalityLogicId);

            if (!redemptionModality && basketType == BasketTypeEnum.BasketPurchase) {
                notification.error({
                    message: translateWord('PRODUCT_ADD_TO_CART_ERROR_TITLE'),
                    description: translateWord('PRODUCT_ADD_TO_CART_ERROR'),
                    onClick: () => {
                    },
                });

                throw new Error('Wallet logic id check error');
            }

            if (basketType == BasketTypeEnum.BasketPurchase && !!redemptionModality && !canAddToCart) {
                notification.error({
                    message: translateWord('PRODUCT_NOT_ENOUGH_CREDIT_TITLE'),
                    description: translateWord('PRODUCT_NOT_ENOUGH_CREDIT'),
                    onClick: () => {
                    },
                });

                throw new Error('Insufficient balance');
            }

      // if (props.item?.purchaseParameters && props.item.purchaseParameters.length > 0) {
      //   console.log("qui")
      //   sessionStorage.setItem("basketType", basketType);
      //   return await navigate(getNavPathFromType(NavigationTypes.DATA_ENTRY_CHECKOUT as string, {
      //       initiativeLogicId: props.initiativeLogicId,
      //       catalogGroupProductLogicId: props.item?.catalogProductLogicId
      //   })), {
      //     state: {
      //       basketType: basketType
      //     }
      //   } as unknown as boolean
      //
      // } else {
      //   await CartService.add(props.initiativeLogicId, basketType, {
      //     productLogicId: props.item.logicId,
      //     redemptionModalityLogicId,
      //     quantity
      //   })
      // }
            await CartService.add(props.initiativeLogicId, basketType, {
                productLogicId: props.item.logicId,
                redemptionModalityLogicId,
                quantity
            })

      setLoading(false)


        } catch (e) {
            setLoading(false);
            if (e instanceof Error && (e.message === 'Wallet logic id check error' || e.message === 'Insufficient balance')) {
            } else {
                notification.error({
                    message: translateWord('PRODUCT_ADD_TO_CART_ERROR_TITLE'),
                    description: translateWord('PRODUCT_ADD_TO_CART_ERROR'),
                    onClick: () => {},
                });
            }
            return false;
        }

        return true;
    }

    return (
        <Form className='product-form' onValuesChange={onValuesChange} onFinish={onFormSubmit}>
            { (!cardActionItems || cardActionItems.length === 0) &&
                formSections?.map((section, i) => {
                    let element: JSX.Element = <span></span>
                    switch (section.type) {
                        case PCFormSectionTypes.PRODUCT_FORM_REDEMPTIONS:
                            if (showRedemptionModesForm) {
                                element = (
                                    <RedemptionModesForm
                                        redemptionModalities={props.item.redemptionModalities || []}
                                        section={section}
                                        currencyCode={props.item.catalog?.currency}
                                        key={i}
                                    />
                                )
                            }
                            break;
                        case PCFormSectionTypes.PRODUCT_FORM_ATTRIBUTES:
                            element = (
                                <AttrbutesForm attributes={props.item.attributes || []}
                                               relatedProducts={props.item.relatedProducts || []} key={i} />
                            )
                            break;
                        case PCFormSectionTypes.PRODUCT_FORM_QUANTITY:
                            if (showQuantityForm) {
                                element = (<>
                                        {/* <QuantityForm section={section} availability={props.item.availability} key={i}/>*/}
                                        <QuantityInput
                                            quantity={quantity}
                                            setQuantity={setQuantity}
                                            section={section}
                                            maxAvailabilitySelect={maxAvailabilitySelect}
                                            maxAvailabilityDisplay={maxAvailabilityDisplay}
                                            key={i} />
                                    </>
                                )
                            }
                            break;
                        default:
                            break;
                    }
                    return (
                        <Col span={24} key={i}>
                            {element}
                        </Col>
                    )
                })
            }

            {!formPurchaseCta && formCTA && <FormCTA
                cta={formCTA}
                quantity={quantity}
                maxAvailabilitySelect={maxAvailabilitySelect}
                purchaseEnabled={purchaseEnabled}
                productType={props.item.productType}/> }

            {formPurchaseCta && formPurchaseCta?.map((cta, index) => {
                return(<JCMSCTA  {...cta} key={index} />)
            })}
        </Form>
    )
})