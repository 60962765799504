import React from 'react';
import {generatePathUrl} from "../../utils";


export const SolutionProjectImage = ({style, className, name}:{style?: React.CSSProperties, className?: string, name?: string}) => {
    const path = generatePathUrl(name);

    return(
        <div className='solution-project-image'>
            <img style={style} className={className} src={path} aria-hidden={true} alt={''}/>
        </div>
    )
}