import { observer } from 'mobx-react';
import MicroApp from '../../../../commons/MicroApp';
import { HttpService } from "../../../../service/HttpService";
import { useAppStore } from "../../../../store/appStore";
import { useConsumerStore } from 'store/consumerStore';
import {GetConsumerDto} from "@jnext/ts-axios-coreuser";
import {mzUseNavigate} from "../../../../utils";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

const ProfileInfo = () => {
    const { handleResponseError } = useAppStore();
    const { updateProfile } = useConsumerStore();
    const customProject = HttpService?.customProject;
    const { consumerInfo } = useConsumerStore();

    const navigate = mzUseNavigate();
    const params = useParams();

    return (
        <>
            {
                HttpService.env?.coreUser && (
                    <MicroApp
                        entry={`${HttpService.env?.coreUser}`}
                        props={{
                            type: "profile-info",
                            customProject: customProject,
                            JNextNavigate: navigate,
                            useParams: params,
                            userProfile: consumerInfo,
                            keyCloakClientId: HttpService.env?.keycloakClientID as string,
                            onError: handleResponseError,
                            onSuccess: (consumer: GetConsumerDto) => {
                                updateProfile();
                            }
                        }}
                    />)
            }
        </>
    );
};

export default observer(ProfileInfo);
