import React, { FC, useEffect, useMemo, useState } from "react"
import {
  CMSSection,
  JSection,
  JSkeletonSlider
} from "@jnext/commons"
import { VasService } from "../../service/VasService"
import { PlanStatusEnum, ProductPreviewDto } from "@jnext/ts-axios-formatdigitalcollection"
import { JCardSubscriptionPurchased } from "./Components/JCardSubscriptionPurchased"
import { JCardSubscription } from "./Components/JCardSubscription"
import "./vas.scss"
import { JBoxInfoWarning } from "./Components/JBoxInfoWarning"
import { useTranslationWord } from "../../utils"
import { useVasStore } from "../../store/VasStore"

interface JVasBoxSectionProps {
  section?: CMSSection;
}

// Component to display VAS box section
export const JVasBoxSection: FC<JVasBoxSectionProps> = ({ section }) => {

  const translateWord = useTranslationWord()

  // Use useMemo to memoize section options
  const { setServicePurchased, removeServicePurchased } = useVasStore()

  // Usa useMemo per memorizzare le opzioni della sezione
  const options = useMemo(() => section?.options, [section])

  // State to store subscriptions
  const [subscriptions, setSubScriptions] = useState<ProductPreviewDto[]>()

  // State to store products
  const [products, setProducts] = useState<ProductPreviewDto[]>([])

  // State to check if there are scheduled subscriptions
  const [isScheduled, setIsScheduled] = useState<boolean>(false)

  // Effect to fetch VAS products based on logicId
  useEffect(() => {
    (async () => {
      if (!!options?.logicId) {
        // Fetch VAS products using the logicId from options
        const data = await VasService.getVasProducts(options.logicId as string)
        if (data) {

          // Rimuove il salvataggio in sessione se l'abbonamento è stato acquistato
          removeServicePurchased()

          // Filter scheduled and purchased subscriptions
          const scheduled = data.filter((product: ProductPreviewDto) => (product?.serviceDetails?.status === PlanStatusEnum.Scheduled))
          const subscription = data.filter((product: ProductPreviewDto) => (product?.serviceDetails?.status === PlanStatusEnum.Purchased))

          // Check if there are scheduled subscriptions
          setIsScheduled(!!scheduled.length!)

          setSubScriptions([...subscription, ...scheduled])

          //Salva in sessione se l'abbonamento è stato acquistato
          setServicePurchased(!!subscription)

          // Imposta i prodotti nello stato
          setProducts(data.filter((product: ProductPreviewDto) => product?.serviceDetails?.status === PlanStatusEnum.AvailableForPurchase))
        }
      }
    })()
  }, [options?.logicId])

  return (
    <JSection {...section}>
      {/* Show skeleton loader if there are no products */}
      {!products && <JSkeletonSlider hasTitle={true} />}

      <div className={"subscription-container"}>
        {/* Show warning message if there are scheduled subscriptions */}
        {isScheduled && <JBoxInfoWarning
          isScheduled={isScheduled}
          text={translateWord("scheduledInfoMessage")}
        />}

        {/* Show purchased subscriptions */}
        {subscriptions && subscriptions.length > 0 &&
          subscriptions?.sort((a, b) => {
            if (a?.serviceDetails?.status && b?.serviceDetails?.status) {
              return a?.serviceDetails?.status > b?.serviceDetails?.status ? -1 : 1
            }
            return 1
          })
            ?.map((subscription, key) =>
              <JCardSubscriptionPurchased key={key} product={subscription} section={section} />
            )}

        {/* Show available subscription plans */}
        {products && products.length > 0 && <JCardSubscription products={products} section={section} />}
      </div>
    </JSection>
  )
}
