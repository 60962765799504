import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import { breakpointsMin, CMSSection, JCMSCTA, JModal, jModalError } from "@jnext/commons"
import moment from "moment/moment"
import { FORMAT_DATE, generatePathUrl, useTranslationWord } from "../../../../utils"
import { useWindowSize } from "@react-hook/window-size"
import { UserSubscriptionChangeDto, UserSubscriptionDetailDto } from "@jnext/ts-axios-formatdigitalcollection"
import { ModalType } from "../../../../pages/Subscription/SubscriptionDetails"
import { VasService } from "../../../../service/VasService"

interface SubscriptionDetailProps {
    onClick: (type: ModalType) => void;
    cmsSection: CMSSection | undefined;
    initiativeLogicId?: string
    subscriptionDetail?: UserSubscriptionDetailDto;
    scheduledChange?: UserSubscriptionChangeDto;
    scheduledDateChange?: string;
    setRefreshService?: Dispatch<SetStateAction<boolean>>;
}

// Definisce il componente SubscriptionDetail che accetta le proprietà subscriptionDetail, scheduledChange, onClick e cmsSection
const SubscriptionDetail = ({
                                onClick,
                                cmsSection,
                                initiativeLogicId,
                                subscriptionDetail,
                                scheduledChange,
                                scheduledDateChange,
                                setRefreshService
                            }: SubscriptionDetailProps) => {
    // Ottiene la funzione di traduzione
    const translateWord = useTranslationWord()
    const [openWithdrawalModal, setOpenWithdrawalModal] = useState<boolean>(false)

    const [width] = useWindowSize()
    const isUnderLg = useMemo<boolean>(() => width < breakpointsMin?.lg, [width])

    // Ottiene il percorso dell'immagine dal CMS
    const imagePath = cmsSection?.image?.[0]?.src

    // Ottiene le CTA (Call To Action) dal CMS
    const ctaCms = cmsSection?.cta
    const goToVas = ctaCms?.find(cta => cta.scope === "VAS")
    const submitCta = ctaCms?.find(cta => cta.scope === "MANAGE_VAS")
    const submitAnnulledCta = ctaCms?.find(cta => cta.scope === "CANCEL_VAS")
    const annulledCta = ctaCms?.find(cta => cta.scope === "CANCEL")
    const withdrawalLink = ctaCms?.find(cta => cta.scope === "WITHDRAWAL")

    // Configurazione Termination
    const termination = useMemo(() => subscriptionDetail?.termination, [subscriptionDetail])


    // Ottiene il titolo del prodotto dall'abbonamento o dal cambio programmato
    const title = subscriptionDetail?.product?.title || scheduledChange?.product?.title
    // Ottiene le modalità di riscatto del prodotto dall'abbonamento o dal cambio programmato
    const redemptionModalities = subscriptionDetail?.product?.redemptionModalities?.[0] || scheduledChange?.product?.redemptionModalities?.[0]

    //Restituisce true se la data programmata è antecedente alla data della nuova attivazione
    const showNewPayment = useMemo(() => {
        if (scheduledDateChange) {
            return moment(subscriptionDetail?.nextCharge) < moment(scheduledDateChange)
        }
        return true
    }, [subscriptionDetail?.nextCharge, scheduledDateChange])

    //funzione che recede il contratto
    async function withdrawalSubscription() {
        try {
            if (initiativeLogicId && subscriptionDetail?.logicId) {
                const data = await VasService.terminateSubscription(initiativeLogicId, subscriptionDetail.logicId)
                if ((data === 200) && setRefreshService) {
                    setRefreshService(true)
                } else {
                    jModalError(translateWord("modalErrorTitle"), translateWord("modalErrorContent"))
                }
            }
        } catch (e: any) {
            console.log("Error fetching withdrawalSubscription:", e)
        } finally {
            setOpenWithdrawalModal(false)
        }

    }

    return (
            <>
                <div className="details-wrapper">
                    <div className="details-header">
                        <div className="icon-text-container">
                            <div className="icon icon-bg">
                                {/* Mostra l'immagine se disponibile */}
                                {imagePath ? (
                                        <img src={generatePathUrl(imagePath)} aria-hidden={true} alt="icon" />
                                ) : null}
                            </div>
                            <div className="text-container">
                                <p className="subscription-label">{translateWord("SUBSCRIPTION_LABEL_TEXT")}</p>
                                {/* Mostra il titolo del piano di abbonamento */}
                                {title && <p className="subscription-plan">{translateWord("vas-card-title")} {title}</p>}
                                <div className="next-payment">
                                    {/* Mostra la data del prossimo pagamento */}
                                    {showNewPayment && subscriptionDetail?.nextCharge && <>
                                    <span className={"translate_label"}>{translateWord("NEXT_PAYMENT",
                                            { date: moment(subscriptionDetail?.nextCharge)?.format(FORMAT_DATE) })}
                                    </span>
                                    </>}
                                    {/* Mostra la data di attivazione del prossimo abbonamento */}
                                    {scheduledChange?.activationDate && <>
                                    <span>
                                        {translateWord("NEXT_ACTIVATION", { date: moment(scheduledChange?.activationDate)?.format(FORMAT_DATE) })}
                                    </span>
                                    </>}
                                </div>
                                {isUnderLg &&
                                        <div className="price-container">
                                            <span>€</span>
                                            {redemptionModalities?.sellPrice && <span>{redemptionModalities?.sellPrice}</span>}
                                            {redemptionModalities?.pricingModel?.cronDescription &&
                                                    <span
                                                            className={"cronDescription"}>{redemptionModalities?.pricingModel?.cronDescription.toLowerCase()}</span>}
                                        </div>}
                            </div>
                        </div>
                        {!isUnderLg && redemptionModalities?.sellPrice &&
                                <div className="price-container">
                                    <span className={"price"}>€</span>
                                    <span className={"price"}>{redemptionModalities?.sellPrice}</span>
                                    {redemptionModalities?.pricingModel?.cronDescription &&
                                            <span
                                                    className={"price cronDescription"}>{redemptionModalities?.pricingModel?.cronDescription.toLowerCase()}</span>}
                                </div>}
                    </div>
                    {/* Mostra il link di recesso*/}
                    {withdrawalLink && termination?.available &&
                            <div className={"withdrawal-container"}>
                                <JCMSCTA
                                        {...withdrawalLink}
                                        title={
                                            `<span style="color: ${withdrawalLink?.options?.color}"> <b>${withdrawalLink?.title}</b></span>`
                                        }
                                        action={() => setOpenWithdrawalModal(true)}
                                />
                            </div>}
                    {/* Mostra i pulsanti di azione se c'è un abbonamento */}
                    {!!subscriptionDetail && <div className="button-container">
                        <div className={"left-side"}>
                            {goToVas && !scheduledDateChange && <JCMSCTA {...goToVas} />}
                            {submitCta && <JCMSCTA {...submitCta} action={() => onClick(ModalType.MANAGE_PLAN)} />}
                        </div>
                        {submitAnnulledCta && <JCMSCTA {...submitAnnulledCta} action={() => onClick(ModalType.CANCEL_PLAN)} />}
                    </div>}
                    {/* Mostra i pulsanti di azione se c'è un abbonamento pianificato */}
                    {!!scheduledChange && annulledCta &&
                            <div className="button-container-end">
                                <JCMSCTA {...annulledCta} action={() => onClick(ModalType.CANCEL_ACTIVATION)} />
                            </div>}
                </div>
                {openWithdrawalModal && <JModal
                        width={620}
                        isModalVisible={openWithdrawalModal}
                        title={translateWord(`WITHDRAWAL_TITLE`)}
                        children={<>{translateWord("WITHDRAWAL_TEXT", { date: moment(termination?.available_until)?.format(FORMAT_DATE) })}</>}
                        footer={<>
                            <JCMSCTA type={"BUTTON"} color={"default"} title={translateWord("cancel")}
                                     action={() => setOpenWithdrawalModal(false)} />
                            <JCMSCTA type={"BUTTON"} title={translateWord("confirm")} action={() => withdrawalSubscription()} />
                        </>}
                        handleCancel={() => setOpenWithdrawalModal(false)}
                />}
            </>
    )
}

export default SubscriptionDetail
