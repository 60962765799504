import {CMSConfiguration, CMSStaticSection, JCMSStaticSections, JPage} from "@jnext/commons";
import React, {Fragment, useEffect, useState} from 'react';
import {useCMSStore} from 'store/cmsStore';
import MetaTags from "../../components/MetaTags/MetaTags";
import {getPageSectionDom} from "../../utils";

const HowTo = () => {
    const { pages, setCurrentPage, currentPage } = useCMSStore();
    const [config, setConfig] = useState<CMSConfiguration | undefined>(pages.howTo);

    useEffect(() => {

        if(pages.howTo)
        {
            setCurrentPage(pages?.howTo ?? undefined);

            if(config?.section?.sections)
            {
                setConfig(prevState => {
                    return {
                        ...prevState,
                        section: {
                            ...prevState?.section,
                            sections: (config?.section?.sections?.filter(section => section.type == null))
                        },
                        enabled: prevState?.enabled ?? true // Ensure enabled is always a boolean
                    }
                })
            }
        }

    }, [pages]);


    if (!config || config?.enabled === false) {
        return <div></div>
    }

    return (
        <Fragment>
            {
                config.page && (
                    <JPage {...config.page} options={config.page.options || { headerAlign: 'center' }}>
                        <MetaTags pageConfiguration={config.page}/>
                        <JCMSStaticSections.HowToTemplate {...config.section as CMSStaticSection.HowTo} />
                        {!!currentPage?.section?.sections?.length && getPageSectionDom(currentPage?.section?.sections)}
                    </JPage>
                )
            }
        </Fragment>
    );
};

export default HowTo;
