import {useTranslationWord} from "../../../../utils";
import {VasService} from "../../../../service/VasService";
import {modalMessageDialog} from "../../../../commons/ModalMessage/ModalMessage";
import {JCMSCTA} from "@jnext/commons";
import React from "react";

interface VasInvoiceProps {
    transactionLogicId: string;
    hasInvoice: boolean;
    disableInvoiceDownloadEnableModal?: boolean;
    handleModal: () => void;
}

const VasInvoice: React.FC<VasInvoiceProps> = ({
                                                   transactionLogicId,
                                                   hasInvoice,
                                                   disableInvoiceDownloadEnableModal = false,
                                                   handleModal
                                               }) => {
    const translateWord = useTranslationWord();

    const handleInvoiceClick = async () => {
        try {
            const data = await VasService.getInvoice(transactionLogicId);
            if (data.hosted_invoice_url) {
                window.open(data.hosted_invoice_url, '_blank');
            } else {
                modalMessageDialog(
                    {type: 'error', message: {title: "WeAreSorry", text: 'Invoice_not_available'}},
                    () => {
                    },
                    undefined,
                    translateWord
                );
            }

        } catch (e: any) {
            if (e.response && e.response.status === 500) {
                modalMessageDialog(
                    {type: 'error', message: {title: "WeAreSorry", text: 'Invoice_not_available'}},
                    () => {
                    },
                    undefined,
                    translateWord
                );
            } else {
                console.log('Error fetching subscription details:', e);
            }
        }
    };

    const openModal = () => {
        if (disableInvoiceDownloadEnableModal) {
            handleModal()
        }
    };

    return (
        <div className={"request_invoice"}
             onClick={!disableInvoiceDownloadEnableModal ? () => hasInvoice && handleInvoiceClick() : () => {
             }}
        >
            <p onClick={openModal}>{translateWord(hasInvoice ? "Request_invoice" : "Request_not_invoice")}</p>
        </div>
    );
};

export default VasInvoice;
